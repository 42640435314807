import { Component, OnInit } from '@angular/core';

import { NavigationService } from '../../../core/navigation/navigation.service';
import { MenuItemCompiledFrontend } from '../../../core/navigation/models/MenuItemCompiledFrontend.class';
import { NavigationRequest } from '../../../core/navigation/models/NavigationRequest.class';
import { ChangedetectorReference } from '../../../core/changedetector/changedetectoreference';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { takeUntil } from 'rxjs/operators';
import { NavigationTabLevel } from '../../../core/navigation/navigation-tab-level.class';

@Component({
  selector: 'app-primary-tabs',
  templateUrl: './primary-tabs.component.html',
  providers: [ChangedetectorReference]
})
export class PrimaryTabsComponent extends DestroyableObjectTrait implements OnInit {

  tabs: NavigationTabLevel[] = [];

  /**
   * Creates a new PrimaryTabsComponent instance
   * @param {NavigationService} navigationService
   */
  constructor(private navigationService: NavigationService,
              protected cdRreference: ChangedetectorReference) {
    super();
  }

  /**
   * Init the component setting the subscriber to path changes
   */
  ngOnInit(): void {
    this.navigationService
      .currentNavigationRequest
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (next: NavigationRequest) => {
          // Puede ser nulo cuando estamos en medio de una navegación
          if (next == null) {
            this.setTabs([]);
          } else {
            const tabs: NavigationTabLevel[] = this.navigationService.resolveTabLevelsTabsFromMenuPathRequest(next);
            this.setTabs(tabs.reverse());
          }
        }
      )
  }

  /**
   * Set the current tabs
   */
  private setTabs(tabs: NavigationTabLevel[]): void {
    this.tabs = tabs;
    this.cdRreference.changeDetector.detectChanges();
  }

  /**
   * Get classes for a specific tab
   *
   * @param tab
   */
  getTabClasses(tab: MenuItemCompiledFrontend, i: number): string[] {
    const classes: string[] = [...tab.CssClasses];
    classes.push('tab-item');
    classes.push('tab-item__' + i);
    if (tab.Disabled === true) {
      classes.push('--disabled')
    }
    return classes;
  }
}
