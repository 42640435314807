<fieldset id="flex-row-1row-1" class="ng-untouched ng-pristine ng-valid">
  <legend class="o-legend ng-star-inserted" style="padding-left: 0px">{{ 'Método ACHS' | t }}</legend>
  <div class="force-normal elem-info elemtype-label flex-col-sm-12 flex-col-md-12 flex-col-lg-12 ng-star-inserted">
    <label ng-reflect-ng-class> {{ 'La metodología ACHS está dirigida a empresas que no necesariamente tienen una contraparte técnica para la gestión en SST. El cálculo del nivel de riesgo se genera a partir de las variables Probabilidad y Consecuencias, en base a una adaptación del método FINE y el método del INSHT.' | t }}
    </label>
  </div>
</fieldset>
<div id="wrapper" style="padding-left: 0px">
  <div id="first">
    <table id="levelTable" class="tableN">
      <tbody>
        <tr>
          <th class="widthColumnName"> {{ 'Nivel de Intervención'| t }} </th>
          <th class="widthColumnDes"> {{ 'Descripción'| t }} </th>
        </tr>
        <tr>
          <td>
            <div class="risklevel_0--legend"> {{ 'I - Urgente'| t }} </div>
          </td>
          <td>
            <p>{{ 'Situación crítica. Implementación de medidas de control antes de un mes.'| t }}</p>
          </td>
        </tr>

        <tr>
          <td>
            <div class="risklevel_1--legend">  {{ 'II - Prioritario'| t }} </div>
          </td>
          <td>
            <p>{{ 'Implementación de las medidas de control antes de tres meses.'| t }} </p>
          </td>
        </tr>
        <tr>
            <td>
              <div class="risklevel_2--legend"> {{ 'III - Programable'| t }} </div>
            </td>
            <td>
              <p>{{ 'Implementación de las medidas de control antes de un año.'| t }} </p>
            </td>
          </tr>
        <tr>
          <td>
            <div class="risklevel_3--legend"> {{ 'IV - Trivial'| t }} </div>
          </td>
          <td>
            <p>{{ 'No requiere intervención, salvo que un análisis más preciso lo justifique.'| t }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
