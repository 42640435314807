import {
  ICommand,
  IViewField,
  IViewMode,
  IViewModeColumnBased,
  IViewModeField,
  IViewModeUserConfiguration,
  IViewModeUserConfigurationColumn,
  IViewModeUserConfigurationColumnsBased,
  IViewModeUserConfigurationField, IViewRowOperations,
  ViewConfiguration,
  ViewFieldTypeEnum,
  ViewModeTable,
  ViewModeTableColumn,
  ViewModeTypeEnum,
  ViewResultCellType,
  ViewResultRow,
  ViewUserConfiguration
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { ListComponent2Service } from '../list.service';
import { PrimeUtils } from '../../utils/prime.utils';
import { asIterableObject, UtilsTypescript } from '../../utils/typescript.utils';
import { CommandService } from '../../../core/commands/command.service';
import { ChangeDetectorRef } from '@angular/core';

export class ViewModeUtils {
  /**
   * Gets the current ViewMode configuration
   */
  public static GetCurrentViewMode(viewConfiguration: ViewConfiguration, userConfiguration: ViewUserConfiguration): IViewMode {
    if (isNullOrUndefined(userConfiguration) || isNullOrUndefined(viewConfiguration)) {
      return null;
    }
    const currentViewMode: ViewModeTypeEnum = userConfiguration.CurrentViewModeType;
    const availableViewModes: { [key: string]: IViewMode } = viewConfiguration.AvailableViewModes;
    if (isNullOrUndefined(currentViewMode) || isNullOrUndefined(availableViewModes)) {
      return null;
    }
    const currentViewModeName: string = PrimeUtils.GetEnumNameFromValue(currentViewMode, ViewModeTypeEnum);
    return availableViewModes[currentViewModeName];
  }

  /**
   * Gets the current ViewMode configuration column-based
   */
  public static GetCurrentViewModeUserConfiguration(userConfiguration: ViewUserConfiguration): IViewModeUserConfiguration {
    if (isNullOrUndefined(userConfiguration)) {
      return null;
    }
    const currentViewMode: ViewModeTypeEnum = userConfiguration.CurrentViewModeType;
    const availableUserViewModes: { [key: string]: IViewModeUserConfiguration } = userConfiguration.AvailableUserViewModes;
    if (isNullOrUndefined(currentViewMode) || isNullOrUndefined(availableUserViewModes)) {
      return null;
    }
    const currentViewModeName: string = PrimeUtils.GetEnumNameFromValue(currentViewMode, ViewModeTypeEnum);
    return availableUserViewModes[currentViewModeName];
  }

  public static GetCurrentViewModeUserConfigurationColumnsBased(userConfiguration: ViewUserConfiguration): IViewModeUserConfigurationColumnsBased {
    return this.GetCurrentViewModeUserConfiguration(userConfiguration) as IViewModeUserConfigurationColumnsBased;
  }

  /**
   * Gets the current ViewMode configuration column-based
   */
  public static GetCurrentViewModeColumnBasedFromService(listComponentService: ListComponent2Service): IViewModeColumnBased {
    return this.GetCurrentViewModeColumnBased(listComponentService.getConfiguration(), listComponentService.getUserConfiguration());
  }

  /**
   * Using the service gets the Current ViewMode
   */
  public static GetCurrentViewModeFromService(listComponentService: ListComponent2Service): IViewMode {
    return this.GetCurrentViewMode(listComponentService.getConfiguration(), listComponentService.getUserConfiguration());
  }

  /**
   * Get a ViewModeField from a IViewModeUserConfigurationField in the current ViewMode
   * @param value
   * @param viewConfiguration
   * @param userConfiguration
   * @constructor
   */
  public static GetViewModeFieldFromViewModeUserConfigurationFieldFromService(
    value: IViewModeUserConfigurationField,
    listComponentService: ListComponent2Service): IViewModeField {
    const viewMode: IViewModeColumnBased = this.GetCurrentViewModeColumnBased(
      listComponentService.getConfiguration(),
      listComponentService.getUserConfiguration());
    return viewMode.Columns[value.Field];
  }

  /**
   * Gets the current ViewMode configuration column-based
   */
  public static GetCurrentViewModeColumnBased(viewConfiguration: ViewConfiguration, userConfiguration: ViewUserConfiguration): IViewModeColumnBased {
    return this.GetCurrentViewMode(viewConfiguration, userConfiguration) as IViewModeColumnBased;
  }

  /**
   * Get a ViewModeField from a IViewModeUserConfigurationField in the current ViewMode
   * @param value
   * @param viewConfiguration
   * @param userConfiguration
   * @constructor
   */
  public static GetViewModeFieldFromViewModeUserConfigurationField(
    value: IViewModeUserConfigurationField,
    viewConfiguration: ViewConfiguration,
    userConfiguration: ViewUserConfiguration): IViewModeField {
    const viewMode: IViewModeColumnBased = this.GetCurrentViewModeColumnBased(viewConfiguration, userConfiguration);
    return viewMode.Columns[value.Field];
  }

  /**
   * Clases CSS para las  cabeceras de los campos
   *
   * @param headerColumnName
   */
  public static resolveTableHeaderCellClasses(header: IViewModeUserConfigurationColumn, listComponentService: ListComponent2Service): string[] {
    let classes: string[] = [];
    const viewMode: IViewModeColumnBased = listComponentService.getCurrentViewMode() as ViewModeTable;
    const viewModeColumn: IViewModeField = viewMode.Columns[header.Field];

    if (!UtilsTypescript.isNullOrUndefined(viewModeColumn)) {
      if (header && viewModeColumn.CssClasses) {
        classes = [...viewModeColumn.CssClasses];
      }

      classes.push('view-column-' + viewModeColumn.Id);
      if (viewModeColumn.Field) {
        classes.push('view-field-' + viewModeColumn.Field);
      }
    }

    return classes;
  }

  /**
   * Resolve css classes for each cell.
   * @param fieldInclude
   * @param rowResult
   */
  public static resolveTableCellClasses(fieldInclude: IViewModeUserConfigurationColumn, rowResult: ViewResultRow, listComponentService: ListComponent2Service): string[] {
    const viewModeColumn: IViewModeField = listComponentService.getViewModeColumnFromViewModeUserColumn(fieldInclude);
    let classes: string[] = [];
    if (!UtilsTypescript.isNullOrUndefined(viewModeColumn)){
      if (rowResult.Columns[viewModeColumn.Field] && rowResult.Columns[viewModeColumn.Field].CssClasses) {
        classes = [...rowResult.Columns[viewModeColumn.Field].CssClasses];
      }
      classes.push('view-field-' + viewModeColumn.Field);
      const configuration: ViewConfiguration = listComponentService.getConfiguration();
      if (configuration.Fields && configuration.Fields[viewModeColumn.Field]) {
        classes.push('view-field-type-' + ViewFieldTypeEnum[configuration.Fields[viewModeColumn.Field].Type].toLowerCase());
      }
    }
    return classes;
  }

  /**
   * Resolve css classes for each row.
   * @param {ViewResultRow} rowResult
   * @returns {any}
   */
  public static resolveRowClasses(rowResult: ViewResultRow): string[] {
    let classes: string[] = [];
    if (rowResult.CssClasses) {
      classes = [...rowResult.CssClasses];
    }
    if (UtilsTypescript.isNullOrWhitespace(rowResult.Key)) {
      classes.push('row-key-' + rowResult.Key);
    }
    classes.push('row-index-' + rowResult.Index);
    classes.push('list-data');
    return classes;
  }

  /**
   * Devuelve la configuración de columna a partir de la configuración de usuario de columna
   *
   * @param headerUserField
   */
  public static getViewModeTableColumn(headerUserField: IViewModeUserConfigurationColumn, listService: ListComponent2Service): ViewModeTableColumn {
    const viewMode: ViewModeTable = ViewModeUtils.GetCurrentViewModeColumnBasedFromService(listService) as ViewModeTable;
    if (isNullOrUndefined(viewMode)) {
      return null;
    }
    const viewModeTableColumn: ViewModeTableColumn = UtilsTypescript.ObjectValues(viewMode.Columns).find(x => x.Field === headerUserField.Field) as ViewModeTableColumn;
    return viewModeTableColumn;
  }

  /**
   *
   * @param headerUserField
   */
  public static getViewFieldFromColumnHeaderUserField(headerUserField: IViewModeUserConfigurationColumn, viewService: ListComponent2Service): IViewField {
    if (!isNullOrUndefined(viewService.getConfiguration()) && !isNullOrUndefined(viewService.getUserConfiguration())) {
      const column: ViewModeTableColumn = this.getViewModeTableColumn(headerUserField, viewService);
      if (isNullOrUndefined(column)) {
        return null;
      }
      return Object.keys(viewService.getConfiguration().Fields)
        .map(fid => viewService.getConfiguration().Fields[fid])
        .find(f => f.Id === column.Id);
    }
    return null;
  }

  /**
   *
   * @param headerUserField
   * @param viewService
   */
  public static viewHeaderColumnUserConfigurationIsSortable(headerUserField: IViewModeUserConfigurationColumn, viewService: ListComponent2Service): boolean {
    const column: ViewModeTableColumn = this.getViewModeTableColumn(headerUserField, viewService);
    // En realidad column nunca debería poder ser nulo, se pone esta comprobación aquí
    // porque en los tests hay algún tipo de condición de carrera que hace que sea nulo...
    if (!column) {
      console.error('Could not find view mode column for user header column: ' + headerUserField.Field);
      return false;
    }
    return column.Sortable === true;
  }

  /**
   *
   * @param row
   * @param fieldInclude
   * @param listComponentService
   */
  public static checkFieldType(row: ViewResultRow, fieldInclude: IViewModeUserConfigurationColumn, listComponentService: ListComponent2Service): string {
    const viewModeColumn: IViewModeField = listComponentService.getViewModeColumnFromViewModeUserColumn(fieldInclude);
    // Podría darse el caso de que una celda no viniera?
    if (!UtilsTypescript.isNullOrUndefined(viewModeColumn)){
      if (row.Columns && row.Columns[viewModeColumn.Field]) {
        return row.Columns[viewModeColumn.Field].Type;
      }
    }
    return null;
  }

  /**
   * resolve Row Operations
   * @param rowResult
   * @param data
   * @param commandService
   * @param cdRef
   */
  public static resolveRowOperations(rowResult: ViewResultRow, data: ViewResultRow[], commandService: CommandService, cdRef: ChangeDetectorRef): any {
    if (!UtilsTypescript.isNullOrUndefined(rowResult.Columns['row_operations'])) {
      const rowOperations: Array<IViewRowOperations> = asIterableObject(rowResult.Columns['row_operations']['RowOperations']);
      let commands: Array<ICommand> = new Array<ICommand>();
      for (const rowOperation of rowOperations) {
        const commandsToAdd: Array<ICommand> = asIterableObject(rowOperation['OnClickCommands']);
        commands = commands.concat(commandsToAdd);
      }

      commandService.executeCommandChain(commands);

      data.forEach(x => x.CssClasses.splice(x.CssClasses.indexOf('row-selected', 1)));
      rowResult.CssClasses.push('row-selected');

      cdRef.detectChanges();
    }
  }
}
