import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { AuthService } from '../authentication/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { SessionstateService } from '../sessionstate/sessionstate.service';
import { finalizeWithReason } from '../../utils/rxJsFinalizeWithReason';

/**
 * Interceptor para detectar cambios de estado de sesión
 * avisados por el servidor. El cliente NO tiene visibilidad
 * sobre las cookies de sesión (son HttpOnly) por lo que esto
 * es simplemente un mecanismoo de saber que el "estado" de la
 * sesión ha cambiado ya que tendrá impacto en cosas como
 * si estoy o no autenticado, qué idioma tiene el usuario, sus
 * permisos, etc.
 *
 * Lo único que hace el interceptor es inyectar una cabecera donde le indica
 * al servidor cual debería ser el estado de la sesión (hash) si el servidor
 * detecta que el estado no coincide con lo que hay en sesión, envia una cabecera
 * de vuelta con el nuevo hash de sesion. Con esa info, el cliente debe
 * resincronizar aquello que crea conveniente en frontend.
 */
@Injectable()
export class SessionStateRefreshInterceptor implements HttpInterceptor {

  /**
   * RefreshTokenInterceptor class constructor.
   * @param {AuthService} authService
   */
  constructor(private sessionStateService: SessionstateService) {
  }

  /** @inheritdoc */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Le metemos al request información sobre el estado de la sesión
    const requestObservable: Observable<HttpEvent<any>> = of(null)
        .pipe(switchMap(() => {
          return next.handle(this.injectStateHeader(req));
        }));

    return requestObservable
        .pipe(
            switchMap((res) => {
              if (res && res['headers']) {
                // Hacemos esta llamada fuera del pipe actual, porque:
                // (a) en realidad el response ya ha llegado
                // (b) queremos evitar que la ejecución de esto dependa de que el observable vinculado al request siga vivo
                this.triggerStateUpdated(res['headers'] as HttpHeaders).subscribe();
              }
              return of(res);
            }),
            catchError((error: HttpErrorResponse, obs: Observable<any>) => {
              // Hacemos esta llamada fuera del pipe actual, porque:
              // (a) en realidad el response ya ha llegado
              // (b) queremos evitar que la ejecución de esto dependa de que el observable vinculado al request siga vivo
              this.triggerStateUpdated(error.headers).subscribe();
              return throwError(error);
            }),
            // Puede que tengamos peticiones cuya cadena de observables
            // se cancele, incluso en esos casos, queremos que se procese el cambio
            // de estado de la sesión.
            finalizeWithReason(() => {
            }));
  }

  /**
   * Avisar al servicio SessionStateChanged si ha habido un cambio en el estado
   * de la sesión.
   *
   * @param httpHeaders
   * @protected
   */
  protected triggerStateUpdated(httpHeaders: HttpHeaders): Observable<any> {
    if (httpHeaders && httpHeaders.has('X-Session-State')) {
      const sessionStateHash: string = httpHeaders.get('X-Session-State');
      return this.sessionStateService.SessionStateChanged(sessionStateHash);
    }
    return of(true);
  }

  /**
   * Inject the JWT access token to the request if available.
   * @param req
   */
  protected injectStateHeader(req: HttpRequest<any>): HttpRequest<any> {

    // Como aplicación, no tenemos conocimiento de la sesión, pero sí es cierto que sus contenidos
    // afectan directamente a lo que podemos o no hacer (autenticación y autorización) e incluso
    // puede afectar al propio contenido devuelto (i.e. solo ver cosas del usuario).
    const newReq: HttpRequest<any>
        = req.clone({
      headers: req.headers
          .set('X-Session-State', this.sessionStateService.CurrentSessionHash ?? ''),
      withCredentials: true
    });

    return newReq;
  }
}
