import { Injectable, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
/**
 * Detects the browser status. Tabs, etc...
 */
export class BrowserTabService extends DestroyableObjectTrait implements OnDestroy {

  /**
   * The current tab Id
   */
  private id: string;

  /**
   * Subject: Tab is visible (active)
   */
  public tabVisibleSubject$: Subject<boolean>;

  constructor(@Optional() @SkipSelf() parentModule?: BrowserTabService) {

    if (parentModule) {
      throw new Error('BrowserTabService service is already loaded. Import it in the AppModule only');
    }

    super();
    this.tabVisibleSubject$ = new ReplaySubject(1);

    this.id = Guid.create().toString();

    document.addEventListener('visibilitychange'
      , () => {
        // console.log('TAB Id: ' + this.id + ' is ' + this.getCurrentTabStatus() + ' at ' + moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'));
        this.tabVisibleSubject$.next(this.getCurrentIsVisible());
      }
    );
  }

  /**
   * The tab is enabled
   */
  get currentTabIsVisibleObservable(): Observable<boolean> {
    return this.tabVisibleSubject$;
  }

  /**
   * Gets the current status
   */
  getCurrentIsVisible(): boolean {
    return !document.hidden;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tabVisibleSubject$.complete();
    this.tabVisibleSubject$ = null;
  }
}

