<!-- No identificamos por Key por que los nuevos tags no tienen key y no podríamos agregar más de uno -->
<tag-input
    [id]="uniqueComponentId('tag-input-')"
    [secondaryPlaceholder]="config?.placeholder"
    [(ngModel)]="items"
    [ngModelOptions]="{standalone: true}"
    [identifyBy]="'Name'"
    [displayBy]="'Name'"
    [onlyFromAutocomplete]="true"
    [onTextChangeDebounce]="500"
    (onAdd)="onAdd()"
    (onRemove)="onRemove()"
    [disable]="!this.config.editable"
    (onBlur)="propagateTouch()"
    #tagInputComponent>
  <tag-input-dropdown
      [autocompleteObservable]="requestAutocompleteItems"
      [minimumTextLength]="4"
      [identifyBy]="'Name'"
      [displayBy]="'Name'">
    <ng-template let-item="item" let-index="index">
      <div [ngStyle]="item.Styles">{{ item.Name }} - {{ item.Description }}</div>
    </ng-template>
  </tag-input-dropdown>
</tag-input>
