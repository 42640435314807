import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BatchItemComponent } from '../../configuration/batch/batch-item/batch-item.component';
import { ModalBatchItemComponent } from './modal-batch-item/modal-batch-item.component';
import { RestTranslatorModule } from '../../core/translator/rest-translator.module';

@NgModule({
    imports: [
        CommonModule,
        RestTranslatorModule
    ],
    declarations: [
        BatchItemComponent,
        ModalBatchItemComponent
    ],
    providers: [],
    exports: [
        BatchItemComponent,
        ModalBatchItemComponent
    ]
})

export class BatchModule {
}
