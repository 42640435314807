<ng-container
    *ngIf="viewService.getConfiguration() && viewService.getUserConfiguration()">
  <ng-container [ngSwitch]="getType()">
    <div class="c-radio-checkbox view-bulk-select-all" *ngSwitchCase="types.BulkOperations">
      <ng-container *ngIf="isVboSelectAllAvailable()">
        <input type="checkbox" [checked]="vboChecked" (change)="vboCheckHandler()"
               class="o-checkradio" [id]="uniqueComponentId"/>
        <label [for]="uniqueComponentId" title="{{'Seleccionar todos los elementos de todas las páginas' | t}}"></label>
      </ng-container>
    </div>
    <div *ngSwitchDefault class="view-header-cell-sort">
      <div *ngIf="header" >{{ header.Header }} </div>
      <div [class.view-header-sort-placeholder]="'view-header-sort-placeholder'" [ngClass]="getSortClass()"></div>
    </div>
  </ng-container>
</ng-container>
