import { ModuleWithProviders, NgModule } from '@angular/core';
import { MessageToastService } from './share/message-toast.service';
import { ToastrModule, } from 'ngx-toastr';

/**
 * Module MessageToast.
 */
@NgModule({
  imports: [
    ToastrModule.forRoot({
      newestOnTop: false,
      closeButton: true,
      positionClass: 'toast-bottom-right',
      enableHtml: true,
    })
  ],
  declarations: [],
  exports: [],
  providers: []
})
export class MessageToastModule {
  static forRoot(): ModuleWithProviders<MessageToastModule> {
    return {
      ngModule: MessageToastModule,
      providers: [
        MessageToastService
      ]
    }
  }
}
