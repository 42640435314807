import { Type } from '@angular/core';
import { ILayoutVariablesInterface } from '../../core/layout-manager/interface/i-layout-variables.interface';
import { IFrontendFormElement } from 'app/shared/form/interfaces/field.interface';
import { components } from 'app/shared/form/form-components/components.constant';

export class FallApartLayoutVariablesClass implements ILayoutVariablesInterface {

    /**
   * See ILayoutVariablesInterface.AvaibleFormComponents
   */
  AvailableFormComponents: { [type: string]: Type<IFrontendFormElement> } = components;
}
