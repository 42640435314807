import { Type } from '@angular/core';
import { IFrontendFormElement } from 'app/shared/form/interfaces/field.interface';
import { ILayoutVariablesInterface } from '../../core/layout-manager/interface/i-layout-variables.interface';
import { FormElementType } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import {
  AutocompleteWrapperComponent
} from '../../shared/form/form-components/acomplete/autocomplete-wrapper/autocomplete-wrapper.component';
import {
  AutocompletenewWrapperComponent
} from '../../shared/form/form-components/autocompletenew/autocompletenew-wrapper/autocompletenew-wrapper.component';
import { ButtonComponent } from '../../shared/form/form-components/button/button.component';
import {
  ChartEmbeddedComponent
} from '../../shared/form/form-components/chart-embedded/chart-embedded-wrapper/chart-embedded.component';
import {
  InputchipsWrapperComponent
} from '../../shared/form/form-components/chips/inputchips-wrapper/inputchips-wrapper.component';
import {
  DatePickerOldWrapperComponent
} from '../../shared/form/form-components/date-picker/date-picker-wrapper/date-picker-old-wrapper.component';
import {
  DatepickerWrapperComponent
} from '../../shared/form/form-components/datepicker/datepicker-wrapper/datepicker-wrapper.component';
import {
  EmbeddedDocumentComponent
} from '../../shared/form/form-components/embedded-document/embedded-document.component';
import { FieldsetComponent } from '../../shared/form/form-components/fieldset/fieldset.component';
import {
  FormElementFromContentsProgressComponent
} from '../../shared/form/form-components/formelementprogress/formelementprogress.component';
import { FormTableComponent } from '../../shared/form/form-components/formtable/form-table.component';
import { HtmlplaceholderComponent } from '../../shared/form/form-components/htmlplaceholder/htmlplaceholder.component';
import {
  InputCheckModalWrapperComponent
} from '../../shared/form/form-components/input-check-modal/input-check-modal-wrapper/input-check-modal-wrapper.component';
import {
  InputCheckComponent
} from '../../shared/form/form-components/input-check/input-check-wrapper/input-check.component';
import {
  InputFileWrapperComponent
} from '../../shared/form/form-components/input-file/input-file-wrapper/input-file.component';
import {
  SelectWrapperComponent
} from '../../shared/form/form-components/input-select/select-wrapper/select-wrapper.component';
import {
  InputTextAreaWrapperComponent
} from '../../shared/form/form-components/input-text-area/input-textarea-wrapper/input-textarea-wrapper.component';
import { InputWrapperComponent } from '../../shared/form/form-components/input/input-wrapper/input-wrapper.component';
import {
  InputBoolWrapperComponent
} from '../../shared/form/form-components/inputbool/input-bool-wrapper/input-bool-wrapper.component';
import { LabelComponent } from '../../shared/form/form-components/label/label-wrapper/label.component';
import {
  MapsInputWrapperComponent
} from '../../shared/form/form-components/map-input/map-input-wrapper/maps-input-wrapper.component';
import { MapsComponent } from '../../shared/form/form-components/map/maps.component';
import { QrCodeComponent } from '../../shared/form/form-components/qr-code/qr-code-wrapper/qr-code.component';
import { RadioWrapperComponent } from '../../shared/form/form-components/radio/radio-wrapper/radio-wrapper.component';
import { SecretComponent } from '../../shared/form/form-components/secret/secret.component';
import {
  TimePickerTimespanWrapperComponent
} from '../../shared/form/form-components/timepicker-timespan/timepicker-wrapper/time-picker-timespan-wrapper.component';
import {
  TimePickerWrapperComponent
} from '../../shared/form/form-components/timepicker/timepicker-wrapper/time-picker-wrapper.component';
import {
  ViewsEmbedComponent
} from '../../shared/form/form-components/views-embed/views-embed-wrapper/views-embed.component';
import {
  FormElementSimpleMessageComponent
} from '../../shared/form/form-components/simple-message/formelement-simple-message.component';
import {
  FormElementNavigationBoardComponent
} from './layout/components/form-components/form-element-navigation-board/form-element-navigation-board/form-element-navigation-board.component';
import {
  DatetimepickerWrapperComponent
} from '../../shared/form/form-components/date-time-picker/datetimepicker-wrapper/datetimepicker-wrapper.component';
import {
  FormElementBatchTaskComponent
} from '../../shared/form/form-components/batch-task/form-element-batch-task.component';
import {
  PrimeFuseViewSelectorWrapperComponent
} from './layout/components/form-components/prime-fuse-view-selector/prime-fuse-view-selector-wrapper/prime-fuse-view-selector-wrapper.component';
import {
  InputNumberWrapperComponent
} from '../../shared/form/form-components/input-number/input-number-wrapper/input-number-wrapper.component';
import {
  InputAvatarWrapperComponent
} from '../../shared/form/form-components/input-avatar/input-avatar-wrapper/input-avatar.component';
import {
  SelectDynamicWrapperComponent
} from '../../shared/form/form-components/input-selectdynamic/selectdynamic-wrapper/selectdynamic-wrapper.component';
import { VideoWrapperComponent } from '../../shared/form/form-components/video/video-wrapper/video-wrapper.component';
import {
  InputRichTextEditorWrapperComponent
} from '../../shared/form/form-components/input-rich-text-editor/input-rich-text-editor-wrapper/input-rich-text-editor-wrapper.component';

export class PrimeFuseLayoutVariablesClass implements ILayoutVariablesInterface {
  /**
   * See ILayoutVariablesInterface.AvaibleFormComponents
   */
  AvailableFormComponents: { [type: string]: Type<IFrontendFormElement> } = {
    [FormElementType.Secret]: SecretComponent,
    [FormElementType.TextInput]: InputWrapperComponent,
    [FormElementType.Email]: InputWrapperComponent,
    [FormElementType.Number]: InputNumberWrapperComponent,
    [FormElementType.Checkbox]: InputCheckComponent,
    [FormElementType.Checkboxes]: InputCheckComponent,
    [FormElementType.CheckboxesModal]: InputCheckModalWrapperComponent,
    [FormElementType.Bool]: InputBoolWrapperComponent,
    [FormElementType.TextArea]: InputTextAreaWrapperComponent,
    [FormElementType.File]: InputFileWrapperComponent,
    [FormElementType.Select]: SelectWrapperComponent,
    [FormElementType.Date]: DatePickerOldWrapperComponent,
    [FormElementType.Password]: InputWrapperComponent,
    [FormElementType.Button]: ButtonComponent,
    [FormElementType.AutoComplete]: AutocompleteWrapperComponent,
    [FormElementType.Radio]: RadioWrapperComponent,
    [FormElementType.Label]: LabelComponent,
    [FormElementType.Map]: MapsComponent,
    [FormElementType.FieldSet]: FieldsetComponent,
    [FormElementType.HtmlPlaceholder]: HtmlplaceholderComponent,
    [FormElementType.ViewsEmbed]: ViewsEmbedComponent,
    [FormElementType.DatePicker]: DatepickerWrapperComponent,
    [FormElementType.TimePicker]: TimePickerWrapperComponent,
    [FormElementType.TagSelector]: InputchipsWrapperComponent,
    [FormElementType.ViewsSelector]: PrimeFuseViewSelectorWrapperComponent,
    [FormElementType.AutocompleteNew]: AutocompletenewWrapperComponent,
    [FormElementType.Table]: FormTableComponent,
    [FormElementType.QRCode]: QrCodeComponent,
    [FormElementType.TimePickerTimeSpan]: TimePickerTimespanWrapperComponent,
    [FormElementType.DateTimePicker]: DatetimepickerWrapperComponent,
    [FormElementType.MapInput]: MapsInputWrapperComponent,
    [FormElementType.EmbeddedDocument]: EmbeddedDocumentComponent,
    [FormElementType.ChartsEmbed]: ChartEmbeddedComponent,
    [FormElementType.Progress]: FormElementFromContentsProgressComponent,
    [FormElementType.NavigationBoard]: FormElementNavigationBoardComponent,
    [FormElementType.SimpleMessage]: FormElementSimpleMessageComponent,
    [FormElementType.BatchTask]: FormElementBatchTaskComponent,
    [FormElementType.Avatar]: InputAvatarWrapperComponent,
    [FormElementType.SelectDynamic]: SelectDynamicWrapperComponent,
    [FormElementType.Video]: VideoWrapperComponent,
    [FormElementType.RichText]: InputRichTextEditorWrapperComponent,
  };
}
