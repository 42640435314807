<span
    *ngIf="config.maxlength > 0"
    class="o-input__field-seek"
    [ngClass]="lengthValidationClasses()">
  {{ getRemainingChars() }}
</span>

<quill-editor
    [id]="uniqueComponentId('textarea-')"
    [placeholder]="config?.placeholder"
    [disabled]="isDisabled"
    [(ngModel)]="value"
    (blur)="propagateTouch()"
    [modules]="richTextEditorConfiguration"
    [readOnly]="isDisabled"
    theme="snow"
    [ngModelOptions]="{ standalone: true }"
    #input
></quill-editor>
