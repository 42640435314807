import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfigurationService } from '../../app.configuration.service';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { ChangedetectorService } from './changedetector.service';
import { isNullOrUndefined } from '../../shared/utils/typescript.utils';

/**
 * Authentication service.
 */
@Injectable()
export class ChangedetectorReference extends DestroyableObjectTrait {

  /**
   * Ctor of Auth Service
   * @param {Router} router
   * @param {HttpClient} http
   * @param {AppConfigurationService} appConfigurationService
   */
  constructor(private cdService: ChangedetectorService,
              @Optional()
              private cdRef: ChangeDetectorRef,
              @Optional()
              @SkipSelf()
              private cdRefParent: ChangeDetectorRef) {
    super();
  }

  public get changeDetector(): ChangeDetectorRef {
    return this.cdRef;
  }

  public get changeDetectorParent(): ChangeDetectorRef {
    return this.cdRefParent;
  }

  public detectChangesIfOutsideAngular(): void {
    if (Zone.current.name === 'angular') {
      return;
    }
    this.cdRef.detectChanges();
  }

  public detectChangesParentIfOutsideAngular(): void {
    if (Zone.current.name === 'angular') {
      return;
    }
    if (!isNullOrUndefined(this.cdRefParent)) {
      this.cdRefParent.detectChanges();
    } else {
      this.cdRef.detectChanges();
    }
  }

  /**
   * Refresh an app if current zone is not angular zone
   */
  refreshApp(): void {
    this.cdService.runApplicationChangeDetection();
  }
}
