import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, of, Subscription } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { WebServiceResponse } from '../../models/ETG_SABENTISpro_Application_Core_models';
import { MaintenanceModeService } from '../../services/ETG_SABENTISpro_Application_Core_maintenancemode.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../navigation/navigation.service';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styles: [`
    @keyframes example {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    i {
      transform: rotate(0deg);
      animation-name: example;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      margin-top: 11px;
    }

    body {
      background: linear-gradient(90deg, #ddd, #fff);
    }

    .mt-20 {
      margin-top: 100px;
    }
  `]
})
export class MaintenancePageComponent implements OnInit, OnDestroy {

  /**
   * Text to display on screen.
   */
  text: string;

  /**
   * Time to wait before asking for the service status.
   */
  protected timeInSeconds = 300;

  /**
   * Interval handler observable.
   */
  protected timerObservable = interval(1000);

  /**
   * Timer subscription.
   */
  protected timerSubscription: Subscription;

  /**
   * Maintenance page class constructor.
   * @param {MaintenanceModeService} maintenanceService
   * @param {Router} router
   */
  constructor(private maintenanceService: MaintenanceModeService,
              private activatedRoute: ActivatedRoute,
              private navigationService: NavigationService) {
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are
   * initialized.
   */
  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.hasOwnProperty('r')) {
      this.timeInSeconds = this.activatedRoute.snapshot.queryParams.r;
    }

    this.activeMaintenancemode();

    this.text = ' en ' + this.getTime(this.timeInSeconds);

    this.timerSubscription =
        this.timerObservable
            .subscribe(
                (next) => {
                  if (next >= this.timeInSeconds) {
                    this.text = '';
                    this.timerSubscription.unsubscribe();
                    location.reload();
                  } else {
                    this.text = ' en ' + this.getTime(this.timeInSeconds - next);
                  }
                }
            );
  }

  /**
   * Lifecycle hook that is called after a directive's content has been fully
   * initialized.
   */
  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }

  /**
   * Formats a time count in seconds to human readable format.
   *
   * @param {number} timeInSecs
   */
  getTime(timeInSecs: number): string {
    let hours: number = 0;
    let min: number = Math.floor(timeInSecs / 60);
    if (min >= 60) {
      hours = Math.floor(min / 60);
      min = min % 60;
    }
    const secs: number = timeInSecs % 60;

    let result: string = '';
    if (hours !== 0) {
      result = hours.toString();
      if (hours === 1) {
        result += ' hora ';
      } else {
        result += ' horas ';
      }
    }
    if (min !== 0) {
      result += min;
      if (min === 1) {
        result += ' minuto ';
      } else {
        result += ' minutos ';
      }
    }
    if (secs !== 0) {
      result += secs;
      if (secs === 1) {
        result += ' segundo';
      } else {
        result += ' segundos';
      }
    }
    return result;
  }

  /**
   * Triggers a request to verify if the app is ready.
   */
  private activeMaintenancemode(): void {
    // La página de mantenimiento puede ser previa al bootstrap (i.e durante el arranque)
    // y mostrarnos el progreso del arranque mismo...
    this.maintenanceService
        .getMaintenancemode({headers: new HttpHeaders({'meta-codepassthrough': '503, 0'})})
        .pipe(
            map((maintenanceActive: WebServiceResponse) => maintenanceActive.result),
            catchError((error) => {
              // Con el meta-codepassthrough hacemos que el error llegue hasta aquí
              return of(true);
            }))
        .subscribe((maintenanceIsActive) => {
          if (!maintenanceIsActive) {
            // We need to reload the page because we don't know if the app
            // was correctly bootstrapped.
            location.href = '/';
          }
        });
  }
}
