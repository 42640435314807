import { Component, EmbeddedViewRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-permissions-wraper',
  templateUrl: './permissions-wraper.component.html'
})
export class PermissionsWraperComponent {
  @ViewChild('vc', { read: ViewContainerRef, static: true }) vc: ViewContainerRef;

  /**
   * Container Template Reference.
   */
  @ViewChildren(TooltipDirective)
  tooltips: QueryList<TooltipDirective>;

  warning = 'No cuenta con los permisos para acceder a esta funcionalidad.';

  constructor() {}

  createEmbeddedView(template: TemplateRef<any>): EmbeddedViewRef<any> {
    return this.vc.createEmbeddedView(template);
  }

  @HostListener('mouseenter')
  mouseEnter(): void {
    this.tooltips.map(item => item.show());
  }

  @HostListener('mouseleave')
  mouseLeave(): void {
    this.tooltips.map(item => item.hide());
  }

}
