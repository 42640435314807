import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RestTranslatorModule } from '../../../../core/translator/rest-translator.module';
import { ViewsFormComponent } from './views-form.component';
import { ViewFormGeneratorDirective } from './view-form-generator.directive';


@NgModule({
  imports: [
    CommonModule,
    RestTranslatorModule,
  ],
  declarations: [
    ViewsFormComponent,
    ViewFormGeneratorDirective
  ],
  exports: [
    ViewsFormComponent
  ],
  providers: [
    DatePipe
  ]
})
export class ViewsFormModule {
}
