import { Component, OnInit } from '@angular/core';
import { FieldsetComponent } from '../fieldset/fieldset.component';
import {
  FormElementTable,
  FormElementTableCellConfig,
  FormElementTableRowConfig
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FieldConfig } from '../../interfaces/field-config.interface';
import { isNullOrUndefined } from '../../../utils/typescript.utils';

@Component({
  templateUrl: './form-table.component.html',
  selector: 'app-formtable'
})
export class FormTableComponent extends FieldsetComponent implements OnInit {

  get element(): FormElementTable {
    return Object.assign(new FormElementTable(), this.config.FormElement);
  }

  getConfigFromCell(cell: FormElementTableCellConfig): FieldConfig {
    return this.config.children[cell.ComponentId];
  }

  getSorted(elements: { [key: number]: FormElementTableRowConfig } | { [key: number]: FormElementTableCellConfig })
    : Array<FormElementTableCellConfig> | Array<FormElementTableRowConfig> {
    const arrSorted: Array<FormElementTableCellConfig> | Array<FormElementTableRowConfig> = [];

    if (!isNullOrUndefined(elements)) {
      for (const key of Object.keys(elements)) {
        arrSorted.push(elements[key]);
      }
    }

    return arrSorted;
  }
}
