import { Component, OnInit } from '@angular/core';
import { mergeMap, Observable, timer } from 'rxjs';

import { BootstrapService } from '../../../app-bootstrap.service';
import { filter, map, retryWhen, take } from 'rxjs/operators';

/**
 * Minimum timespan to wait between checks.
 */
const TIMESPAN: number = 30;

/**
 * Timespan multiplier between checks.
 */
const TIMESPAN_MULTIPLIER: number = 3;

/**
 * This component is showed when the server is down.
 */
@Component({
  selector: 'app-out-of-service',
  templateUrl: './out-of-service.component.html',
  styleUrls: ['./out-of-service.component.scss']
})
export class OutOfServiceComponent implements OnInit {

  /**
   * Timer to be used by template.
   */
  timer: number;

  /**
   * Time span to wait between checks. Every time a check is done this
   * timespan is multiplied by 3.
   */
  timeSpan: number = TIMESPAN;

  /**
   * OutOfServiceComponent class constructor.
   *
   * @param {BootstrapService} bootstrapService
   */
  constructor(private bootstrapService: BootstrapService) {
  }

  /**
   * Human readable hour time formatter.
   */
  get fHours(): number {
    return Math.floor(this.timer / 60 / 60);
  }

  /**
   * Human readable minutes time formatter.
   */
  get fMinutes(): number {
    const time: number = this.timer - (this.fHours * 60);
    return Math.floor(time / 60);
  }

  /**
   * Human readable seconds time formatter.
   */
  get fSeconds(): number {
    const time: number = this.timer - (this.fHours * 60) - (this.fMinutes * 60);
    return time;
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are
   * initialized.
   */
  ngOnInit(): void {
    this.timerCountdown$()
        .pipe(
            mergeMap(() => this.bootstrapService.initializeApp()),
            map((res: Boolean) => {
              if (res) {
                location.href = '/';
              }
            }),
            // This error is only executed when the request fails, then
            // we force a full retry throwing an error.
            map((res) => {
              throw new Error('Error while querying for service.');
            }),
            retryWhen((errors) => errors))
        .subscribe();
  }

  /**
   * Countdown timer
   */
  timerCountdown$(): Observable<void> {
    return timer(1000, 1000)
        .pipe(map((i) => {
              this.timer = this.timeSpan - i;
              return i;
            }),
            filter(i => i === this.timeSpan),
            take(1),
            map(() => {
              this.timeSpan *= TIMESPAN_MULTIPLIER
            }));
  }

  /**
   * Plural formatter for labels.
   * @param {number} num
   */
  plurals(num: number): boolean {
    return num > 1
  }
}
