<ng-container *ngIf="showComponent">
  <div class="l-chart-list">
      <div *ngFor="let option of availableCharts" [class]="option.Id">
        <label>
          <input
              type="radio"
              [name]="'chart-options-' + currentChartResponse?.PluginRequest?.Id"
              value="option"
              [checked]="option.Id === currentChart"
              (click)="onOptionChanged(option)">
          <i></i>
        </label>
      </div>
  </div>
</ng-container>
