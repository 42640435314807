import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

import { FuseNavigationComponent } from './navigation.component';
import { FuseNavVerticalItemComponent } from './vertical/item/item.component';
import { FuseNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { FuseNavVerticalGroupComponent } from './vertical/group/group.component';
import { FuseNavHorizontalItemComponent } from './horizontal/item/item.component';
import { FuseNavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';
import { ItemActiveIfAnyChildIsActiveDirective } from './directives/item-active-if-any-child-is-active.directive';
import { CoreModule } from '../../../app/core/core.module';
import { FuseNavVerticalCollapsableItemComponent } from './vertical/collapsable-item/collapsable-item.component';
import { FuseNavHorizontalCollapsableItemComponent } from './horizontal/collapsable-item/collapsable-item.component';
import { MatInputModule } from '@angular/material/input';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,

    TranslateModule.forChild(),
    CoreModule,
    MatInputModule,
    ExtendedModule,
    FlexModule
  ],
  exports: [
    FuseNavigationComponent,
    ItemActiveIfAnyChildIsActiveDirective
  ],
  declarations: [
    FuseNavigationComponent,
    FuseNavVerticalGroupComponent,
    FuseNavVerticalItemComponent,
    FuseNavVerticalCollapsableComponent,
    FuseNavVerticalCollapsableItemComponent,
    FuseNavHorizontalItemComponent,
    FuseNavHorizontalCollapsableComponent,
    FuseNavHorizontalCollapsableItemComponent,
    ItemActiveIfAnyChildIsActiveDirective
  ]
})
export class FuseNavigationModule {
}
