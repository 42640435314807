import { Type } from '@angular/core';

import { PagerInterface } from './interfaces/pager.interface';
import { PagerComponent } from './pager/pager.component';
import { LoadMorePagerComponent } from './load-more-pager/load-more-pager.component';
import { MaterialPagerComponent } from './material-pager/material-pager.component';
import { LazyPagerComponent } from './lazy-pager/lazy-pager.component';
import { ViewsPagerTypeEnum } from 'app/core/models/ETG_SABENTISpro_Application_Core_models';

export const PAGERS: { [type: string]: { component: Type<PagerInterface> } } = {
  [ViewsPagerTypeEnum[ViewsPagerTypeEnum.Complete]]: {component: PagerComponent},
  [ViewsPagerTypeEnum[ViewsPagerTypeEnum.Simple]]: {component: PagerComponent},
  [ViewsPagerTypeEnum[ViewsPagerTypeEnum.LoadMore]]: {component: LoadMorePagerComponent},
  [ViewsPagerTypeEnum[ViewsPagerTypeEnum.MaterialComplete]]: {component: MaterialPagerComponent},
  [ViewsPagerTypeEnum[ViewsPagerTypeEnum.CompleteLazy]]: {component: LazyPagerComponent},
};
