import { Type } from '@angular/core';

import { IViewModeComponent } from './i-view-mode.component';
import { ViewGridComponent } from './ViewModeTable/components/grid.component';
import { ViewCardBoardsComponent } from './ViewModeCardBoards/components/card-boards.component';
import { ViewModeCalendarComponent } from './ViewModeCalendar/components/view-mode-calendar.component';


const ViewModeComponentsConstants: { [type: string]: Type<IViewModeComponent> } = {
  ViewModeTableUserConfiguration: ViewGridComponent,
  ViewModeCardBoardsUserConfiguration: ViewCardBoardsComponent,
  ViewModeCalendarUserConfiguration: ViewModeCalendarComponent
};

export { ViewModeComponentsConstants };
