<div class="container container-table">
  <div class="vertical-center-row">
    <div class="flex-container">
      <div class="image-wrapper">
        <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAC/VBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADMAAGYAAJkAAMwAAP8AMwAAMzMAM2YAM5kAM8wAM/8AZgAAZjMAZmYAZpkAZswAZv8AmQAAmTMAmWYAmZkAmcwAmf8AzAAAzDMAzGYAzJkAzMwAzP8A/wAA/zMA/2YA/5kA/8wA//8zAAAzADMzAGYzAJkzAMwzAP8zMwAzMzMzM2YzM5kzM8wzM/8zZgAzZjMzZmYzZpkzZswzZv8zmQAzmTMzmWYzmZkzmcwzmf8zzAAzzDMzzGYzzJkzzMwzzP8z/wAz/zMz/2Yz/5kz/8wz//9mAABmADNmAGZmAJlmAMxmAP9mMwBmMzNmM2ZmM5lmM8xmM/9mZgBmZjNmZmZmZplmZsxmZv9mmQBmmTNmmWZmmZlmmcxmmf9mzABmzDNmzGZmzJlmzMxmzP9m/wBm/zNm/2Zm/5lm/8xm//+ZAACZADOZAGaZAJmZAMyZAP+ZMwCZMzOZM2aZM5mZM8yZM/+ZZgCZZjOZZmaZZpmZZsyZZv+ZmQCZmTOZmWaZmZmZmcyZmf+ZzACZzDOZzGaZzJmZzMyZzP+Z/wCZ/zOZ/2aZ/5mZ/8yZ///MAADMADPMAGbMAJnMAMzMAP/MMwDMMzPMM2bMM5nMM8zMM//MZgDMZjPMZmbMZpnMZszMZv/MmQDMmTPMmWbMmZnMmczMmf/MzADMzDPMzGbMzJnMzMzMzP/M/wDM/zPM/2bM/5nM/8zM////AAD/ADP/AGb/AJn/AMz/AP//MwD/MzP/M2b/M5n/M8z/M///ZgD/ZjP/Zmb/Zpn/Zsz/Zv//mQD/mTP/mWb/mZn/mcz/mf//zAD/zDP/zGb/zJn/zMz/zP///wD//zP//2b//5n//8z////6+YKPAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAHdElNRQfiAwITCAZFK5BmAAABjElEQVR42u3YzWrDQAxFYedQQqBNoaR5/1ftplACiX9G0pw4Ha3Huh9e2BpN06hRo9qL35LjLQK4AnAF4ApAFtgAkAX3Ae9yfr9XALLABoAssAHgCo7IAmQAyAIbALJgDeBDzq98BWdkATIAZIENAFfwhix4mNMJMBvSQzAfUQ9YSigXLPYvFiAD1nQvFdgA2gC8DIBWAMXf4KZjOwQQAfACgAf/+9Pag93WMVWTCVEARfm0n9wVgAwANQB6ADYM3jUzug0gC8BOAQuXr8uWwyV30ertnQ3YdgEuuC7bALIB6ACy8286ZgO2rmHS1zY2gBoAOoDc/DIAdQB2AWhZh6ZuUGsBx9SVdEvpgJOcv/QKQBbYAJAFba2uG586Z+UnPHhbh/Y21xRBpEkGINYjQRDsEAbQE3Dn+YsNQG5AbwBPBqA/gKcCJPzSQi2+HQCxMSBhkgjOIQmTTHQOmpIahOarY+ThnoPgjGAABmAADjZgsvNFgQ34+5l9GvFfwmZgdqiT40eNGjVq1D+uH9oGOsHMSqPnAAAAAElFTkSuQmCC"
            width=140>
      </div>
      <div class="text-wrapper">
        <div>
          <h1>
            ¡Oh! Algo ha ido mal :(
          </h1>
          <p>
            Lo sentimos, alguna cosa no ha ido bien. Pulse "Volver a la página" para volver a la página
            dónde se originó el error. Si el problema persiste pulse <a [href]="main">aquí</a> para volver a la página
            principal. <a (click)="goToPageAndRefresh(true)">...</a>
          </p>
          <h6><span style="font-weight: bold;">Número de incidencia:</span> #{{ ticketId }}</h6>
        </div>
        <div class="button-wrapper">
          <button class="o-btn__secondary t-volver" (click)="goToPageAndRefresh()">Volver a la página</button>
        </div>
      </div>
    </div>
  </div>
</div>
