import { join as pathJoin } from '@fireflysemantics/join';


export class UtilsUrl {

  /**
   * Unir uno o varios segmentos de URL de forma segura
   *
   * @param args
   * @constructor
   */
  public static JoinPath(...args: string[]): string {
    return pathJoin(...args);
  }

  /**
   * Para dos URI, conocer si el componente de path es igual
   *
   * @param url1
   * @param url2
   * @constructor
   */
  public static UrlPathNameEqual(url1: string, url2: string): boolean {
    if (url1.indexOf('://') === -1) {
      url1 = UtilsUrl.JoinPath('http://localhost/', url1);
    }
    if (url2.indexOf('://') === -1) {
      url2 = UtilsUrl.JoinPath('http://localhost/', url2);
    }
    const parsedUrl1: URL = new URL(url1);
    const parsedUrl2: URL = new URL(url2);
    return parsedUrl1.pathname.toLowerCase() === parsedUrl2.pathname.toLowerCase();
  }

  /**
   * Check whether a URL is global
   * @param url
   * @constructor
   */
  public static IsGlobalUrl(url: string): boolean {

    // Este regex detecta si hay un '//' al principio de la url o un '://' en algun lugar que no sea el principio
    let RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');
    return RgExp.test(url);
  }
}
