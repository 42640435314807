import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppConfigurationService } from '../../app.configuration.service';
import { HelpService } from '../help.service';

const modulename: string = 'help';

/**
 * This component show option help in the menu.
 */
@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpButtonComponent implements OnInit {
  private activated;
  private init = false;

  /**
   * The constructor.
   * @param moduleservice This service allows us to know the active modules.
   * @param helpService This service allows you to manipule the modal.
   */
  constructor(
    private configurationService: AppConfigurationService,
    private helpService: HelpService
  ) {}

  ngOnInit(): void {
    this.init = true;
    this.activated = this.isModuleActive();
  }

  /**
   * Return if help module is activeted
   */
  isModuleActive(): boolean {
    let result: boolean = false;
    if (
      this.configurationService
        .getActiveModulesConfig()
        .find(m => m.nombre === modulename)
    ) {
      result = true;
    }
    return result;
  }

  /**
   * Validate if activated and init variable are true then open help form.
   */
  openMenu(): void {
    if (this.activated && this.init) {
      this.helpService.openModal();
    }
  }
}
