import { ComponentRef, EmbeddedViewRef, Injectable, TemplateRef, Type } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '../utils/typescript.utils';
import { BaseDialogComponent } from './components/base-dialog.component';
import { DecoupledModalBridgeService } from './decoupled-modal-bridge.service';
import { AbstractDecoupledModalService } from './models/abstract-decoupled-modal.service';
import { DecoupledModalComponentInterface } from './models/decoupled-modal-component.interface';
import { BaseModalParamsInterface } from './models/modal-params.interface';
import { CoalescingComponentFactoryResolver } from '../../core/coalescing-factory-resolver.service';
import { finalize, take, takeUntil } from 'rxjs/operators';

/**
 * Decoupled modal base service for the app.
 */
@Injectable()
export class AppModalService extends AbstractDecoupledModalService {
  /**
   * FallApartLayoutDecoupledModalService class constructor.
   */
  constructor(
      protected dmbs: DecoupledModalBridgeService,
      protected bsModalService: BsModalService,
      protected componentFactoryResolver: CoalescingComponentFactoryResolver,
  ) {
    super(dmbs);
  }

  /**
   * @inheritdoc
   */
  register(): Promise<boolean> {
    this.dmbs.register(AppModalService);
    return Promise.resolve(true);
  }

  /**
   * @inheritdoc
   */
  handleShowComponent(params: BaseModalParamsInterface): void {
    const {initialState, ...rest} = params;
    const modalId: string = params.id;

    const config: ModalOptions = {
      class: this.getCssClasses(params).join(' '),
      initialState: {
        ...initialState
      },
      ...(rest || {}),
      backdrop: params.config.HideClose ? 'static' : undefined,
      keyboard: params.config.HideClose ? false : true,
    } as ModalOptions;

    const bsref: BsModalRef = this.bsModalService
        .show(BaseDialogComponent, config);

    // Get the dialog reference.
    const dialogRef: BaseDialogComponent
        = bsref.content as BaseDialogComponent;

    dialogRef.data = params;

    // Close dialog trigger.
    const dialogClosed$: Subject<void> = new Subject<void>();

    // On dialog close event, then emit the dialog data and close the modal.
    dialogRef.close
        .asObservable()
        .pipe(take(1))
        .subscribe((data?: any) => {
          // Este hide hay que ponerlo aquí, sino se queda la instancia colgada...
          bsref.hide();
          this.onClose(params, data);
          // Emit an event to unsubscribe from component closing.
          dialogClosed$.next();
          dialogClosed$.complete();
        });

    if (params.component instanceof TemplateRef) {
      const templateRef: EmbeddedViewRef<unknown> =
          dialogRef.container.createEmbeddedView(params.component);

      if (templateRef) {
        this.onInstance(params, dialogRef);
      }

      return;
    }

    // Instance the component.
    const componentRef: ComponentRef<DecoupledModalComponentInterface>
        = this.instanceComponent(params, dialogRef);

    // When de component is instaced, then emit a reference to the instance.
    if (componentRef) {
      this.onInstance(params, componentRef.instance);
    }

    // Set the component configuration parameter.
    componentRef.instance.data = params;

    Object.assign(componentRef.instance, (params.initialState || {}));

    // On component close event, then emit the component data and close the modal.
    componentRef.instance.close
        .asObservable()
        .pipe(
            take(1),
            takeUntil(this.componentDestroyed$),
            finalize(() => {
              // Make sure the component is destroyed
              // TODO: No podemos llamar a este destroy porque
              // hay un ciclo doble en onclose->closemodal->onclose que
              // hay que resolver primero
              componentRef.destroy();
            })
        )
        .subscribe((data: any) => {
          dialogRef.closeModal(data);
        });
  }

  /**
   * Get CSS classes for the modal.
   *
   * @param {BaseModalParamsInterface} params
   */
  protected getCssClasses(params: BaseModalParamsInterface): string[] {
    const modalCssClasses: string[] = [];
    if (params.config.CssClasses && Array.isArray(params.config.CssClasses)) {
      modalCssClasses.concat(params.config.CssClasses);
    }
    if (!isNullOrUndefined(params.config.ModalSize)) {
      modalCssClasses.push(params.config.ModalSize.toString());
      modalCssClasses.push('modal-size--' + params.config.ModalSize.toString());
    }
    if (!isNullOrUndefined(params.config.ModalType)) {
      modalCssClasses.push('modal-type--' + params.config.ModalType.toString());
    }
    modalCssClasses.push('modal-id--' + params.id);
    return modalCssClasses;
  }

  /**
   * Instances the dialog component.
   *
   * @param {BaseModalParamsInterface} data
   * @param {BaseDialogComponent} dialogRef
   */
  protected instanceComponent(
      data: BaseModalParamsInterface,
      dialogRef: BaseDialogComponent
  ): ComponentRef<DecoupledModalComponentInterface> {
    // Create the component
    return dialogRef.container.createComponent(data.component as Type<DecoupledModalComponentInterface>);
  }

  /**
   * Instance handler.
   *
   * @param {BaseModalParamsInterface} data
   * @param {ComponentRef<DecoupledModalComponentInterface>} instance
   */
  protected onInstance(
      data: BaseModalParamsInterface,
      instance?: DecoupledModalComponentInterface
  ): void {
    const onInstanceHandler: (data: any) => void = data.onInstance;

    if (isNullOrUndefined(onInstanceHandler)) {
      return;
    }

    onInstanceHandler(instance);
  }

  /**
   * Close handler.
   *
   * @param {BaseModalParamsInterface} data
   * @param {any} result
   */
  protected onClose(
      data: BaseModalParamsInterface,
      result?: any
  ): void {
    const onCloseHandler: (data: any) => void = data.onClose;

    if (isNullOrUndefined(onCloseHandler)) {
      return;
    }

    onCloseHandler(result);
  }
}


/**
 * Factory to run the `bootstrapService.load` method. This function is used
 * as APP_INITIALIZER callback for `AppModule`.
 *
 * APP_INITIALIZER: Callback is invoked before an app is initialized.
 * All registered initializers can optionally return a Promise.
 * All initializer functions that return Promises must be resolved before
 * the application is bootstrapped. If one of the initializers fails
 * to resolves, the application is not bootstrapped.
 *
 * @see https://angular.io/guide/dependency-injection-providers#predefined-tokens-and-multiple-providers
 *
 * @param {BootstrapService} bootstrapService
 */
export function decoupledModalServiceFactory(bootstrapService: AppModalService): Function {
  return (): Promise<boolean> => {
    return bootstrapService.register();
  };
}
