<div class="flex-row">
  <div class="flex-col-sm-12">
    <div *ngIf="listComponentService.getConfiguration() && listComponentService.getUserConfiguration()"
         class="c-table-paginator">
      <div class="view-table-paginator__filter"> {{"Ver" | t }}
        <select #paginator class="view-table-paginator__input" [(ngModel)]="pageSize"
                (ngModelChange)="changeNumerPage()">
          <option *ngFor="let optionValue of availablePageSizes">
            {{optionValue}}
          </option>
        </select> {{"de" | t }} {{ viewResult?.ResultCount.Count }}
      </div>
      <div class="view-table-paginator__filter">
        <ul class="view-table-paginator__nav o-list-bare  o-list-inline">
          <li class="view-table-paginator__nav-item  view-table-paginator__link"
              [ngClass]="{'is-active':!isFirstPage()}" (click)="!isFirstPage() && goToFirst()">{{"Primera" | t }}</li>
          <li class="view-table-paginator__nav-item  view-table-paginator__link"
              [ngClass]="{'is-active':!isFirstPage()}"
              (click)="!isFirstPage() && goToPrevious()">{{"Anterior" | t }}</li>
          <li>
            <input type="number" class="view-table-paginator__input" name="numpag" [(ngModel)]="currentNormalizedPage"
                   (keyup.enter)="changeCurrentPage()" (blur)="changeCurrentPage()" min="1" max="maxPagesList">
          </li>
          <li class="view-table-paginator__nav-item  view-table-paginator__link" [ngClass]="{'is-active':!isLastPage()}"
              (click)="!isLastPage() && goToNext()">{{"Siguiente" | t }}</li>
          <li class="view-table-paginator__nav-item  view-table-paginator__link" [ngClass]="{'is-active':!isLastPage()}"
              (click)="!isLastPage() && goToLast()">{{"Última" | t }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
