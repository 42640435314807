import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';

@Component({
  selector: 'app-input-bool',
  templateUrl: './input-bool-wrapper.component.html'
})
export class InputBoolWrapperComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput', { static: true })
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }
  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
