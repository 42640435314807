import { ChangeDetectorRef, Component, OnChanges, SimpleChanges } from '@angular/core';
import { ViewModeCalendarComponentAbstract } from '../view-mode-calendar-component-abstract.class';
import { CalendarEvent } from 'calendar-utils';
import { CalendarEventTimesChangedEvent } from 'angular-calendar';
import { CalendarModeDay } from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DateUtils } from '../../../../../utils/date.utils';
import { TranslatorService } from '../../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-view-mode-calendar-day',
  templateUrl: './view-mode-calendar-day.component.html',
  styleUrls: ['./view-mode-calendar-day.component.scss']
})
export class ViewModeCalendarDayComponent extends ViewModeCalendarComponentAbstract implements OnChanges {

  /**
   * The current Configuration
   */
  dayConfiguration: CalendarModeDay;

  /**
   * The start time
   */
  dayStartOn: Date;

  /**
   * The end time
   */
  dayEndOn: Date;

  constructor(public cdr: ChangeDetectorRef, private translator: TranslatorService) {
    super(cdr);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Emiters
  // -----------------------------------------------------------------------------------------------------
  onEventClicked(data: { event: CalendarEvent<any> }): void {
    this.eventClicked.emit(data);
  }

  onEventTimesChanged(data: CalendarEventTimesChangedEvent<any>): void {
    this.eventTimesChanged.emit(data);
  }

  protected parseConfiguration(): void {
    this.dayConfiguration = this._configuration as CalendarModeDay;
    if (this.dayConfiguration) {
      this.dayStartOn = DateUtils.ConvertTimeSpanToDate(this.dayConfiguration.StartTime);
      this.dayEndOn = DateUtils.ConvertTimeSpanToDate(this.dayConfiguration.EndTime);
      this.lang = this.translator.getAngularLang();
    }
  }
}
