import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormViewGeneratorDirective } from './form-view-generator.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    FormViewGeneratorDirective,
  ],
  exports: [
    FormViewGeneratorDirective
  ],
})
export class ViewsSharedModule {
}
