import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FormElementChartEmbeddedValue, IFormElementChartEmbeddedValue } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { NavigationService } from '../../../../../core/navigation/navigation.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-inner-chart-embedded',
  templateUrl: './inner-chart-embedded.component.html',
  animations: fuseAnimations,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InnerChartEmbeddedComponent), multi: true}
  ]
})
export class InnerChartEmbeddedComponent extends FrontendFormElementInput {

  value: IFormElementChartEmbeddedValue;

  constructor(
    protected formManagerService: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    protected navigationService: NavigationService,
    protected localeService: TranslatorService
  ) {
    super(formManagerService, cdRef, localeService);
  }

  writeValue(obj: IFormElementChartEmbeddedValue): void {
    this.value = obj;
  }

  get ChartEmbeddedValue(): FormElementChartEmbeddedValue {
    return (this.value as FormElementChartEmbeddedValue);
  }
}
