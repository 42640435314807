import { Component, OnInit } from '@angular/core';
import { ViewLocalActionsComponent } from '../../../view-headers-shared/components/view-localactions.component';
import { ListComponent2Service } from '../../../../list.service';

@Component({
  selector: 'app-view-local-actions-material',
  templateUrl: './view-local-actions-material.component.html',
  styleUrls: ['./view-local-actions-material.component.scss']
})
export class ViewLocalActionsMaterialComponent extends ViewLocalActionsComponent implements OnInit {

  /**
   * Get an instance of ViewLocalActionsMaterialComponent
   *
   * @param {ListComponentService} listComponentService
   */
  constructor(protected listComponentService: ListComponent2Service) {
    super(listComponentService);
  }

}
