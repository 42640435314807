import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicPieChartModule } from './basic-pie-chart/basic-pie-chart.module';

@NgModule({
  declarations: [],
  imports: [
    BasicPieChartModule,
    CommonModule
  ]
})
export class PieChartModule {
}
