import { ILayoutVariablesInterface } from '../interface/i-layout-variables.interface';

import { PrimeFuseLayoutVariablesClass } from '../../../layouts/prime-fuse/prime-fuse-variables.class';
import { FallApartLayoutVariablesClass } from '../../../layouts/fall-apart-layout/fall-apart-layout-variables.class';

export class LayoutConstants {
  public static readonly MainLayout: string = 'MainLayout';
  public static readonly FuseLayout: string = 'FuseLayout';
}

const LayoutVariables: { [type: string]: ILayoutVariablesInterface } = {
  'MainLayout' : new FallApartLayoutVariablesClass(),
  'FuseLayout': new PrimeFuseLayoutVariablesClass()
};

export { LayoutVariables };

