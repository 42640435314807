<div class="flex-container">
  <div class="flex-row">
    <div class="flex-col-md-12">
      <div class="flex-row">
        <div class="flex-col-md-1">
          <i class="fa fa-cog fa-5x" aria-hidden="true"></i>
        </div>
        <div class="flex-col-md-11">
          <h1>Un problema de conexión ha ocurrido.</h1>
          <hr>
          <h4>
            Compruebe que la conexión a internet es correcta y vuelva a intentar.
          </h4>
          <h4>
            Reintentando conexión
            <span *ngIf="timer">en
              <span *ngIf="fHours">
                {{ fHours }} hora<span *ngIf="plurals(fHours)">s</span>
              </span>
              <span *ngIf="fMinutes">
                {{ fMinutes }} minuto<span *ngIf="plurals(fMinutes)">s</span>
              </span>
              <span *ngIf="fSeconds">
                {{ fSeconds }} segundo<span *ngIf="plurals(fSeconds)">s</span>
              </span>
            </span>...
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
