import { Component, Input } from '@angular/core';

import { AbstractDecoupledModalComponent } from '../models/abstract-decoupled-modal.component';
import { ErrorDialogInterface } from '../models/modal-params.interface';


@Component({
  selector: 'app-decoupled-modal-error-dialog',
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent extends AbstractDecoupledModalComponent {

  /**
   * @inheritdoc
   */
  @Input()
  data: ErrorDialogInterface;

}
