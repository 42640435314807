import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { NavigationService } from '../../../core/navigation/navigation.service';

@Component({
  selector: 'app-predefined-delete',
  templateUrl: './predefined-corrective-action-delete.component.html'
})
export class PredefinedCorrectiveActionDeleteComponent implements OnInit {

  params: { predefinedId: string };

  /**
   * Creates an instance of PredefinedCorrectiveActionDeleteComponent
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private navigationService: NavigationService) {
    this.params = {
      predefinedId: null
    }
  }
  /**
   * Initialization to get the parameters from the navigation
   *
   * @memberof PredefinedCorrectiveActionDeleteComponent
   */
  ngOnInit(): void {
    const routeParams: object[] = this.navigationService.findParamsFromRoute(this.activatedRoute.snapshot);
    const predefined: object = routeParams.find(x => x.hasOwnProperty('predefined'));
    if (!isNullOrUndefined(predefined)) {
      this.params['predefinedId'] = predefined['predefined'];
    }
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   * @memberof PredefinedCorrectiveActionDeleteComponent
   */
  cancel(e: any): void {
    // redirect to list
    this.router.navigate(['../../..'], { relativeTo: this.activatedRoute });
  }

  /**
   * Handler for form succeed event.
   * @param e
   * @memberof PredefinedCorrectiveActionDeleteComponent
   */
  saveSucceed(e: any): void {
    // redirect to edit form ??
    this.router.navigate(['../../..'], { relativeTo: this.activatedRoute });
  }
}
