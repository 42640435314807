import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleNumberChartComponent } from './components/single-number-chart.component';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
    declarations: [SingleNumberChartComponent],
    imports: [
        CommonModule,
        FlexLayoutModule
    ]
})
export class SingleNumberChartModule {
}
