import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ListComponent2Service } from '../../list.service';
import { PagerInterface } from '../interfaces/pager.interface';
import { ViewsuserconfigchangedAction } from '../../viewsuserconfigchanged.eventdata';
import { takeUntil } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../../../utils/destroyableobject.trait';
import {
  ViewConfiguration,
  ViewResult,
  ViewsPagerComplete,
  ViewsPagerUserConfigurationSimple,
  ViewUserConfiguration
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * Pager Component: component used to page the elements of a list.
 * This component will receive the customized configuration of the pagination by input, and
 * will generate by output the same configuration but with the values modified by the user.
 *
 * @export PaginatorComponent
 */
@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html'
})
export class PagerComponent extends DestroyableObjectTrait implements OnInit, PagerInterface {
  currentPage = 0;
  pageSize = 25;
  availablePageSizes: number[];
  maxPagesList: number;

  hidePager: boolean = false;

  @Input() viewResult: ViewResult;

  /**
   * Creates a new instance of Paginator2Component
   * @param {ListComponent2Service} listComponentService
   */
  constructor(public listComponentService: ListComponent2Service) {
    super();
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    if (this.hidePager) {
      return 'hidden';
    }
    return '';
  }

  get currentNormalizedPage(): number {
    return this.currentPage + 1;
  }

  set currentNormalizedPage(value: number) {
    if (value > 0) {
      this.currentPage = value - 1;
    }
  }

  /**
   * Initialize the component
   */
  ngOnInit(): void {
    const configuration: ViewConfiguration = this.listComponentService.getConfiguration();
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    this.pageSize = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).PageSize;
    this.currentPage = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage;
    this.availablePageSizes = (configuration.Pager as ViewsPagerComplete).AvailablePageSizes;
    this.hidePager = this.listComponentService.data.LastPage && this.currentPage === 0 && (configuration.Pager as ViewsPagerComplete).HidePagerIfNotNeeded;
    this.listComponentService.viewDataLoaded
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.hidePager = this.listComponentService.data.LastPage && this.currentPage === 0 && (configuration.Pager as ViewsPagerComplete).HidePagerIfNotNeeded;
      });
  }

  /**
   * set User configuration to get data
   */
  changeCurrentPage(): void {
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    const maxPage: number = Math.ceil(this.viewResult.ResultCount.Count / this.pageSize);
    this.maxPagesList = maxPage > 0 ? maxPage : 1;
    if (this.currentPage >= this.maxPagesList) {
      this.currentPage = this.maxPagesList - 1;
    }
    (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage = this.currentPage;
    this.listComponentService.setUserConfiguration(
      userConfiguration,
      true,
      ViewsuserconfigchangedAction.Refresh,
      [ListComponent2Service.userConfigurationChangeNotAffectingCountTag]);
  }

  /**
   * When number of available pages change go to first and get data
   */
  changeNumerPage(): void {
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).PageSize = Number(this.pageSize);
    this.goToFirst();
  }

  /**
   * Checks if is first page
   * @returns {boolean}
   */
  isFirstPage(): boolean {
    return this.currentPage === 0;
  }

  /**
   * checks if is last page
   * @returns {boolean}
   */
  isLastPage(): boolean {
    return this.viewResult.LastPage === true;
  }

  /**
   * Goes to first page
   */
  goToFirst(): void {
    this.currentPage = 0;
    this.changeCurrentPage();
  }

  /**
   * Goes to last page
   */
  goToLast(): void {
    this.currentPage = Math.ceil(this.viewResult.ResultCount.Count / this.pageSize);
    this.changeCurrentPage();
  }

  /**
   * Go to next page if is not last
   */
  goToNext(): void {
    if (!this.isLastPage()) {
      this.currentPage++;
      this.changeCurrentPage();
    }
  }

  /**
   * Go to previous page if is not first
   */
  goToPrevious(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.changeCurrentPage();
    }
  }
}
