import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { CommunicationService } from '../../../../../core/communication/communication.service';
import {
  IViewModeField,
  IViewModeUserConfigurationColumn,
  IViewResultCell,
  ViewResultCellSimple,
  ViewResultCellType,
  ViewResultFileLink,
  ViewResultNavigationNodeLink,
  ViewResultRow
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { truncateAtWord, UtilsTypescript } from '../../../../utils/typescript.utils';
import { ListComponent2Service } from '../../../list.service';
import { NavigationService } from '../../../../../core/navigation/navigation.service';
import { ChangedetectorReference } from '../../../../../core/changedetector/changedetectoreference';
import { Params, UrlTree } from '@angular/router';

/**
 * This component will receive the customized configuration of the list by input, and
 * will generate by output the same configuration but with the values modified by the user.
 *
 * @export
 * @class GridComponent component used to show elements of a list.
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-view-grid-cell',
  templateUrl: './gridcell.component.html',
  providers: [ChangedetectorReference]
})
export class ViewGridCellComponent implements OnChanges {

  /**
   * The field include info
   */
  @Input() viewModeUserColumn: IViewModeUserConfigurationColumn;

  /**
   * The full row data. We use this so that we
   * can read dependencies between fields.
   */
  @Input() rowData: ViewResultRow;

  /**
   * The untyped field value
   */
  fieldValue: IViewResultCell;

  /**
   * Typescript utils
   *
   * @type {UtilsTypescript}
   */
  utilsTypescript = UtilsTypescript;

  /**
   * Render element as link.
   */
  renderAsLinkUri = false;

  renderAsLinkUriHref: string = null;

  renderAsLinkUriQueryParams: Params = null;

  simpleRenderString: string;

  renderAsHtml: boolean = false;

  /**
   * Import of `ViewResultCellType`.
   *
   * @type {ViewResultCellType}
   */
  ViewResultCellType = ViewResultCellType;

  /***
   * Si mostramos el ver más / ver menos
   */
  truncateContents: boolean = false;

  /**
   *
   */
  truncateContentsCollapsed: boolean = true;

  /**
   * Get an instasnce of GridCellComponent
   *
   * @param listComponentService
   */
  constructor(private listComponentService: ListComponent2Service,
              private commService: CommunicationService,
              private navigationService: NavigationService,
              private cdRef: ChangedetectorReference) {

  }

  /**
   * notify when child component change
   *
   * @memberof GridComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    const viewModeColumn: IViewModeField = this.listComponentService.getViewModeColumnFromViewModeUserColumn(this.viewModeUserColumn);
    this.fieldValue = this.rowData.Columns[viewModeColumn.Field];

    this.processFieldRenderer();
  }

  /**
   *
   */
  toogleTruncateCollapse(): void {
    const simpleCell: ViewResultCellSimple = this.fieldValue as ViewResultCellSimple;
    this.truncateContentsCollapsed = !this.truncateContentsCollapsed;
    if (this.truncateContentsCollapsed) {
      this.simpleRenderString = truncateAtWord(this.simpleRenderString, simpleCell.ShowMoreTruncateLength);
    } else {
      this.simpleRenderString = this.fieldValue.RawValue;
    }
    this.cdRef.changeDetectorParent.detectChanges();
  }

  /**
   * Process field render variables.
   */
  processFieldRenderer(): void {
    // Basic ViewResultCellType.Simple field config.
    this.renderAsLinkUri = false;
    this.renderAsLinkUriHref = null;

    switch (this.fieldValue.Type) {
      case ViewResultCellType.NavigationNodeLink:
        const nodeCell: ViewResultNavigationNodeLink = this.fieldValue as ViewResultNavigationNodeLink;
        this.renderAsLinkUri = true;
        this.renderAsLinkUriHref = this.navigationService.getUrlByController(nodeCell.Controller, nodeCell.DefaultArguments);
        let returnToUri: string = null;
        if (nodeCell.NavigateWithReturnToSelf === true) {
          returnToUri = 'self';
        }
        const tree: UrlTree = this.navigationService.getUriWithReturnToUrl(this.renderAsLinkUriHref, returnToUri, nodeCell.QueryArguments);
        this.renderAsLinkUriQueryParams = tree.queryParams;
        return;
      case ViewResultCellType.FileLink:
        const fileCell: ViewResultFileLink = this.fieldValue as ViewResultFileLink;

        /**
         * Render ViewResultFileLink as link only if has a valid URL.
         */
        if (fileCell.Url) {
          this.renderAsLinkUri = true;
          this.renderAsLinkUriHref = this.commService.generateFileUrl(fileCell.Url);
        } else {
          this.simpleRenderString = this.fieldValue.RawValue;
        }
        return;
      case ViewResultCellType.Simple:
        this.simpleRenderString = this.fieldValue.RawValue;
        const simpleCell: ViewResultCellSimple = this.fieldValue as ViewResultCellSimple;
        if (simpleCell.ShowMoreTruncateLength && this.simpleRenderString && this.simpleRenderString.length > simpleCell.ShowMoreTruncateLength) {
          this.simpleRenderString = truncateAtWord(this.simpleRenderString, simpleCell.ShowMoreTruncateLength);
          this.truncateContents = true;
        }
        return;
      case ViewResultCellType.Html:
        this.renderAsHtml = true;
        return;
    }
  }

  formatUrl(url: string): string {
    return url;
  }
}
