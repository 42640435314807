import { NgModule, APP_INITIALIZER, Injector, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetsTalkService } from './lets-talk.service';
import { EventsService } from 'app/core/events/events.service';

@NgModule({
  imports: [
    CommonModule
  ],
})
export class LetsTalkModule {
  static forRoot(): ModuleWithProviders<LetsTalkModule> {
    return {
      ngModule: LetsTalkModule,
      providers: [
        LetsTalkService,
        { provide: APP_INITIALIZER, useFactory: initializerServiceFactory, deps: [Injector], multi: true }
      ]
    };
  }
}

/**
 * Service initializer
 */
export function initializerServiceFactory(injector: Injector): Function {
  return (): void => {
    const letsTalkService: LetsTalkService = injector.get(LetsTalkService);

    letsTalkService.initialize();
  };
}
