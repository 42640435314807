import { Component, forwardRef } from '@angular/core';
import { FrontendFormElementInput } from '../../../../../../../shared/form/form-components/formelementinput.class';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fuseAnimations } from '../../../../../../../../@fuse/animations';

@Component({
  selector: 'app-inner-form-element-navigation-board',
  templateUrl: './inner-form-element-navigation-board.component.html',
  animations: fuseAnimations,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InnerFormElementNavigationBoardComponent), multi: true}
  ]
})
export class InnerFormElementNavigationBoardComponent extends FrontendFormElementInput {

  writeValue(obj: any): void {
  }
}
