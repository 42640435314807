<h1>This is a Generic Empty Page</h1>
<h2>Minium Page Information</h2>
<p>Path: {{currentMenuItemCompiled?.path}}</p>
<p>Controller: {{currentMenuItemCompiled?.controller}}</p>
<p>Frontent Path: {{currentMenuItemCompiled?.frontendPath}}</p>
<p>Redirect Frontent Path: {{currentMenuItemCompiled?.redirectedFrontendPath}}</p>
<p>Navigation Request Controller: {{navigationRequest?.requestController}}</p>
<p>Navigation Request Arguments: {{stringify(navigationRequest?.requestArguments)}}</p>
<p>Navigation Request Response Menu Path: {{navigationRequest?.responseMenuPath}}</p>
<h2>Content</h2>
<div [innerHTML]="getSafeContent()"></div>
<h2>Button Links</h2>
<ng-container *ngFor="let button of links">
  <a [id]="button['id']" [routerLink]="button['url']">{{button['title']}}</a><br/>
</ng-container>
