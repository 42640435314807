import { Directive, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router, RouterLinkWithHref } from '@angular/router';
import { MenuItemCompiledFrontend } from './models/MenuItemCompiledFrontend.class';
import { NavigationService } from './navigation.service';
import { LocationStrategy } from '@angular/common';

/**
 * Implementación custom de SabentisRouterLink
 * con el objetivo de poder lidiar con:
 * - Casos en que el elemento de menú NO apunta a nada (porque el nodo hijo no está accesible)
 */
@Directive({
  selector: 'a[appSabentisRouterLink], area[appSabentisRouterLink]'
})
export class SabentisRouterLinkDirective extends RouterLinkWithHref implements OnInit {

  /**
   * ??
   */
  @Input() mgiRouterLink: string;

  /**
   * The menu ítem
   */
  @Input() appSabentisRouterLink: MenuItemCompiledFrontend;

  /**
   *
   * @param navigationService
   * @param router
   * @param activatedRoute
   * @param locationStrategy
   */
  constructor(private navigationService: NavigationService,
              private router2: Router,
              private activatedRoute2: ActivatedRoute,
              private locationStrategy2: LocationStrategy) {
    super(router2, activatedRoute2, locationStrategy2);
  }

  /**
   *
   */
  ngOnInit(): void {
    let targetHref: string = this.appSabentisRouterLink.getFrontendPath();
    if (this.appSabentisRouterLink.accessBlocked()) {
      targetHref = '/home/accessdenied';
    }
    this.href = this.routerLink = this.mgiRouterLink = targetHref;
  }

  /**
   *
   * @param button
   * @param ctrlKey
   * @param metaKey
   * @param shiftKey
   */
  onClick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
    if (button !== 0 || ctrlKey || metaKey || shiftKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }

    const extras: NavigationExtras = {
      skipLocationChange: attrBoolValue(this.skipLocationChange),
      replaceUrl: attrBoolValue(this.replaceUrl),
      state: this.state
    };

    try {
      this.navigationService.navigateToMenuItem(this.appSabentisRouterLink, extras).subscribe();
    } catch (error) {
    }

    return false;
  }
}

function attrBoolValue(s: any): boolean {
  return s === '' || !!s;
}
