import { Injectable } from '@angular/core';
import { BaseModalParamsInterface } from './models/modal-params.interface';
import { DecoupledModalComponentInterface } from './models/decoupled-modal-component.interface';
import { DtoFrontendModal } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { getInSafe } from '../utils/typescript.utils';

@Injectable()
export class DialogWrapperService {
  /**
   * Modal reference.
   */
  reference: DecoupledModalComponentInterface;

  /**
   * Data supplied to the component to display the modal.
   *
   * This property should correspond to an object decorated by the
   * InputDecorator class.
   */
  get data(): BaseModalParamsInterface {
    return getInSafe(this.reference, ref => ref.data, null);
  }

  /**
   * Modal configuration
   */
  get config(): DtoFrontendModal {
    return getInSafe(this.data, data => data.config, null);
  }

  /**
   * Service initializer.
   */
  initialize(ref: DecoupledModalComponentInterface): void {
    this.reference = ref;
  }

  /**
   * Title setter.
   */
  setTitle(title: string): void {
    if (!this.config) {
      return;
    }

    this.config.Title = title;
  }
}
