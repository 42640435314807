import { EventEmitter, Injectable, isDevMode, Optional, SkipSelf } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { IResultCollector } from './resultcollector.interface';
import { ResultCollector } from './resultcollector.class';
import { filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { backendTypeMatch, UtilsTypescript } from '../../shared/utils/typescript.utils';
import {
    CoreCommandRedirectUser,
    CoreCommandRedirectUserTargetEnum,
    CoreCommandReloadApplication,
    ICommand
} from '../models/ETG_SABENTISpro_Application_Core_models';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
    providedIn: 'root'
})
export class CommandService {

    /**
     * Aquí los módulos pueden registrar las acciones que debe o no ejecutar cada commando (o ninguna acción)
     */
    private readonly commandExecute: EventEmitter<IResultCollector<ICommand, (() => Promise<boolean>) | Observable<boolean>>>;

    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CommandService) {

        // Protección para garantizar que esto está inyecto como SINGLETON
        if (parentModule) {
            throw new Error(
                'CommandService is already loaded. Import it in the AppModule only');
        }

        this.commandExecute = new EventEmitter<IResultCollector<ICommand, (() => Promise<boolean>) | Observable<boolean>>>();
        this.registerLegacyCommandActions();

    }

    get CommandObservable(): Observable<IResultCollector<ICommand, (() => Promise<boolean>) | Observable<boolean>>> {
        return this.commandExecute;
    }

    /**
     * Exeucte a chain of commands
     *
     * @param commands
     */
    executeCommandChain(commands: ICommand[]): Promise<boolean> {

        if (!commands || !commands.length) {
            return Promise.resolve(true);
        }

        const clonedCommands: ICommand[] = UtilsTypescript.jsonClone(commands);

        let commandActionsAggregate: {
            command: ICommand,
            callback: ((() => Promise<boolean>) | Observable<boolean>)
        }[] = [];
        const sortedClonedCommands: ICommand[] = clonedCommands.sort((i) => i.Weight);

        for (const command of sortedClonedCommands) {

            const commandActions: ResultCollector<ICommand, (() => Promise<boolean>) | Observable<boolean>> = new ResultCollector<ICommand, (() => Promise<boolean>) | Observable<boolean>>(command);

            // Esta emisión pasa de manera síncrona!
            this.commandExecute.next(commandActions);

            if (!command.$type) {
                throw new Error('No type for the command: ' + JSON.stringify(command));
            }

            if (!commandActions.resultsValue || !commandActions.resultsValue.length) {
                throw new Error('No command observable was registered for command type: ' + command.$type);
            }

            // Los async los lanzamos ya, sin enlazarlos en la cadena de promesas.
            if (command.Async === true) {
                for (const action of commandActions.resultsValue) {
                    if (action instanceof Observable) {
                        (action as Observable<any>).toPromise().then();
                    } else {
                        action().then();
                    }
                }
                continue;
            }

            commandActionsAggregate = [...commandActionsAggregate, ...commandActions.resultsValue.map(
                (i) => {
                    return {
                        command: command,
                        callback: i
                    };
                })];
        }

        // Ahora ejecutamos en cadena los resultados...

        const chainExecuted: EventEmitter<any> = new EventEmitter<any>();

        let promiseChain: Observable<boolean> = defer(() => of(true)
            .pipe(
                takeUntil(chainExecuted)
            ));

        for (const cmd of commandActionsAggregate) {
            promiseChain =
                promiseChain
                    .pipe(
                        take(1),
                        // Solo continuar si devuelve TRUE, de lo contrario abortar el pipe
                        filter((value) => value === true),
                        switchMap(() => {
                            if (cmd.callback instanceof Observable) {
                                return cmd.callback;
                            } else {
                                // https://dev.to/frederikprijck/converting-a-promise-into-an-observable-dag
                                // return defer(() => fromPromise(cmd()));
                                return fromPromise(cmd.callback());
                            }
                        })
                    );
        }

        return promiseChain.pipe(
            takeUntil(chainExecuted),
            map((value) => {
                return value;
            }),
            finalize(() => {
                if (JSON.stringify(commands) !== JSON.stringify(clonedCommands)) {
                    console.error('No debe manipular los comandos en el listener');
                    if (isDevMode()) {
                        throw new Error('No debe manipular los comandos en el listener');
                    }
                }
                console.log('Command chain execution completed');
                chainExecuted.next(null);
            })
        )
            .toPromise();
    }

    /**
     * Estas acciones deberían estar cada una en su módulo... son de core?
     */
    protected registerLegacyCommandActions(): void {

        /**
         * Comando de refreso del navegador
         */
        this.commandExecute
            .pipe(
                filter((obj: any) => backendTypeMatch(CoreCommandReloadApplication.$type, obj.Argument)),
                map((obj) => obj as IResultCollector<CoreCommandReloadApplication, () => Promise<boolean>>)
            )
            .subscribe((next) => {
                next.AddResult(() => new Promise(() => {
                    console.error('Versión de servidor no coincide con la versión de cliente. Se va a refrescar el navegador.');
                    location.reload();
                }));
            });

        /**
         * Comando de redirección a plataforma externa
         */
        this.commandExecute
            .pipe(
                filter((obj: any) => backendTypeMatch(CoreCommandRedirectUser.$type, obj.Argument)),
                map((obj) => obj as IResultCollector<CoreCommandRedirectUser, () => Promise<boolean>>)
            )
            .subscribe((next) => {
                next.AddResult(() => new Promise(() => {
                    let target: string;
                    switch (next.Argument.Target) {
                        case CoreCommandRedirectUserTargetEnum.Blank:
                            target = '_blank';
                            break;
                        case CoreCommandRedirectUserTargetEnum.Self:
                            target = '_self';
                            break;
                        case CoreCommandRedirectUserTargetEnum.Parent:
                            target = '_parent';
                            break;
                        case CoreCommandRedirectUserTargetEnum.Top:
                            target = '_top';
                            break;
                    }
                    if (next.Argument.UsePost) {
                        const mapForm: HTMLFormElement = document.createElement('form');
                        mapForm.target = target;
                        mapForm.method = 'POST'; // or "post" if appropriate
                        mapForm.action = next.Argument.Url;
                        if (next.Argument.FormData) {
                            Object.keys(next.Argument.FormData).forEach((param) => {
                                const mapInput: HTMLInputElement = document.createElement('input');
                                mapInput.type = 'hidden';
                                mapInput.name = param;
                                mapInput.setAttribute('value', next.Argument.FormData[param]);
                                mapForm.appendChild(mapInput);
                            });
                        }
                        document.body.appendChild(mapForm);
                        mapForm.submit();
                    } else {
                        window.open('', target).location.assign(next.Argument.Url);
                    }
                }));
            });
    }
}

