import { Directive, EventEmitter, HostListener, Input, NgZone, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../shared/utils/destroyableobject.trait';
import { MediaObserver } from '@angular/flex-layout';

@Directive({
  selector: '[appOnWindowResize]'
})
/**
 * Detecta cambios de tamaño en ventana, y lanza evento una vez que se estabiliza la zona de angular.
 */
export class OnWindowsResizeDirective extends DestroyableObjectTrait implements OnInit {

  private resizeDebouncer: Subject<any> = new Subject<any>();

  @Output() appOnWindowResize: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() resizeWindowDebounceTime: number = 400;

  constructor(private ngZone: NgZone,
              private mediaObserver: MediaObserver) {
    super();
  }

  ngOnInit(): void {
    this.resizeDebouncer
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(this.resizeWindowDebounceTime)
      )
      .subscribe(((event): any => {
          this.appOnWindowResize.next(event);
        }
      ).bind(this));

    this.mediaObserver.asObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.ngZone.onStable
          .pipe(
            takeUntil(this.componentDestroyed$),
            take(1)
          )
          .subscribe(() => this.resizeDebouncer.next(false));
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.ngZone.onStable
      .pipe(
          takeUntil(this.componentDestroyed$),
          take(1))
      .subscribe(() => this.resizeDebouncer.next(event));
  }
}
