import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommunicationService } from '../../../../../core/communication/communication.service';
import { MessageToastService } from '../../../../../core/message-toast/share/message-toast.service';
import { DecoupledModalBridgeService } from '../../../../decoupled-modal/decoupled-modal-bridge.service';
import { FileUploaderService } from '../../../../file-uploader/file-uploader.service';
import { FileWrapper, UploadStatus } from '../../../../file-uploader/models/file-wrapper';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FileComponentClass } from '../../input-file/file-component.class';
import { isNullOrUndefined } from '../../../../utils/typescript.utils';
import { FormElementAvatar } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { FileService } from '../../../../../core/services/ETG_SABENTISpro_Application_Core_file.service';

/**
 * Implements custom file selector.
 *
 * @see https://blog.angularindepth.com/never-again-be-confused-when-implementing-controlvalueaccessor-in-angular-forms-93b9eee9ee83
 */
@Component({
  selector: 'app-avatar-select',
  templateUrl: './avatar-select.component.html',
  styleUrls: ['./avatar-select.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AvatarSelectComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => AvatarSelectComponent), multi: true}
  ]
})
export class AvatarSelectComponent extends FileComponentClass {

  /**
   * The upload status
   */
  public uploadStatus = UploadStatus;

  public errorDimensions: string[];

  public overError: boolean;

  /**
   * Get in instance of FileSelectComponent
   */
  constructor(
      protected uploaderService: FileUploaderService,
      protected communicationService: CommunicationService,
      protected messageService: MessageToastService,
      protected cdRef: ChangeDetectorRef,
      protected dmbs: DecoupledModalBridgeService,
      protected formManagerService: FormManagerService,
      protected localeService: TranslatorService) {
    super(uploaderService, communicationService, messageService, cdRef, dmbs, formManagerService, localeService);
  }

  /**
   * Get the only file
   */
  get file(): FileWrapper {
    return this.files[0];
  }

  /**
   * Obtener la configuración del elemento
   */
  get formElement(): FormElementAvatar {
    return this.config.FormElement as FormElementAvatar;
  }

  /**
   * onUploadFiles
   * @param files
   */
  onOverError(value: boolean): void {
    this.overError = value;
  }


  /**
   * onUploadFiles
   * @param files
   */
  onUploadFilesLoaded(files: FileWrapper[]): void {
    this.cdRef.detectChanges();
    const filesToUpload: FileWrapper[] = [];
    if (files && files.length > 0) {
      files.forEach(x => {
        this.checkFileDimensions(x).then(y => {
          if (y) {
            filesToUpload.push(x);
            this.uploadFiles(filesToUpload);
          } else {
            this.cdRef.detectChanges();
          }
        });
      })
    }
  }

  checkFileDimensions(file: FileWrapper): Promise<boolean> {

    return new Promise((resolve: Function, reject: Function) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const image: any = new Image();
        image.src = e.target.result;
        return image.onload = rs => {
          const imageHeight: number = rs.currentTarget['height'];
          const imageWidth: number = rs.currentTarget['width'];

          let result: boolean = true;
          this.errorDimensions = [];
          if (!isNullOrUndefined(this.config.MaxHeight) && this.config.MaxHeight !== 0 && imageHeight > this.config.MaxHeight) {
            this.errorDimensions.push(`La altura máxima la imágen debe ser : ${this.config.MaxHeight}px. La imagen cargada tiene ${imageHeight}px de alto.`);
            result = false;
          }

          if (!isNullOrUndefined(this.config.MaxWidth) && this.config.MaxWidth !== 0 && imageWidth > this.config.MaxWidth) {
            this.errorDimensions.push(`La anchura máxima la imágen debe ser : ${this.config.MaxWidth}px. La imagen cargada tiene ${imageWidth}px de ancho.`);
            result = false;
          }

          if (!isNullOrUndefined(this.config.MinHeight) && this.config.MinHeight !== 0 && imageHeight < this.config.MinHeight) {
            this.errorDimensions.push(`La altura mínima la imágen debe ser : ${this.config.MinHeight}px. La imagen cargada tiene ${imageHeight}px de alto.`);
            result = false;
          }

          if (!isNullOrUndefined(this.config.MinWidth) && this.config.MinWidth !== 0 && imageWidth < this.config.MinWidth) {
            this.errorDimensions.push(`La anchura mínima la imágen debe ser : ${this.config.MinWidth}px. La imagen cargada tiene ${imageWidth}px de alto.`);
            result = false;
          }

          return resolve(result);
        };
      };
      reader.readAsDataURL(file.File);
    })

  }

  public imageStyle(): object {
    const style: object = {
      'max-width': this.getStyleValue(this.config.MaxWidth),
      'max-height': this.getStyleValue(this.config.MaxHeight),
      'min-width': this.getStyleValue(this.config.MinWidth),
      'min-height': this.getStyleValue(this.config.MinHeight),
    };

    return style;
  }

  private getStyleValue(value?: number): string {
    if (!isNullOrUndefined(value) && value !== 0) {
      return `${value}px`
    }
    return 'auto';
  }
}
