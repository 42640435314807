import { TYPE_MAP } from './custom-component-factory.service';
import { ICustomComponentInterface } from './i-custom-component.interface';
import { isNullOrUndefined } from '../utils/typescript.utils';

/**
 * Decorator for dynamic components.
 * On warm up all the components whose declare this component will be introduced into TYPE_MAP dictionary.
 * @param name
 * @constructor
 */
export function DynamicComponent(name: string): any {
  return function _DynamicComponent<T extends new(...args: any[]) => ICustomComponentInterface>(constr: T): void {
    if (!isNullOrUndefined(TYPE_MAP[name])) {
      throw new Error('Component with name "' + name + '" already exists.');
    }
    TYPE_MAP[name] = constr;
  };
}
