import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, mergeMap, timer } from 'rxjs';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { AppComponent } from '../../../../app/app.component';
import { getInSafe } from '../../../shared/utils/typescript.utils';
import { MessageBarComponent } from '../componentes/message-bar/message-bar.component';
import { filter, flatMap, take } from 'rxjs/operators';

export interface MessageOptionsInterface {
  closeable?: boolean,
  /**
   * If set to true then a message key must be set to reduce the messages
   * with the active ones on queue.
   */
  noRepeat?: boolean,

  /**
   * Message key to help reducer methods.
   */
  key?: string
}

export interface Message {
  sym: Symbol,
  content: string | TemplateRef<any> | any,
  options?: MessageOptionsInterface
}

@Injectable()
export class MessageBarService {

  /**
   * Reference os the `MessageBarComponent` displayed on screen.
   */
  protected messageComponentReference: ComponentRef<MessageBarComponent>;

  /**
   * List of messages available.
   */
  protected messageQueue: Message[] = [];

  /**
   * This subject emits a boolean value once the `application componente reference`
   * it's detected.
   */
  protected appReferenceInitialized$: BehaviorSubject<boolean>
      = new BehaviorSubject<boolean>(false);

  /**
   * Application root component reference.
   */
  protected appReference: ViewContainerRef;

  /**
   * `MessageBarService` class constructor.
   * @param {ApplicationRef} appRef
   * @param {ComponentFactoryResolver} factoryResolver
   */
  constructor(
      appRef: ApplicationRef,
      private factoryResolver: ComponentFactoryResolver
  ) {
    this.validateReferenceInitialization(appRef);
  }

  /**
   * Adds a message to the queue of messages to display.
   * @param {string | TemplateRef<any> | any} content
   * @param {MessageOptionsInterface} options
   */
  addMessage(
      content: string | TemplateRef<any> | any,
      options?: MessageOptionsInterface): Symbol {
    const sym: Symbol = Symbol();

    const key: string = getInSafe(options, o => o.key, null);
    if (getInSafe(options, o => o.noRepeat, true)
        && !isNullOrUndefined(key)
        && this.getMessageByKey(key) !== undefined) {
      this.messageQueue.push({sym, content, options});
    } else {
      this.messageQueue.push({sym, content, options});
    }

    this.launchMessageBar();

    return sym;
  }

  /**
   *  Launches the message bar on screen.
   */
  launchMessageBar(): void {
    this.appReferenceInitialized$
        .asObservable()
        .pipe(
            filter(init => init),
            filter(() => this.messageQueue.length > 0),
            take(1),
            mergeMap(() => {
              if (!isNullOrUndefined(this.messageComponentReference)) {
                this.messageComponentReference.instance.update();
                return;
              }
              this.messageComponentReference = this.appReference
                  .createComponent(MessageBarComponent);

              const instance: MessageBarComponent =
                  (this.messageComponentReference.instance as MessageBarComponent);

              instance.setServiceInstance(this);
              return instance.getDestroyObservable$()
            }),
            take(1))
        .subscribe(() => {
          this.messageComponentReference.destroy()
          this.messageComponentReference = null;
        })
  }

  /**
   * Returns an array of the messages in queue.
   *
   * @returns {Message[]}
   */
  getMessages(): Message[] {
    return this.messageQueue;
  }

  /**
   * Removes a message from the queue using its symbol
   *
   * @param {Symbol} sym
   * @returns {boolean} Indicates if a message has been removed
   */
  removeMessage(sym: Symbol): boolean {
    const index: number = this.messageQueue.findIndex(m => m.sym === sym);

    if (index < 0) {
      return false;
    }

    return this.messageQueue.splice(index, 1).length > 0;
  }

  /**
   * Returns a message object by its key.
   *
   * @param {string} key
   * @returns {Message}
   */
  private getMessageByKey(key: string): Message {
    return this.messageQueue
        .find(m => getInSafe(m.options, o => o.key, undefined) === key);
  }

  /**
   * Returns a message object by its Symbol.
   * @param {Symbol} sym
   * @returns {Message}
   */
  private getMessageBySymbol(sym: Symbol): Message {
    return this.messageQueue.find(m => m.sym === sym);
  }

  /**
   * Validates that the `app component reference` has been initialized, once it's
   * detected emits a new value using the `referenceInitialized$` subject.
   *
   * @param {ApplicationRef} appRef
   */
  private validateReferenceInitialization(appRef: ApplicationRef): void {
    appRef.isStable
        .pipe(
            filter(stable => stable),
            take(1),
            flatMap(() => timer(10, 10)),
            filter(i => {
              return getInSafe(appRef,
                  a => !isNullOrUndefined(a.components[0].instance), false);
            }),
            take(1))
        .subscribe(() => {
          this.appReference = (appRef.components[0].instance as AppComponent).vcr;
          this.appReferenceInitialized$.next(true);
        });
  }
}
