<ng-container *ngIf="itemSelectionMode === ViewsFieldVboSelectionMode.Multiple">
  <input type="checkbox"
         [checked]="vboChecked"
         [disabled]="vboDisabled"
         (change)="vboCheckHandler()"
         title="Seleccionar"
         class="t-select o-checkradio"
         [id]="uniqueComponentId"/>
  <label [for]="uniqueComponentId" class="t-vbo-select-label"></label>
</ng-container>
<ng-container *ngIf="itemSelectionMode === ViewsFieldVboSelectionMode.SingleRadio">
  <input type="radio"
         [checked]="vboChecked"
         [disabled]="vboDisabled"
         (change)="vboCheckHandler()"
         [name]="this.fieldInclude"
         title="Seleccionar"
         class="t-select o-checkradio"
         [id]="uniqueComponentId"/>
  <label [for]="uniqueComponentId" class="t-vbo-select-label"></label>
</ng-container>
<ng-container *ngIf="itemSelectionMode === ViewsFieldVboSelectionMode.SingleButton">
  <button class="o-btn__primary t-select" (click)="itemSelected()">Seleccionar</button>
</ng-container>
