import { IFormState } from '../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * En frontend, la "foto" del formulario
 * es el serializado + el parseado
 */
export class Formstatecontainer {

  // Formulario parseado
  formState: IFormState;

  // Formulario firmado
  signedFormState: string;
}
