import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UtilsModule } from '../../../utils/utils.module';
import { RestTranslatorModule } from '../../../../core/translator/rest-translator.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ViewGridComponent } from './components/grid.component';
import { ViewGridCellComponent } from './components/gridcell.component';
import { ViewGridHeaderComponent } from './components/gridheader.component';
import { Singleitemoperations2Component } from '../singleitemoperations/singleitemoperations.component';
import { ViewGridCellBulkOperationsComponent } from './components/grid-cell-bulk-operations.component';
import { ViewGridCellBulkReviewComponent } from './components/grid-cell-bulk-review.component';
import { GridRowDefaultComponent } from './components/grid-row-default.component';
import { GridRowGroupExpandComponent } from './components/grid-row-group-expand.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    RestTranslatorModule,
    TooltipModule.forRoot(),
    MatButtonModule,
    RouterModule,
    CollapseModule,
    MatRippleModule
  ],
    declarations: [
        ViewGridComponent,
        ViewGridCellComponent,
        ViewGridHeaderComponent,
        Singleitemoperations2Component,
        ViewGridCellBulkOperationsComponent,
        ViewGridCellBulkReviewComponent,
        GridRowDefaultComponent,
        GridRowGroupExpandComponent,
    ],
    exports: [
        ViewGridComponent,
        ViewGridCellComponent,
        ViewGridHeaderComponent,
        Singleitemoperations2Component,
        ViewGridCellBulkOperationsComponent,
        ViewGridCellBulkReviewComponent,
    ]
})
export class ViewModeTableModule {
}
