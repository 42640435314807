import { ViewsVboSelectedItem } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * Available VBO operations.
 */
export enum VboOperations {
  SELECT,
  UNSELECT,
  SELECT_ALL,
  UNSELECT_ALL,
  CLEAR
}

export class VboToggleEvent {

  /**
   * Select or unselect the item.
   */
  public operation: VboOperations;

  /**
   * The row.
   */
  public row: ViewsVboSelectedItem;

  /**
   * The fielid name
   */
  public fieldName: string;
}
