export class UtilsString {
  public static IsNullOrWhitespace(source: string)
  {
    return source == null || source.trim().length === 0;
  }

  /**
   * Generates a random "n-length" string.
   *
   * Used to generate a random log/ticket identifier.
   * @param {number} length Length of the string to generate.
   */
  public static randomString(length: number): string {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1).toUpperCase();
  }
}
