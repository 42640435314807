<div class="flex-container">
  <div class="flex-row">
    <div class="flex-col-md-12">
      <div class="flex-row">
        <div class="flex-col-md-1">
          <i class="fa fa-cog fa-5x" aria-hidden="true"></i>
        </div>
        <div class="flex-col-md-11">
          <h1>Realizando tareas de mantenimiento.</h1>
          <hr>
          <h4>Espere, por favor. Reintentando conexión{{text}}...</h4>
        </div>
      </div>
    </div>
  </div>
</div>
