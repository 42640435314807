import { ViewUserConfiguration } from '../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * Un cambio en la configuración de usuario no siempre viene acompañado
 * de un refresco completo del listado.
 */
export enum ViewsuserconfigchangedAction {

  /**
   * Refrescar el listado completamente
   */
  Refresh = 0,

  /**
   * Refrescar, pero añade los resultados al final de los que ya tuviera
   * (load more...).
   */
  Append = 1,

  /**
   * No refresca, solo lanza el evento
   */
  Ignore = 2,

  /**
   * No refresca, solo lanza el evento
   */
  ViewModeChange = 3,

  /**
   * Borra la configuración del usuario almacenada en memoria (front) y refresca el listado completamente
   */
  DropCurrentUserAndLoad = 4,
}

/**
 * Evento de cambio en la configuración de usuario del listado
 */
export class ViewsuserconfigchangedEventdata {

  /**
   * Acción que debe tomarse al refrescar la configuración de usuario.
   * Solo sirve para el propio componente de listado.. en realidad debería
   * ser un evento independiente...
   */
  refreshAction: ViewsuserconfigchangedAction;

  /**
   * La nueva configuración de usuario
   */
  userConfiguration: ViewUserConfiguration;

  /**
   * This is used for components that trigger userconfiguration changed events themselves
   * to tag the change and decide if they want or not to react to a user configuration
   * changed event that they emitted themselves.
   */
  tags: string[] = [];

  /**
   * Get an instance of ViewsuserconfigchangedEventdata
   * @param userConfig
   * @param refreshAction
   */
  constructor(
    userConfig: ViewUserConfiguration,
    refreshAction: ViewsuserconfigchangedAction = ViewsuserconfigchangedAction.Refresh,
    tags: string[] = []) {
    this.userConfiguration = userConfig;
    this.refreshAction = refreshAction;
    this.tags = tags;
  }
}

