<div class="c-message-bar" [ngClass]="classes" #container>
  <div class="message-content" [ngClass]="classesContent">
    {{ content }}
    <strong>(Mensaje: {{ messageNumber + 1 }} / {{ totalMessages }})</strong>
  </div>
  <div class="message-controls">
    <button (click)="next()" *ngIf="!disableNavigation"><i class="fa fa-arrow-circle-left"></i></button>
    <button (click)="prev()" *ngIf="!disableNavigation"><i class="fa fa-arrow-circle-right"></i></button>
    <button (click)="destroyMessage()" *ngIf="isRemovable()">
      <i class="fa fa-times"></i>
    </button>
  </div>
</div>
