<div class="paginator-container">
  <mat-paginator
    [length]="length"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [pageSizeOptions]="availablePageSizes"
    (page)="changeCurrentPage($event)"
    (click)="clickHandler()"
    (mouseover)="mouseOverHandler()"
    #paginator>
  </mat-paginator>
  <button
    mat-icon-button
    class="load-count-button"
    [style.visibility]="isLoadCountButtonVisible ? 'visible' : 'hidden'"
    [matTooltip]="'Contar total de registros' | t"
    (mouseover)="mouseOverHandler()"
    (click)="loadCount()">
      <mat-icon>restore_page</mat-icon>
  </button>
</div>
