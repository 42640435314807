import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { DestroyableObjectTrait } from '../../../../../shared/utils/destroyableobject.trait';
import { delay, map, switchMap, takeUntil } from 'rxjs/operators';
import { asIterableObject, getInSafe, isNullOrUndefined } from '../../../../../shared/utils/typescript.utils';
import { Guid } from 'guid-typescript';
import {
  ChartDataBatch,
  ChartDataSeries,
  ChartExecutionResponse,
  ChartPluginRequest,
  ChartUserConfiguration,
  CoreBatchActionEnum,
  FormSubmitData,
  IChartExportingResult,
  WebServiceResponse,
  WebServiceResponseTyped
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ChartService } from '../../../../../core/services/ETG_SABENTISpro_Application_Core_chart.service';
import { BatchService } from '../../../../../core/services/ETG_SABENTISpro_Application_Core_batch.service';
import { GoogleanalyticsService } from '../../../../../googleanalytics/googleanalytics.service';
import { compactTrackingAction } from '../../../../../googleanalytics/googleanalytics.module';
import { Formstatecontainer } from '../../../../../shared/form/formstatecontainer.class';

@Injectable()
export class FuseChartsService extends DestroyableObjectTrait implements OnDestroy {


  /**
   * This subject emits an event when the component is being destroyed.
   * Reference to the main component's change detector
   */
  public cdRef: ChangeDetectorRef;

  /**
   * Inner id
   */
  public id: Guid;

  /**
   * Last ChartExecutionResponse
   */
  private _lastChartExecutionResponse: ChartExecutionResponse;

  /**
   * ChartUserConfiguration
   * @protected
   */
  private _currentUserConfiguration: ChartUserConfiguration;

  /**
   * Current User Configuration Observable
   */
  protected _lastChartExecutionResponseChanges: ReplaySubject<ChartExecutionResponse>
      = new ReplaySubject<ChartExecutionResponse>(1);

  /**
   * Current User Configuration Observable
   */
  protected _currentUserConfigurationChanged: ReplaySubject<ChartUserConfiguration>
      = new ReplaySubject<ChartUserConfiguration>(1);

  /**
   * Current User Configuration Observable getter
   */
  public get lastChartExecutionResponseChanges(): ReplaySubject<ChartExecutionResponse> {
    return this._lastChartExecutionResponseChanges;
  }

  /**
   * Current User Configuration Observable getter
   */
  public get currentChartUserConfiguration(): ReplaySubject<ChartUserConfiguration> {
    return this._currentUserConfigurationChanged;
  }

  constructor(protected backendChartService: ChartService,
              protected batchManager: BatchService,
              protected googleAnalyticService: GoogleanalyticsService) {
    super();
    this.id = Guid.create();
  }

  public load(pluginRequest: ChartPluginRequest, userConfiguration: ChartUserConfiguration, skipCaches: boolean = false): Observable<ChartExecutionResponse> {
    const unresolved: (request: ChartPluginRequest) => Observable<ChartExecutionResponse> = (request: ChartPluginRequest) => {
      this.googleAnalyticService.pushEventAction(compactTrackingAction('cht', pluginRequest.Id, 'req'));
      return this.backendChartService.postLoad(request, request.Id, userConfiguration, skipCaches, {showSpinner: false})
          .pipe(
              takeUntil(this.componentDestroyed$),
              switchMap((response: WebServiceResponseTyped<ChartExecutionResponse>) => {
                if (ChartDataSeries.$type.includes(response.result.CompiledChart.Data.$type)) {
                  this.googleAnalyticService.pushEventAction(compactTrackingAction('cht', pluginRequest.Id, 'load'));
                  this._lastChartExecutionResponse = response.result;
                  this._currentUserConfiguration = response.result.UserConfiguration;
                  this._lastChartExecutionResponseChanges.next(response.result);
                  return of(response.result);
                }
                if (ChartDataBatch.$type.includes(response.result.CompiledChart.Data.$type)) {
                  pluginRequest = Object.assign(new ChartPluginRequest(), pluginRequest);
                  pluginRequest.DeferredQueueId = response.result.PluginRequest.DeferredQueueId;
                  return of(true)
                      .pipe(
                          delay(getInSafe(response, x => x.result.PluginRequest.DeferredExecutionRefreshInterval, 5000)),
                          switchMap(() => unresolved(pluginRequest))
                      );
                }
                throw new Error('Unable to continue the execution chain.');
              }));
    };

    return unresolved(pluginRequest);
  }

  /**
   * Change the selected chart from the AvailableCharts
   * @param id
   */
  public changeChart(id: string): void {
    if (asIterableObject(this._lastChartExecutionResponse.CompiledChart.AvailableCharts).findIndex(x => x.Id === id) !== -1) {
      const newCurrentUserConfiguration: ChartUserConfiguration = Object.assign(new ChartUserConfiguration(), this._currentUserConfiguration);
      newCurrentUserConfiguration.SelectedChart = id;
      this.updateUserConfiguration(newCurrentUserConfiguration);
      return;
    }
    throw new Error(`The selected chart ${id} not exists in the current AvailableCharts configuration`);
  }

  /**
   * Apply Chart Filters using a Form
   * @param data
   */
  public applyChartFilters(data: FormSubmitData, formState: Formstatecontainer = null): void {
    const newCurrentUserConfiguration: ChartUserConfiguration = Object.assign(new ChartUserConfiguration(), this._currentUserConfiguration);
    newCurrentUserConfiguration.FormSubmitData = data;
    if (!isNullOrUndefined(formState)) {
      newCurrentUserConfiguration.SignedFormState = formState.signedFormState;
    }
    this.load(this._lastChartExecutionResponse.PluginRequest, newCurrentUserConfiguration, true)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(x => {
          this.updateUserConfiguration(x.UserConfiguration);
        })
  }


  /***
   * Get the FileRef
   * @param pluginRequest
   */
  public exportAsImage(pluginRequest: ChartPluginRequest, deferredExecution: boolean): Observable<IChartExportingResult> {
    this.googleAnalyticService.pushEventAction(compactTrackingAction('cht', pluginRequest.Id, 'image'));
    pluginRequest.DeferredExecution = false;
    return this.backendChartService.postExportrequestimage(pluginRequest, this._currentUserConfiguration, deferredExecution, pluginRequest.Id, {showSpinner: false})
        .pipe(
            takeUntil(this.componentDestroyed$))
        .pipe(map((response: WebServiceResponseTyped<IChartExportingResult>) => {
          return response.result;
        }));
  }

  /***
   * Get the FileRef
   * @param pluginRequest
   */
  public exportAsExcel(pluginRequest: ChartPluginRequest, deferredExecution: boolean): Observable<IChartExportingResult> {
    pluginRequest.DeferredExecution = false;
    this.googleAnalyticService.pushEventAction(compactTrackingAction('cht', pluginRequest.Id, 'excel'));
    return this.backendChartService.postExportrequestexcel(pluginRequest, this._currentUserConfiguration, deferredExecution, pluginRequest.Id, {showSpinner: false})
        .pipe(
            takeUntil(this.componentDestroyed$),
            map((response: WebServiceResponseTyped<IChartExportingResult>) => {
              return response.result;
            }));
  }

  /***
   * Export chart as html table
   * @param pluginRequest
   */
  public exportAsHtmlTable(pluginRequest: ChartPluginRequest): Observable<IChartExportingResult> {
    return this.backendChartService.postExportrequesthtmltable(pluginRequest, this._currentUserConfiguration, pluginRequest.Id, {showSpinner: false})
        .pipe(
            takeUntil(this.componentDestroyed$),
            map((response: WebServiceResponseTyped<IChartExportingResult>) => {
              return response.result;
            }));
  }

  /**
   * Detect changes
   */
  public detectChanges(): void {
    // A veces se usa este servicio, sin que exista la GUI (por ejemplo en el selector de contexto)
    if (isNullOrUndefined(this.cdRef)) {
      return;
    }
    this.cdRef.detectChanges();
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    if (isNullOrUndefined(this._lastChartExecutionResponse) || isNullOrUndefined(this._lastChartExecutionResponse.PluginRequest.DeferredQueueId)) {
      super.ngOnDestroy();
      return;
    }
    this.batchManager.postTaskaction(this._lastChartExecutionResponse.PluginRequest.DeferredQueueId, CoreBatchActionEnum.CANCEL).pipe(
        takeUntil(this.componentDestroyed$))
        .subscribe((x: WebServiceResponse) => {
              console.debug(`Chart Queue with id ${this._lastChartExecutionResponse.PluginRequest.DeferredQueueId} was destroyed`);
              super.ngOnDestroy();
            }
        );
  }

  /**
   * updateUserConfiguration
   * @param newUserConfiguration
   * @private
   */
  private updateUserConfiguration(newUserConfiguration: ChartUserConfiguration): void {
    this._currentUserConfiguration = newUserConfiguration;
    this._currentUserConfigurationChanged.next(newUserConfiguration);
  }
}
