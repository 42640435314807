import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, EventEmitter, Injectable, NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { AppConfigurationService } from '../../app.configuration.service';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import apply = Reflect.apply;

/**
 * Authentication service.
 */
@Injectable()
export class ChangedetectorService extends DestroyableObjectTrait {

  private ngZoneEmptyRunEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Ctor of Auth Service
   * @param {Router} router
   * @param {HttpClient} http
   * @param {AppConfigurationService} appConfigurationService
   */
  constructor(
    private router: Router,
    private ngZone: NgZone
  ) {
    super();

    this.ngZoneEmptyRunEventEmitter
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.ngZone.run(() => {
        });
      });

    /**
     * El objetivo de esta suscripción es que, al eliminar casi en su totalidad
     * los eventos de angular que lanzan change detection, tenemos que colgarnos de la
     * navegación y lanzar al change detection manualmente.
     */
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.runApplicationChangeDetection();
      }
    });
  }

  /**
   * Run change detection on the ref if we are not running inside angular
   *
   * @param cdRef
   */
  detectChangesIfNotInAngular(cdRef: ChangeDetectorRef): void {
    if (Zone.current.name === 'angular') {
      return;
    }
    cdRef.detectChanges();
  }


  /**
   * Refresh an app if current zone is not angular zone
   */
  runApplicationChangeDetection(force: boolean = false): void {
    if (Zone.current.name === 'angular' && force === false) {
      return;
    }
    this.ngZoneEmptyRunEventEmitter.emit();
  }

  /**
   * Run some code inside the angular zone (async)
   *
   * @param fn
   * @param applyThis
   * @param applyArgs
   */
  runInsideAngularAsync<T>(fn: (...args: any[]) => T, applyThis?: any, applyArgs?: any[]): Observable<T> {

    const evFinished: EventEmitter<T> = new EventEmitter<T>();

    this.ngZone.run(() => {
      setTimeout(() => {
        const result: T = fn.bind(applyThis).apply(applyArgs);
        evFinished.emit(result);
        evFinished.complete();
      });
    });

    return evFinished.asObservable();
  }

  /**
   * Run conde inside the angular zone
   *
   * @param fn
   * @param applyThis
   * @param applyArgs
   */
  runInsideAngular<T>(fn: (...args: any[]) => T, applyThis?: any, applyArgs?: any[]): T {

    if (Zone.current.name === 'angular') {
      return fn.bind(applyThis).apply(applyArgs);
    }

    let result: T;

    this.ngZone.run(() => {
      result = fn.bind(applyThis).apply(applyArgs) as T;
    });

    return result;
  }
}
