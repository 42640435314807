import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, ComponentFactoryResolver } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RestTranslatorModule } from '../../core/translator/rest-translator.module';
import { BatchModule } from '../batch/batch.module';
import { SFormModule } from '../form/form.module';
import { AppModalService } from './app-modal.service';
import { BaseDialogComponent } from './components/base-dialog.component';
import { BatchDialogComponent } from './components/batch-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { ErrorDialogComponent } from './components/error-dialog.component';
import { FormDialogComponent } from './components/form-dialog.component';
import { CoalescingComponentFactoryResolver } from '../../core/coalescing-factory-resolver.service';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        ErrorDialogComponent,
        FormDialogComponent,
        BaseDialogComponent,
        BatchDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SFormModule,
        RestTranslatorModule,
        BatchModule
    ],
    exports: [
        BaseDialogComponent
    ]
})
export class DecoupledModalModule {
  static forRoot(): ModuleWithProviders<DecoupledModalModule> {
    return {
      ngModule: DecoupledModalModule,
      providers: [
        AppModalService
      ]
    }
  }

  constructor(
    coalescingResolver: CoalescingComponentFactoryResolver,
    localResolver: ComponentFactoryResolver
  ) {
    // Registramos ComponentFactoryResolver local (laxy-loaded) en resolver
    // coalescente para ampliar visibilidad.
    coalescingResolver.registerResolver(localResolver);
  }
}
