/**
 * Clase para pasar a través del router configuración estática del componente
 *
 * POR FAVOR NO METER MIERDAS EN ESTE COMPONENTE, SI NO TE SIRVE, CREA UNO AD-HOC PARA EL CASO DE USO
 * QUE REQUIERAS. ESTE COMPONENTE ES UN FORMULARIO-LISTADO-FORMULARIO. NADA MÁS.
 */
export class GenericFormListFormPageSimpleArguments {

  /**
   * El id del formulario
   */
  form1Id: string;

  /**
   * Map of route arguments to form parameteres
   */
  form1ArgumentMapping: { [id: string]: string };

  /**
   * El id del formulario
   */
  form2Id: string;

  /**
   * Map of route arguments to form parameteres
   */
  form2ArgumentMapping: { [id: string]: string };

  /**
   * El id del formulario
   */
  form3Id?: string;

  /**
   * Map of route arguments to form parameteres
   */
  form3ArgumentMapping?: { [id: string]: string };

  /**
   * El ide del formulario
   */
  list1Id?: string;

  /**
   * Map of route arguments to form parameteres
   */
  list1ArgumentMapping?: { [id: string]: string };

  /**
   * Deshabilita el Intersection Observer API del listado
   */
  disableListIntersectionObserverApi: boolean;

  /**
   * Constructor
   * @param form1Id
   * @param form1ArgumentMapping
   * @param list1Id
   * @param list1ArgumentMapping
   * @param disableListIntersectionObserverApiValue
   * @param form2Id
   * @param form2ArgumentMapping
   * @param form3Id
   * @param form3ArgumentMapping
   */
  constructor(form1Id: string,
              form1ArgumentMapping: { [id: string]: string },
              list1Id?: string,
              list1ArgumentMapping?: { [id: string]: string },
              disableListIntersectionObserverApiValue?: boolean,
              form2Id?: string,
              form2ArgumentMapping?: { [id: string]: string },
              form3Id?: string,
              form3ArgumentMapping?: { [id: string]: string }) {
    this.form1Id = form1Id;
    this.form1ArgumentMapping = form1ArgumentMapping;
    this.form2Id = form2Id;
    this.form2ArgumentMapping = form2ArgumentMapping;
    this.list1Id = list1Id;
    this.list1ArgumentMapping = list1ArgumentMapping;
    this.form3Id = form3Id;
    this.form3ArgumentMapping = form3ArgumentMapping;
    this.disableListIntersectionObserverApi = disableListIntersectionObserverApiValue;
  }
}
