<ng-container *ngIf="this.viewConfiguration && this.viewConfiguration.QuickSearch">
  <div class="views-searcher-new--main">
    <div class="search-by" (appClickOutside)="clickOutsideHandler($event)">
      <div class="dropdown" [class.open]="this.showSearchByList">
        <ul class="dropdown-menu">
          <li>
            <div class="fix-dropdown-checkbox">
              <div *ngFor="let field of fields" class="c-radio-checkbox">
                <input
                    type="checkbox"
                    [id]="uniqueComponentId + field.FieldId"
                    class="o-checkradio"
                    [checked]="isChecked(field.FieldId)"
                    [class.disabled]="isDisabled(field.FieldId)"
                    [tooltip]="itemTooltip(field.FieldId)"
                    (change)="inputChanged($event, field.FieldId)">
                <label [for]="uniqueComponentId + field.FieldId">{{ field.Name }}</label>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div (click)="toggleDropdown($event)" class="views-searcher-new--toogler">
        <span class="search-summary" [tooltip]="quickSearchFieldSummary">{{quickSearchFieldSummaryDisplay}}</span>
        <i class="m-fi" [class.m-fi--arrow-point-up]="showSearchByList"
           [class.m-fi--arrow-point-down]="!showSearchByList"></i>
      </div>
    </div>
    <div class="search-content">
      <input type="search"
             class="no-focusborder t-quicksearch-input"
             placeholder="Ingresar búsqueda"
             [disabled]="!this.hasCheckedFields"
             (keyup)="searchChange($event);"
             (keypress)="preventEventPropagation($event);"
             (click)="inputFocus($event)"
             [(ngModel)]="searchString">
    </div>
    <button type="button" class="c-form-field__button search-action"
            [disabled]="this.quickSearchUserConfiguration?.Fields?.length === 0 || !this.searchString || searchString===''"
            [class.--disabled]="this.quickSearchUserConfiguration?.Fields?.length === 0 || !this.searchString || searchString===''"
            (click)="searchChange($event);">
      <i class="m-fi  m-fi--search af af-magnifier"></i>
    </button>
  </div>
</ng-container>
