import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementInput } from '../../../../../../../shared/form/form-components/formelementinput.class';
import { FrontendFormElementWrapper } from '../../../../../../../shared/form/form-components/formelementwrapper.class';
import { ViewsselectorwrapperComponent } from '../../../../../../../shared/form/form-components/viewsselector/viewsselectorwrapper/viewsselectorwrapper.component';

@Component({
  selector: 'app-prime-fuse-view-selector-wrapper',
  templateUrl: './prime-fuse-view-selector-wrapper.component.html'
})
export class PrimeFuseViewSelectorWrapperComponent extends ViewsselectorwrapperComponent {
  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
