import { ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { TimepickerComponent, TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { isNullOrUndefined } from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form-timepicker',
  templateUrl: './time-picker.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimePickerComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => TimePickerComponent), multi: true}
  ]
})
export class TimePickerComponent extends FrontendFormElementInput implements OnDestroy, OnInit {

  @ViewChild('timepicker', {static: true})
  formElementInstanceValue: TimepickerComponent;

  protected inputValueTime: Date;

  timePickerConfig: TimepickerConfig;

  isValid: boolean;

  set timeInput(value: Date) {
    if (isNullOrUndefined(value)) {
      this.inputValueTime = null;
    } else {
      this.inputValueTime = value;
    }
    this.propagateChange(this.inputValueTime);
  }

  get timeInput(): Date {
    return this.inputValueTime;
  }

  detectChanges(): void {
    this.cdRef.detectChanges();
  }

  /**
   * Get an instance of DatetimeComponent
   * @param formManagerService
   */
  constructor(protected formManager: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected localeService: TranslatorService) {

    super(formManager, cdRef, localeService);

  }

  customPropagateTouch(): any {
    this.propagateTouch();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.generateBsConfig();
    this.formElementInstanceValue
        .isValid
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(((isValid: boolean): void => {
          this.isValid = isValid;

          const component: AbstractControl = this.formManagerService
              .getFormComponent(this.config.ClientPath);

          component.updateValueAndValidity({emitEvent: true, onlySelf: false});
        }).bind(this));
  }

  /**
   * This function is described on the ControlValueAccessor interfacte.
   * Is used by the Angular Form to set the value that comes from the server
   * @param value: value set by Angular Forms Manager
   */
  writeValue(value: Date): void {
    this.inputValueTime = new Date(value);
  }

  doValidate(c: AbstractControl): ValidationErrors {

    const errors: ValidationErrors = super.doValidate(c);

    if (this.isValid === false) {
      errors['invalid-time'] = 'La hora indicada no es válida.';
    }

    return errors;
  }

  /**
   * Generate BS config
   */
  protected generateBsConfig(): void {
    this.timePickerConfig = new TimepickerConfig();
    // Se ha quitado esto porque no se usaba, y  hay que adaptarlo al valor interno real del componente
    // this.timePickerConfig.max = (this.config.FormElement as FormElementTimePicker).MaxTime as any as Date;
    // this.timePickerConfig.min = (this.config.FormElement as FormElementTimePicker).MinTime as any as Date;
  }
}
