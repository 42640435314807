import { Component, OnDestroy, OnInit } from '@angular/core';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';
import {
  ViewsFieldVboSelectionMode,
  ViewsVboSelectedItem
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../list.service';
import { VboOperations } from '../../../events/vboitemtoogle.eventdata';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ChangedetectorReference } from '../../../../../core/changedetector/changedetectoreference';
import { getInSafe } from '../../../../utils/typescript.utils';

/**
 * The ViewGridCellBulkReviewComponent component reviews the quantity and identifiers for the
 * selected vbo items in the current view.
 */
@Component({
  selector: 'app-view-grid-cell-bulk-review',
  templateUrl: './grid-cell-bulk-review.component.html',
  providers: [
    ChangedetectorReference
  ]
})
export class ViewGridCellBulkReviewComponent extends DestroyableObjectTrait implements OnInit, OnDestroy {

  public totalItems: number = NaN;
  public isNaN = (x: number) => Number.isNaN(x);

  /**
   * Class constructor.
   */
  constructor(
    public viewService: ListComponent2Service,
    protected cdReference: ChangedetectorReference
  ) {
    super();
  }

  ngOnInit(): void {
    // Al seleccionar todos los items, obtenemos el número total de registros
    this.viewService
      .onVboItemToggle
      .pipe(
        filter(x => x.operation === VboOperations.SELECT_ALL),
        switchMap(() => this.viewService.getTotalItemCount()),
        map((i) => i.Count),
        takeUntil(this.componentDestroyed$),
        distinctUntilChanged()
      ).subscribe(x => {
        this.totalItems = x;
        this.cdReference.changeDetector.detectChanges();
    });

    // Si están seleccionados todos los registros, actualizamos el número total al cambiar la configuración del listado.
    this.viewService
      .viewDataLoadedWithReplay
      .pipe(
        filter(x => this.isVboAllSelected()),
        switchMap(() => this.viewService.getTotalItemCount()),
        map((i) => i.Count),
        takeUntil(this.componentDestroyed$),
        distinctUntilChanged()
      ).subscribe(x => {
      this.totalItems = x;
      this.cdReference.changeDetector.detectChanges();
    });
  }

  /**
   * Returns a integer indicating if there is any VBO item selected on the view.
   *
   * @returns {number}
   */
  selectedItems(): number {
    return this.viewService.countVboItems();
  }

  /**
   * Returns a intenger indicating the total result count for the view.
   *
   * @returns {number}
   */
  get totalItemsInView(): number {
    return this.totalItems;
  }

  /**
   * Returns an array with the selected items for the view.
   *
   * @returns {ViewsVboSelectedItem[]}
   */
  getSelectedItems(): ViewsVboSelectedItem[] {
    return Object.keys(this.viewService.vboUserConfiguration.SelectedItems)
      .map(k => this.viewService.vboUserConfiguration.SelectedItems[k]);
  }

  /**
   * Cuando mostrar u ocultar el review
   */
  showVboReview(): boolean {
    // Si se oculta explícitamente, o si solo puedo seleccionar un elemento
    return this.viewService.vboField.HideSelectedItemsReview !== true || this.viewService.vboField.ItemSelectionMode === ViewsFieldVboSelectionMode.SingleButton;
  }

  /**
   * Removes a selected item from the view.
   *
   * @param {ViewsVboSelectedItem} item Seleted item.
   */
  removeSelectedItem(item: ViewsVboSelectedItem): void {
    this.viewService.vboToggleItemHandler({
      operation: VboOperations.UNSELECT,
      row: item,
      fieldName: 'vbo'
    });
  }

  /**
   * Removes the all selected option.
   */
  vboToggleAll(): void {
    this.viewService.vboToggleAll();
  }

  /**
   * Returns a boolean indicating if all rows in the view are vbo selected.
   *
   * @returns {boolean}
   */
  isVboAllSelected(): boolean {
    return this.viewService.isVboAllSelected();
  }

  itemTooltip(item: ViewsVboSelectedItem): string {
    if (item.Disabled === true) {
      return 'Este elemento no es editable.';
    }
    return null;
  }
}
