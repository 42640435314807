import { Attribute, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router, RouterLink } from '@angular/router';
import { MenuItemCompiledFrontend } from './models/MenuItemCompiledFrontend.class';
import { isNullOrUndefined } from '../../shared/utils/typescript.utils';
import { NavigationService } from './navigation.service';

/**
 * Implementación custom de SabentisRouterLink
 * con el objetivo de poder lidiar con:
 * - Casos en que el elemento de menú NO apunta a nada (porque el nodo hijo no está accesible)
 */
@Directive({
  selector: ':not(a):not(area)[appSabentisRouterLink]'
})
export class SabentisRouterLinkWithoutHrefDirective extends RouterLink implements OnInit {

  /**
   * ??
   */
  @Input() mgiRouterLink: string;

  /**
   * The menu ítem
   */
  @Input() appSabentisRouterLink: MenuItemCompiledFrontend;

  /**
   *
   * @param navigationService
   * @param router
   * @param activatedRoute
   * @param locationStrategy
   */
  constructor(private router2: Router,
              private route2: ActivatedRoute,
              @Attribute('tabindex') tabIndex2: string,
              renderer2: Renderer2,
              el2: ElementRef,
              protected navigationService: NavigationService) {
    super(router2, route2, tabIndex2, renderer2, el2);
  }

  /**
   *
   */
  ngOnInit(): void {
    let targetHref: string = this.appSabentisRouterLink.getFrontendPath();
    if (this.appSabentisRouterLink.accessBlocked()) {
      targetHref = '/home/accessdenied';
    }
  }

  /**
   *
   * @param button
   * @param ctrlKey
   * @param metaKey
   * @param shiftKey
   */
  onClick(): boolean {

    const extras: NavigationExtras = {
      skipLocationChange: attrBoolValue(this.skipLocationChange),
      replaceUrl: attrBoolValue(this.replaceUrl),
      state: this.state
    };

    this.navigationService.navigateToMenuItem(this.appSabentisRouterLink, extras).subscribe();
    return false;
  }
}

function attrBoolValue(s: any): boolean {
  return s === '' || !!s;
}
