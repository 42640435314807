import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { MailTrackingService } from '../../core/services/ETG_SABENTISpro_Application_Modules_mailtracking.service';
import { Router } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { BrowserTabService } from '../../core/browser-tab/browser-tab.service';
import { WebServiceResponseTyped } from '../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-mailbox-info',
  templateUrl: './mailbox-info.component.html',
  styleUrls: ['./mailbox-info.component.scss']
})
export class MailboxInfoComponent extends DestroyableObjectTrait implements OnInit {

  private intervalSubscription: Subscription;

  constructor(private mailtrackingService: MailTrackingService,
              private cdReference: ChangeDetectorRef,
              private zone: NgZone,
              private router: Router,
              private browserTabService: BrowserTabService) {
    super();
  }
  totalMailsUnreaded: number;

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.intervalSubscription = interval(10000)
        .pipe(
          filter(() => this.browserTabService.getCurrentIsVisible()),
          switchMap(() => {
            return this.zone.run(() => {
              return this.refreshNotification(
                this.mailtrackingService.getHasnewemail({ showSpinner: false })
              );
            });
          }),
          takeUntil(this.componentDestroyed$)
        )
        .subscribe();
    });

  }

  verNotificacion(): boolean {
    return this.totalMailsUnreaded !== 0;
  }

  refreshNotification(backendApi: Observable<WebServiceResponseTyped<number>>): Observable<any> {
    return backendApi
      .pipe(
        takeUntil(this.componentDestroyed$),
        take(1),
        map((response: WebServiceResponseTyped<number>): void => {
          if (this.totalMailsUnreaded === response.result) {
            return;
          }
          this.totalMailsUnreaded = response.result;
        })
      );
  }

  onClick(): void {
    this.router.navigateByUrl('/main/home/mailtracking/inbox');
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
