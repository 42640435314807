import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FormElementQRCodeValueForm, FormPluginRequest } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { NavigationService } from '../../../../../core/navigation/navigation.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router, UrlTree } from '@angular/router';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-inner-qr-code',
  templateUrl: './inner-qr-code.component.html',
  styleUrls: ['./inner-qr-code.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InnerQrCodeComponent), multi: true}
  ]
})
export class InnerQrCodeComponent extends FrontendFormElementInput {

  value: FormElementQRCodeValueForm;
  url: string;
  partialUrl: string;

  constructor(
    protected formManagerService: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    protected navigationService: NavigationService,
    protected router: Router,
    protected localeService: TranslatorService
  ) {
    super(formManagerService, cdRef, localeService);
  }

  writeValue(obj: FormElementQRCodeValueForm): void {
    this.value = obj;
    this.generateFormUrl(obj as FormElementQRCodeValueForm);
  }

  private generateFormUrl(value: FormElementQRCodeValueForm): void {
    this.url = this.getQrFormFullUrl(value.PluginRequest);
    this.partialUrl = this.getQrFormUrl(value.PluginRequest);
  }

  getQrFormUrl(pluginRequest: FormPluginRequest): string {
    const urlBase: string = this.navigationService.addLayoutPrefixIfMissing('qr-code/form');
    const url: UrlTree = this.router.createUrlTree([urlBase], {
      queryParams: {
        pluginRequest: btoa(JSON.stringify(pluginRequest))
      }
    });
    return url.toString();
  }

  getQrFormFullUrl(pluginRequest: FormPluginRequest):
    string {
    const baseUrl: string = window.location.origin;
    return (baseUrl + this.getQrFormUrl(pluginRequest)).replace('//', '/');
  }
}
