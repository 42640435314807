<div class="chart-container"
     (appOnWindowResize)="onNgxChartResizedWithDirective()"
     (window:resize)="onNgxChartResized($event)"
     resizeWindowDebounceTime="500">
  <ngx-charts-pie-chart
      [results]="data"
      [gradient]="displayOptions.Gradient"
      [labels]="displayOptions.Labels"
      [doughnut]="displayOptions?.Doughnut"
      [scheme]="colorScheme"
      [legend]="getLegendDisplayOptions()?.ShowLegend"
      [legendTitle]="getLegendDisplayOptions()?.LegendTitle"
      [legendPosition]="getLegendDisplayOptions()?.LegendPosition"
      [tooltipDisabled]="displayOptions?.TooltipDisabled"
      [trimLabels]="displayOptions?.TrimLabels"
      [explodeSlices]="displayOptions?.ExplodeSlices"
      [view]="view"
      #chart>
    <ng-template #tooltipTemplate let-model="model"
                 *ngIf="displayOptions && !displayOptions?.TooltipDisabled && displayOptions?.TooltipText">
      <div [innerHTML]="getTooltipText(model)"></div>
    </ng-template>
  </ngx-charts-pie-chart>
</div>
