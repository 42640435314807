import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, NgZone, SkipSelf, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
  FormElementAutocompleteCallbackResult,
  FormElementOption,
  FormSubmitData,
  IFormElementOption
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { ChangedetectorService } from '../../../../../core/changedetector/changedetector.service';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { TagInputComponent } from 'ngx-chips';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-inputchips',
  templateUrl: './inputchips.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputchipsComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputchipsComponent), multi: true},
    ChangedetectorService
  ]
})

export class InputchipsComponent extends FrontendFormElementInput implements AfterViewInit {

  @ViewChild('tagInputComponent', {static: true}) tagInputComponent: TagInputComponent

  public itemsValue: IFormElementOption[] = [];

  public get items(): IFormElementOption[] {
    return this.itemsValue;
  }

  public set items(items: IFormElementOption[]) {
    this.itemsValue = items;
  }

  private itemsSubject$: Subject<IFormElementOption[]> = new Subject<IFormElementOption[]>();

  /**
   *
   * @param {FormManagerService} formManagerService
   * @param cdRef
   * @param cdRefParent
   * @param cdService
   * @param ngZone
   * @param localeService
   */
  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected cdService: ChangedetectorService,
              protected ngZone: NgZone,
              protected localeService: TranslatorService,
              @SkipSelf()
              protected cdRefParent: ChangeDetectorRef,) {
    super(formManagerService, cdRef, localeService);
  }

  /**
   * Transform the input to FormElementOption
   * @param value
   */
  public transform(value: any): Observable<IFormElementOption> {
    let item: IFormElementOption = new FormElementOption();
    if (value.hasOwnProperty('Key') && value.hasOwnProperty('Name')) {
      return of(value);
    }

    return of({Key: item.Key, Name: item.Name} as IFormElementOption)
  }

  /**
   * Searches the avaible tags
   * @param text
   */
  public requestAutocompleteItems = (search: string): Observable<IFormElementOption[]> => {
    this.getItems(search);
    return this.itemsSubject$.asObservable();
  };

  public getItems(search: string): void {
    const formSubmitData: FormSubmitData = new FormSubmitData();
    formSubmitData.formInput = this.formManagerService.form.value;
    formSubmitData.submitElement = this.config.name;
    this.formManagerService.getFieldautocompletecallback(
        formSubmitData,
        this.config.name,
        search)
        .pipe(takeUntil(this.itemsSubject$))
        .subscribe(this.getSuccessHandler.bind(this));
  }

  getSuccessHandler(result: FormElementAutocompleteCallbackResult): void {
    if (!isNullOrUndefined(result) && !isNullOrUndefined(result.Options)) {
      this.itemsSubject$.next(result.Options);
    }
    this.cdRef.detectChanges();
  }

  public writeValue(value: FormElementOption[]): void {
    this.items = value;
  }

  public onAdd(): void {
    this.tagInputComponent.dropdown.hide();
    this.propagateChange(this.items);
  }

  public onRemove(): void {
    this.propagateChange(this.items);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // Por algún motivo que desonocemos, las animaciones internas
    // que pintan los elementos seleccionados no se están lanzando correctamente
    // y requieren un repintado asíncrono desde la zona de angular
    this.cdService.runInsideAngularAsync(() => {
      this.cdRefParent.detectChanges();
    }, this);
  }
}
