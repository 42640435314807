<div class="view-search-btns" style="vertical-align: baseline !important;">
  <div class="view-search-btns-select-fake u-radius__tr--medium u-radius__br--medium" [ngClass]="isOpenClass">
    <span tooltip="Editar las columnas del listado" placement="auto">
      <button type="button" class="c-btn-filtro" (click)="clickedInside($event)">
        <i class="m-fi m-fi--gear af af-gear"></i>
        <i class="m-fi m-fi--arrow-point-down u-ml--tiny u-font-size__xxxs"></i>
      </button>
    </span>

    <ul #cMenu class="view-search-btns-select-fake__list o-list-bare">
      <li class="view-search-btns-select-fake__list-item" (click)="showColumnConfiguration()">
        {{ "Columnas" | t }}
      </li>
    </ul>
  </div>
  <app-view-operations (onVboOperationCompleted)="dispatchEventOnTaskCompleted($event)"> </app-view-operations>
</div>

<app-view-localactions></app-view-localactions>

<ng-template #columnsModal>
  <app-view-configuration-selector (close)="modalRef.doClose()">
  </app-view-configuration-selector>
</ng-template>
