<div [formGroup]="group">

  <app-formelementinputlabel [config]="config" [group]="group"></app-formelementinputlabel>

  <app-acomplete *ngIf="config.isMultiselect !== true"
                 [formControlName]="config?.name"
                 [config]="config"
                 [group]="group"
                 #formInput>
  </app-acomplete>

  <app-autocomplete-multiple *ngIf="config.isMultiselect === true"
                             [formControlName]="config?.name"
                             [config]="config"
                             [group]="group"
                             #formInputMultiple>
  </app-autocomplete-multiple>

  <app-formelementinputdescription [config]="config" [group]="group"></app-formelementinputdescription>
  <app-formelementinput-validationmessages [config]="config" [group]="group"></app-formelementinput-validationmessages>
</div>
