import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormViewGeneratorDirective } from '../shared/form-view-generator.directive';
import {
  FormSubmitData,
  IViewsUserConfiguration,
  Payload,
  ViewFilterManagerExposedUserConfiguration,
  ViewUserConfiguration
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ViewsuserconfigchangedAction } from '../viewsuserconfigchanged.eventdata';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { ListComponent2Service } from '../list.service';
import { PrimeUtils } from '../../utils/prime.utils';
import { getInSafe, isNullOrUndefined } from '../../utils/typescript.utils';
import { ValuechangedEventdata } from '../../form/valuechanged.eventdata';
import { ActiveUserConfigurationClass } from '../activeUserConfigurationClass';
import { Formstatecontainer } from '../../form/formstatecontainer.class';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-exposed-filters',
  templateUrl: './exposed-filters.component.html',
  styleUrls: ['./exposed-filters.component.scss']
})
export class ExposedFiltersComponent extends DestroyableObjectTrait implements OnInit {

  private _updateViewConfigurationOnChangeSelection: boolean = true;

  private _initialized: boolean;

  @Output()
  public exposedFiltersFormState: Formstatecontainer;

  @ViewChild(FormViewGeneratorDirective) exposedFilterContainer: FormViewGeneratorDirective;

  @Input()
  get updateViewConfigurationOnChangeSelection(): boolean {
    return this._updateViewConfigurationOnChangeSelection;
  }

  set updateViewConfigurationOnChangeSelection(value: boolean) {
    this._updateViewConfigurationOnChangeSelection = value;
    if (this._initialized) {
      this.cdr.detectChanges();
    }
  }

  constructor(public listComponentService: ListComponent2Service,
              protected cdr: ChangeDetectorRef) {
    super();
    this.listComponentService.activeUserConfiguration
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((i) => !isNullOrUndefined(i.userConfiguration)),
        // Este TAKE 1 aquí es si más no extraño. Explicación: los filtros expuestos no están bien integrados
        // con el pipeline de ejecución de listados. El formstate se almacena en el userConfiguration, y el envío
        // del formulario se procesa en el AddToQuery, por lo que el cliente nunca puede recibir una versión
        // procesada del formulario (solo existe la versión original). Con este take(1) hacemos que el formulario
        // solo se cargue la primera vez y en posteriores actualizaciones del userConfiguration. Esto es un problema
        // porque limita lo que podemos hacer con el formulario, ya que NO tiene estado (no hay rebuilds, ni envíos, ni posiblidad
        // de meter errores de validación).
        take(1),
      ).subscribe((x: ActiveUserConfigurationClass) => {
      const fs: Formstatecontainer = this.getExposedFilterFormState(x.userConfiguration);
      this.exposedFiltersFormState = fs;
    });
  }

  changeFormValue(event: ValuechangedEventdata): void {
    if (this._updateViewConfigurationOnChangeSelection) {
      this.applyQuickFilters();
    }
  }

  ngOnInit(): void {
    const fs: Formstatecontainer = this.getExposedFilterFormState(this.listComponentService.getUserConfiguration());
    this.exposedFiltersFormState = fs;
    this._initialized = true;
  }

  /**
   * Refresh the view without modify searching and page
   */
  applyQuickFilters(): void {
    this.listComponentService.setPaginatorFirstPage();
    this.setExposedFilterSubmitData(this.listComponentService.getUserConfiguration(), this.exposedFilterContainer.getFormSubmitDataForSubmit())
  }

  private getExposedFilterFormState(userConfiguration: ViewUserConfiguration): Formstatecontainer {
    const userConf: ViewFilterManagerExposedUserConfiguration = this.GetViewFilterManagerExposedUserConfiguration(userConfiguration);
    if (userConf) {
      return this.parseFormState(userConf.SignedFormState);
    }
    return null;
  }

  private setExposedFilterSubmitData(userConfiguration: ViewUserConfiguration, formSubmitData: FormSubmitData): void {
    const userConf: ViewFilterManagerExposedUserConfiguration = this.GetViewFilterManagerExposedUserConfiguration(userConfiguration);
    if (userConf) {
      userConf.FormSubmitData = formSubmitData;
      const newUserConfiguration: ViewUserConfiguration =
        {...userConfiguration};
      this.listComponentService.setUserConfiguration(
        newUserConfiguration,
        true,
        ViewsuserconfigchangedAction.Refresh);
    }
  }

  private GetViewFilterManagerExposedUserConfiguration(userConfiguration: ViewUserConfiguration): ViewFilterManagerExposedUserConfiguration {
    const userConfigurations: { [key: string]: IViewsUserConfiguration } =
      getInSafe(userConfiguration, x => x.UserConfigurations, {});
    if (userConfigurations) {
      const userConf: ViewFilterManagerExposedUserConfiguration =
        PrimeUtils.ParseKeyItemToArray(userConfigurations).find((x: IViewsUserConfiguration) => {
          return x.$type.indexOf('ViewFilterManagerExposedUserConfiguration') !== -1
        }) as ViewFilterManagerExposedUserConfiguration;
      return userConf;
    }
    return null;
  }

  parseFormState(signedFormState: string): Formstatecontainer {
    const container: Formstatecontainer = new Formstatecontainer();
    container.formState = JSON.parse((jwt_decode(signedFormState) as Payload).data);
    container.signedFormState = signedFormState;
    return container;
  }

}
