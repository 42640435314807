<div [ngClass]="data?.config?.CssClasses">
  <ng-container *ngIf="data?.initialState?.messages">
    <p *ngFor="let message of (data?.initialState?.messages || [])" [innerHTML]="message"></p>
  </ng-container>
  <p>{{ data?.initialState?.message1 }}</p>
  <p>{{ data?.initialState?.message2 }}</p>
</div>
<div class="footer flex-row">
  <div class="dialog-confirm-buttons">
    <button
        id="dialog-confirm-no"
        *ngIf="!data?.initialState?.notVisibleNo"
        (click)="closeModal(false)"
        class="o-btn__danger dialog-confirm-no">
      {{(data?.initialState?.NoLabel) || ('No' | t) }}
    </button>
  </div>
  <div class="dialog-confirm-buttons">
    <button
        id="dialog-confirm-yes"
        *ngIf="!data?.initialState?.notVisibleYes"
        (click)="closeModal(true)"
        class="o-btn__primary dialog-confirm-yes">
      {{(data?.initialState?.YesLabel) || ('Sí' | t) }}
    </button>
  </div>
</div>

