import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AlertComponent } from './alert.component';
import { AlertConfig } from './alert.config';

@NgModule({
    imports: [CommonModule],
    declarations: [AlertComponent],
    exports: [AlertComponent]
})
export class AlertModule {
  public static forRoot(): ModuleWithProviders<AlertModule> {
    return {ngModule: AlertModule, providers: [AlertConfig]};
  }
}
