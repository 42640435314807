import { Component, ViewChild, HostBinding } from '@angular/core';

import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';

@Component({
  selector: 'app-radio-wrapper',
  templateUrl: './radio-wrapper.component.html'
})
export class RadioWrapperComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput', { static: true })
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  @HostBinding('class')
  get getClasses(): string {
    return super.getComponentClassesRendered();
  }
}
