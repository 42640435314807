<div class="c-single-item" style="display: flex;">
  <ng-container *ngFor="let operation of operations">
    <div *ngIf="operation.Access !== false">
      <button
          [ngClass]="getOperationClasses(operation)"
          [title]="operation.Title ? operation.Title : ''"
          [tooltip]="operation.Tooltip"
          (click)="onClick(operation)"><span>{{ operation.Title }}</span></button>
    </div>
  </ng-container>
</div>
