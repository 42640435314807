import { Directive, Injector, Input, ViewContainerRef } from '@angular/core';
import { List2Component } from '../../../list_v2/list.component';
import { CustomComponentFactoryService } from '../../../custom-component-factory/custom-component-factory.service';

@Directive({
  selector: '[appViewFormGenerator]'
})
export class ViewFormGeneratorDirective {
  /**
   * Component to render.
   */
  view: List2Component;

  private _disableInterserctionObserverApi: boolean = false;

  constructor(protected container: ViewContainerRef,
              protected customComponentFactory: CustomComponentFactoryService,
              protected injector: Injector) {
    this.view = this.customComponentFactory.createComponent('View', container, this.injector, {
      disableIntersectionObserverApi: this._disableInterserctionObserverApi
    }).instance as List2Component;
  }

  get disableInterserctionObserverApi(): boolean {
    return this._disableInterserctionObserverApi;
  }

  @Input()
  set disableInterserctionObserverApi(value: boolean) {
    this._disableInterserctionObserverApi = value;
    this.view.disableIntersectionObserverApi = value;
  }
}
