import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MaintenancePageComponent } from './components/maintenance-page.component';
import { OutOfServiceComponent } from './components/out-of-service.component';

const routes: Routes = [
  {path: 'maintenance', component: MaintenancePageComponent},
  {path: 'out-of-service', component: OutOfServiceComponent},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class MaintenanceRoutingModule { }
