import { Component, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';
@Component({
  selector: 'app-input-selectdynamic',
  templateUrl: './selectdynamic-wrapper.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SelectDynamicWrapperComponent extends FrontendFormElementWrapper {
  @ViewChild('formInput', { static: true })
  formElementInstanceValue: FrontendFormElementInput;
  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }
  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
