import { Component, ElementRef, OnInit } from '@angular/core';

import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import { NGXTrendSeriesChartComponent } from '../../ngx-trend-series-chart.class';
import {
  BubbleChart,
  BubbleChartDisplayOptions
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-basic-bubble-chart',
  templateUrl: './basic-bubble-chart.component.html',
  styleUrls: ['./basic-bubble-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class BasicBubbleChartComponent extends NGXTrendSeriesChartComponent<BubbleChart, BubbleChartDisplayOptions> implements OnInit {

  constructor(protected cdReference: ChangedetectorReference,
              protected elementRef: ElementRef) {
    super(cdReference);
  }

  GetViewValueOnInitializeChart(): [number, number] {
    return this.GetDefaultViewValueOnInitializeChart(this.elementRef);
  }

  GetViewValueOnResize(): [number, number] {
    return this.GetDefaultViewValueOnResize(this.elementRef);
  }
}
