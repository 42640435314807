/**
 * Evento de cambio en la configuración de usuario del listado
 */
import { ViewConfiguration, ViewUserConfiguration } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

export class ViewsinitializedEventdata {
  /**
   * La nueva configuración de usuario
   */
  userConfiguration: ViewUserConfiguration;

  configuration: ViewConfiguration;

  /**
   * Get an instance of ViewsuserconfigchangedAction
   * @param config
   * @param userConfig
   */
  constructor(config: ViewConfiguration, userConfig: ViewUserConfiguration) {
    this.userConfiguration = userConfig;
    this.configuration = config;
  }
}
