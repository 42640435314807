import { Component, ViewEncapsulation } from '@angular/core';
import { AppConfigurationService } from '../../app.configuration.service';
import { AbstractDecoupledModalComponent } from '../../shared/decoupled-modal/models/abstract-decoupled-modal.component';

/**
 * This componente has the methods for the feedback.
 */
@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FeedbackDialogComponent extends AbstractDecoupledModalComponent {
  title: string;
  params: { url: string, url_api: string };

  /**
   * FeedbackDialogComponent class constructor.
   */
  constructor(private configService: AppConfigurationService) {
    super();
    this.params = { url: window.location.href, url_api: this.configService.get('domain') }
  }


  /**
   * This method changes the view of modal.
   * @param e event clicked.
   */
  onSaveSucceed(e: any): void {
    this.closeModal();
  }
}
