import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DestroyableObjectTrait } from '../../../utils/destroyableobject.trait';
import { ViewsuserconfigchangedEventdata } from '../../../list_v2/viewsuserconfigchanged.eventdata';
import {
  ViewsPluginRequest,
  ViewsVboSelectedItem
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';

import { Observable } from 'rxjs';
import { ViewFormGeneratorDirective } from './view-form-generator.directive';
import { ListComponent2Service } from '../../../list_v2/list.service';
import { ViewsinitializedEventdata } from '../../../list_v2/events/viewsinitialized.eventdata';
import { takeUntil } from 'rxjs/operators';


/**
 * Versión del comonente de listados que se puede embedir en los formularios
 */

@Component({
  selector: 'app-views-form',
  templateUrl: './views-form.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ViewsFormComponent extends DestroyableObjectTrait {

  @ViewChild(ViewFormGeneratorDirective, {static: true}) component: ViewFormGeneratorDirective;

  @Output() viewIntialized: EventEmitter<ViewsinitializedEventdata> = new EventEmitter<ViewsinitializedEventdata>();

  @Output() vboSelectedItems: EventEmitter<{ [id: string]: ViewsVboSelectedItem }> = new EventEmitter<{
    [p: string]: ViewsVboSelectedItem
  }>();

  private _disableIntersectionObserverApi: boolean;

  get disableIntersectionObserverApi(): boolean {
    return this._disableIntersectionObserverApi;
  }

  @Input()
  set disableIntersectionObserverApi(value: boolean) {
    this._disableIntersectionObserverApi = value;
  }

  constructor() {
    super();
  }

  loadListFromPluginRequest(pluginRequest: ViewsPluginRequest, init: boolean, options: ViewsuserconfigchangedEventdata = null): void {
    this.component.view.viewIntialized.pipe(takeUntil(this.componentDestroyed$)).subscribe(x => this.viewIntialized.emit(x));
    this.component.view.vboSelectedItems.pipe(takeUntil(this.componentDestroyed$)).subscribe(x => this.vboSelectedItems.emit(x));
    this.component.view.currentPluginRequest = pluginRequest;
    this.component.view.listId = pluginRequest.Id;
    this.component.view.args = pluginRequest.Arguments as any[];

    console.debug(`app-views-form IOA disableIntersectionObserverApi ${this._disableIntersectionObserverApi}`);
    this.component.view.disableIntersectionObserverApi = this.disableIntersectionObserverApi;
    this.component.view.loadListFromPluginRequest(pluginRequest, init, options);
  }

  reloadList(): void {
    this.component.view.reloadList();
  }

  hideTitle(): void {
    this.component.view.hideTitle();
  }

  get listComponentService(): ListComponent2Service {
    return this.component.view.listComponentService;
  }

  materializeVboSelectionInMemory(): Observable<ViewsVboSelectedItem[]> {
    return this.component.view.materializeVboSelectionInMemory();
  }
}
