import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SessionstateService } from '../../core/sessionstate/sessionstate.service';
import { NavigationService } from '../../core/navigation/navigation.service';
import { AppContextService } from '../context/context.service';
import { JsonPathEvaluate } from '../utils/typescript.utils';

@Injectable()
export abstract class GenericPageComponent extends DestroyableObjectTrait {

  sessionData: {};

  /**
   * Parametros que vienen de ruta o resolver, centralizados para todos los componentes internos
   */
  params: object;

  protected constructor(
    protected navigationService: NavigationService,
    protected activatedRoute: ActivatedRoute,
    protected route: ActivatedRoute,
    protected sessionStateService: SessionstateService,
    protected contextService: AppContextService) {
    super();
  }

  protected abstract captureRequiredArguments(): string[];

  /**
   *
   * @param mapping
   * @protected
   */
  protected mapParamsToComponentParams(mapping: object): object {
    if (!mapping) {
      return {};
    }
    const params: object = {};
    for (const p of Object.keys(mapping)) {
      params[p] = this.params[mapping[p]];
    }
    return params;
  }

  /**
   * Actualiza la batería completa de parámetros que necesitan los componentes
   * @protected
   */
  protected updateParams(): void {
    const mapping: string[] = this.captureRequiredArguments();

    if (mapping && !this.sessionData && mapping.findIndex(x => x.startsWith('@session:')) !== -1) {
      this.getSessionData(() => this.updateParams());
      return;
    }

    this.params = {};

    if (mapping) {
      for (const paramValueOrMap of mapping) {
        let paramName: string = paramValueOrMap;
        let isOptional: boolean = false;
        if (paramValueOrMap.startsWith('?')) {
          paramName = paramValueOrMap.substring(1, paramValueOrMap.length);
          isOptional = true;
        }

        let valueFromSession: string;
        if (paramName.includes('@session:')) {
          valueFromSession = this.getSessionDataValue(paramName);
        }

        let valueFromContext: string;
        if (paramName.includes('@context:')) {
          valueFromContext = this.getContextParamValue(paramName);
        }

        const valueFromRoute: string = this.getNavArgumentValue(paramName);
        const valueFromResolver: string = this.getResolverParamValue(paramName);
        const valueFromQueryString: string = this.getQueryParamValue(paramName);
        // Originalmente los parámetros on literales, o si cuadra con algún argumento, el valor del argumento. Para evitar
        // esta ambigüedad, terminar el parámetro con un ? indica que debe obtener el valor de algún sitio, pero no del
        // literal de defecto
        const defaultValue: string = isOptional ? null : paramName;
        this.params[paramValueOrMap] = valueFromSession || valueFromContext || valueFromRoute || valueFromResolver || valueFromQueryString || defaultValue;
      }
    }
  }

  protected getSessionData(action: () => void): void {
    // Si no lo tenemos lo obtenemos, si se cambia, se actualiza
    this.sessionStateService.$sessionData
      .pipe(
        takeUntil(this.componentDestroyed$)
      ).subscribe(sessionData => {
      this.sessionData = sessionData;
      action();
    });
  }

  protected getSessionDataValue(paramName: string): string {
    const sessionParamName: string = paramName.replace('@session:', '');
    const value: string = JsonPathEvaluate(sessionParamName, this.sessionData)[0] as string;
    return value;
  }

  protected getNavArgumentValue(paramName: string, prefix: string = null): string {
    if (prefix) {
      paramName = paramName.replace(prefix, '');
    }
    return this.navigationService.findParmsForRouteByName(this.activatedRoute.snapshot, paramName)
  }

  protected getQueryParamValue(paramName: string, prefix: string = null): string {
    if (prefix) {
      paramName = paramName.replace(prefix, '');
    }
    return this.route.snapshot.queryParams[paramName];
  }

  protected getResolverParamValue(paramName: string, prefix: string = null): string {
    if (prefix) {
      paramName = paramName.replace(prefix, '');
    }
    return this.route.snapshot.data[paramName];
  }

  protected getContextParamValue(paramName: string): string {
    const contextParamName: string = paramName.replace('@context:', '');
    if (!this.contextService.GetContext().hasOwnProperty(contextParamName)) {
      return null;
    }
    return this.contextService.GetContext()[contextParamName];
  }
}
