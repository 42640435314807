import { BaseFuseChartTypeInterface } from './base-fuse-chart-type.class';
import { Directive, ElementRef, ViewChild } from '@angular/core';
import { BaseChartComponent } from '@swimlane/ngx-charts';
import { ChangedetectorReference } from '../../../../../../../core/changedetector/changedetectoreference';
import { Chart, IChartDisplayOptions } from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class NGXChartComponent<TChart extends Chart, TDisplayOptions extends IChartDisplayOptions> extends BaseFuseChartTypeInterface<TChart, TDisplayOptions> {

  @ViewChild('chart', {static: true}) chart: BaseChartComponent;

  protected constructor(protected cdReference: ChangedetectorReference) {
    super(cdReference);
  }

  GetDefaultViewValueOnInitializeChart(elementRef: ElementRef): [number, number] {
    if (this.view[0] === 0 && this.view[1] === 0) {
      return this.GetDefaultViewValueOnResize(elementRef);
    }
    return this.view;
  }

  GetDefaultViewValueOnResize(elementRef: ElementRef): [number, number] {
    const minHeight: number = 200;
    const maxHeight: number = 400;

    /*
        xs: 599px,
    sm: 960px,
    md: 1280px,
    lg: 1920px,
    xl: 5000px,
     */

    if (!elementRef.nativeElement.offsetParent) {
      return this.view;
    }

    let maxWidth: number = window.innerWidth;

    /*if (elementRef.nativeElement.offsetParent.clientWidth < 480) {
      maxWidth = window.innerWidth / 1.25;
    } else if (elementRef.nativeElement.offsetParent.clientWidth < 960) {
      maxWidth = window.innerWidth / 1.25;
    } else if (elementRef.nativeElement.offsetParent.clientWidth < 1280) {
      maxWidth = window.innerWidth / 1.25;
    } else if (elementRef.nativeElement.offsetParent.clientWidth < 1920) {
      maxWidth = window.innerWidth / 1.25;
    } else if (elementRef.nativeElement.offsetParent.clientWidth < 5000) {
      maxWidth = window.innerWidth / 1.25;
    }*/

    if (elementRef.nativeElement.offsetParent.clientWidth < 5000) {
      maxWidth = window.innerWidth / 1.25;
    }

    const width: number = elementRef.nativeElement.offsetParent.clientWidth < maxWidth ? elementRef.nativeElement.offsetParent.clientWidth : maxWidth;
    const height: number = elementRef.nativeElement.offsetParent.clientHeight < minHeight ? minHeight :
      elementRef.nativeElement.offsetParent.clientHeight > maxHeight ? maxHeight : elementRef.nativeElement.offsetParent.clientHeight;

    return [width, height];
  }
}
