/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class AchsHiraService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Gets questionnaires from study
  *
  * @param {string} categoryId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any[]} Person item or null
  */
  getCategorythematics(categoryId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any[]>>
  {
    const params: any = {'categoryId' : categoryId};
    return this.generalService.get(`quala-achs-hira/category-thematics`, params, clientOpts) as Observable<WebServiceResponseTyped<any[]>>;
  }

  /**
  *
  * Gets questionnaires from study
  *
  * @param {string} studyId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any[]} Person item or null
  */
  getStudythematics(studyId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any[]>>
  {
    const params: any = {'studyId' : studyId};
    return this.generalService.get(`quala-achs-hira/study-thematics`, params, clientOpts) as Observable<WebServiceResponseTyped<any[]>>;
  }
}
