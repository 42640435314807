import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RestTranslatorModule } from '../../../../core/translator/rest-translator.module';
import { UtilsModule } from '../../../utils/utils.module';
import { ExposedFiltersModule } from '../../exposed-filters/exposed-filters.module';
import { ViewOperationsModule } from '../../operations/view-operations.module';
import { ViewHeadersSharedModule } from '../view-headers-shared/view-headers-shared.module';
import { ViewSmallBarComponent } from './components/view-small-bar.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RestTranslatorModule,
        UtilsModule,
        TooltipModule.forRoot(),
        ExposedFiltersModule,
        ViewOperationsModule,
        ViewHeadersSharedModule
    ],
    declarations: [
        ViewSmallBarComponent
    ],
    exports: [
        ViewSmallBarComponent
    ]
})
export class ViewSmallBarModule {
}
