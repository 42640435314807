<ng-container [ngSwitch]="this.config.VideoType">
  <div *ngSwitchCase="FormElementVideoType.Url">
    <video
        [id]="this.uniqueComponentId('inputVideoPlayer-')"
        [autoplay]="this.config.Width"
        [controls]="this.config.Controls"
        [height]="this.config.Height"
        [width]="this.config.Width"
        [loop]="this.config.Loop"
        [muted]="this.config.Muted">
    </video>
  </div>
  <div *ngSwitchCase="FormElementVideoType.Youtube">
    <youtube-player
        [id]="this.uniqueComponentId('input-')"
        [videoId]="this.config.VideoId"
        suggestedQuality="highres"
        [height]="this.config.Height"
        [width]="this.config.Width">
    </youtube-player>
  </div>
  <div *ngSwitchCase="FormElementVideoType.Vimeo">
    <div
        [id]="this.uniqueComponentId('input-')"
        class="vimeo-player"></div>
  </div>
  <div *ngSwitchDefault>
    <p>Unsupported video type</p>
  </div>
</ng-container>
