import { FormManagerService } from './form-manager/form-manager.service';
import { isNullOrUndefined, isNullOrWhitespace } from '../utils/typescript.utils';
import { AbstractControl } from '@angular/forms';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { FieldConfig } from './interfaces/field-config.interface';
import { FormElementInput, FormState } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ClientCache } from '../../core/clientcache/ClientCacheClass';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';

/**
 * Este servicio se encarga de implementar una funcionalidad que permite
 * a los controles conservar el input de usuario en frontend, para que cuando
 * vuelvan al formulario, ya les salga llenado el componente
 *
 */
export class PreserveFrontendValueService extends DestroyableObjectTrait {

  /**
   * Constructor
   *
   * @param formManagerService
   */
  constructor(
    protected formManagerService: FormManagerService,
    protected clientCache: ClientCache) {
    super();
  }

  initialize(): void {

    // Nos colgamos del cambios de valor de todos los componentes involucrados
    for (const k of Object.keys(this.formManagerService.instanceComponents)) {

      const fc: AbstractControl = this.formManagerService.form.get(k);
      const fe: FieldConfig = this.formManagerService.getFieldConfigFromSelector(k);

      if (!fe || !fc) {
        continue;
      }

      if (isNullOrWhitespace((fe.FormElement as FormElementInput).FrontedUserValueRestoreKey)) {
        continue;
      }

      // Generamos una clave base, combinando el ID de formulario con los argumentos
      const formState: FormState = this.formManagerService.getFormState();
      let key: string = formState.FormId + ':' + JSON.stringify(formState.Arguments);
      key += (fe.FormElement as FormElementInput).FrontedUserValueRestoreKey;

      const storedValue: any = this.clientCache.getItem(key, null);
      if (storedValue !== null) {
        this.formManagerService.patchFormComponentValue(k, storedValue, true);
      }

      // El valueChanges() tambien se lanza cuando se deshabilita (.disable)
      // un control ya que angular asume que un control con disable=true
      // no tiene input de usuario
      fc
        .valueChanges
        .pipe(
          takeUntil(this.componentDestroyed$),
          startWith(this.formManagerService.getFormComponentValueRaw(k)),
          pairwise()
        )
        .subscribe(([prev, next]) => {
          // Como estamos escuchando al evento a nivel de componente
          // el valor todavia no esta propagado al formulario, y podemos
          // obtener el valor original.
          const previousValue: any = prev;
          const bothNull: boolean = isNullOrUndefined(next) && isNullOrUndefined(previousValue);
          // Guardamos el valor
          if (!bothNull) {
            this.clientCache.setItem(key, next, 'persistent');
          }
        });
    }
  }
}
