import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { UtilsModule } from '../../../../utils/utils.module';
import { ViewFilterContainerComponent } from './components/view-filter-container.component';
import { ViewFilterModalComponent } from './components/view-filter-modal.component';
import { ViewFilterTriggerComponent } from './components/view-filter-trigger.component';
import { SabentisUtilsModule } from '../../../../../sabentisutils/utils.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RestTranslatorModule } from '../../../../../core/translator/rest-translator.module';

@NgModule({
    imports: [
        CommonModule,
        UtilsModule,
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        FormsModule,
        TooltipModule.forRoot(),
        SabentisUtilsModule,
        RestTranslatorModule
    ],
  declarations: [
    ViewFilterContainerComponent,
    ViewFilterModalComponent,
    ViewFilterTriggerComponent
  ],
  exports: [
    ViewFilterContainerComponent,
    ViewFilterModalComponent,
    ViewFilterTriggerComponent,
  ]
})
export class ViewFiltersModule {
}
