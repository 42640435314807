import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { AuthService } from '../authentication/auth.service';
import { catchError, switchMap } from 'rxjs/operators';

/**
 * Este interceptor se encarga únicamente de "persistir" cabeceras que vienen de backend y luego meterlas
 * en todas las request que se envían a servidor.
 *
 * Es un reemplazo para las cookies (que funcionan casi igual) pero con muchas menos restricciones
 * a nivel de navegador.
 *
 * Esto está implementado temporalmente hasta que consigamos que esta información vaya en cookies,
 * pero los requisitos de navegador para que funcionen (FULL HTTPS, incluso en entornos de desarollo)
 * son demasiado estrictos para ser prácticos.
 *
 * IMPORTANTE: https://stackoverflow.com/questions/59808537/cookies-headers-are-present-but-cookies-are-not-stored-in-browser
 */
@Injectable()
export class HeaderCookieInterceptor implements HttpInterceptor {

  protected headerKeyRegex: RegExp = new RegExp('^header-store:(.*)$');

  /**
   * RefreshTokenInterceptor class constructor.
   * @param {AuthService} authService
   */
  constructor(private authService: AuthService) {
  }

  /** @inheritdoc */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Copiar el request con los headers
    const requestObservable: Observable<HttpEvent<any>> = of(null)
        .pipe(switchMap(() => {
          return next.handle(this.injectStateHeader(req));
        }));

    return requestObservable
        .pipe(
            switchMap((res) => {
              if (res && res['headers']) {
                this.processIncomingHeaders(res['headers'] as HttpHeaders);
              }
              return of(res);
            }),
            // Si hay error, también pueden haber comandos
            catchError((error: HttpErrorResponse, obs: Observable<any>) => {
              if (error && error.headers) {
                this.processIncomingHeaders(error.headers as HttpHeaders);
              }
              return throwError(error);
            }));
  }

  /**
   * Inject the JWT access token to the request if available.
   * @param req
   */
  injectStateHeader(req: HttpRequest<any>): HttpRequest<any> {
    const newHeaders: HttpHeaders = req.headers;
    const storedHeaders: { [id: string]: string } = this.getStoredHeaders();
    for (const key of Object.keys(storedHeaders)) {
      newHeaders.set(key, storedHeaders[key]);
    }
    const newReq: HttpRequest<any> = req.clone({headers: newHeaders});
    return newReq;
  }

  /**
   *
   * @param headers
   * @private
   */
  private processIncomingHeaders(headers: HttpHeaders): void {
    for (const header of headers.keys()) {
      // De momento hardcodeamoos solo para el header que queremos
      // tenga gestión automática
      if (header.toLowerCase() === 'X-Session'.toLowerCase()) {
        this.storeHeader(header, headers.get(header));
      }
    }
  }

  /**
   * Devuelve una lista de las cabeceras almacenadas
   * @constructor
   * @private
   */
  private getStoredHeaders(): { [id: string]: string } {
    const result: { [id: string]: string } = {};
    // eslint-disable-next-line guard-for-in
    for (const key in localStorage) {
      const matches: RegExpExecArray = this.headerKeyRegex.exec(key);
      if (matches?.length !== 1) {
        continue;
      }
      result[matches[1]] = localStorage.getItem(key);
    }
    return result;
  }

  /**
   *
   * @param key
   * @param value
   */
  public storeHeader(key: string, value: string): void {
    localStorage.setItem('header-store:' + key, value);
  }
}
