import { isNullOrUndefined } from '../../utils/typescript.utils';
import { FrontendFormElementInput } from './formelementinput.class';
import { FormElementInputCharacterCounterModeEnum } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FieldConfig } from '../interfaces/field-config.interface';

/**
 * Get the length validation style.
 */
export function lengthValidationClasses(inputValue: string, base: FrontendFormElementInput): Record<string, boolean> {
  const length: number = isNullOrUndefined(inputValue)
    ? 0 : (inputValue.toString() || '').length;

  return {
    'o-input__field-seek-error': (base.config.maxlength - length) < 0
  }
}

/**
 * Get the total remaining characteres
 */
export function getRemainingChars(inputValue: string, base: FrontendFormElementInput): string {
  const length: number = isNullOrUndefined(inputValue) ? 0 : (inputValue.toString() || '').length;

  if ((base.config.maxlength - length) < 0) {
    return (base.config.maxlength - length).toString();
  }

  return length + ' / ' + base.config.maxlength;
}


/**
 * Returns a boolean indicating if the user input must be "denied"
 * once the input length count equals the max length.
 */
export function handleMaxLengthInput(config: FieldConfig, value: any, e: KeyboardEvent = null): boolean {
  // If the max length number is not defined, then return.
  if (isNullOrUndefined(config.maxlength) || !config.maxlength) {
    return false;
  }

  // If is defined, then depending of the mode return a denied value boolean.
  if (config.characterCounterMode === FormElementInputCharacterCounterModeEnum.AllowCharacterExcedents) {
    return false;
  }

  return true;
}
