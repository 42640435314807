<ng-container *ngIf="!item.hidden">

  <!-- item.url -->
  <a [id]="item.id" class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function"
     [appSabentisRouterLink]="item.sabentisMenuItemCompiledFrontend"
     [target]="item.openInNewTab ? '_blank' : '_self'"
     [appSabentisRouterLinkActive]="['active', 'accent']"
     (mousedown)="$event.preventDefault()">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a [id]="item.id" class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && !item.function"
     [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.function -->
  <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function"
        (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

  <!-- item.url && item.function -->
  <a [id]="item.id" class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && item.function"
     (click)="item.function()"
     [appSabentisRouterLink]="item.sabentisMenuItemCompiledFrontend"
     [appSabentisRouterLinkActive]="['active', 'accent']"
     [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
     [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl && item.function -->
  <a [id]="item.id" class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && item.function"
     (click)="item.function()"
     [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
    <span class="nav-link-title" [translate]="item.translate">{{item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
          [ngClass]="item.badge?.cssClasses">
            {{item.badge.title}}
        </span>
  </ng-template>

</ng-container>
