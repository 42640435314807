import { FormElementDatePickerTimeZoneInfo, Precision } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import * as moment from 'moment-timezone';
import { MomentZone } from 'moment';

export class DateUtils {

  public static DateRegex: RegExp = new RegExp('(?:(\\d{4})([\\-\\/.])([0-3]?\\d)\\2([0-3]?\\d)|([0-3]?\\d)([\\-\\/.])([0-3]?\\d)\\6(\\d{4}))(?:\\s+([012]?\\d)([:hap])([0-5]\\d))?');
  public static DateTimeRegex: RegExp = new RegExp('[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])(T)(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]')

  /**
   * Get the time now
   * @returns {number}
   * @constructor
   */
  public static GetTimeNow(): number {
    return Math.floor(Date.now() / 1000);
  }

  /**
   * From .NET TimeSpan, returns a Date
   * @param value
   * @constructor
   */
  public static ConvertTimeSpanToDate(value: string): Date {
    return moment(value, 'HH:mm:ss').toDate();
  }

  /**
   * From a .NET DateTimeOffset, returns a date applying a offset
   * @param value
   * @param offset
   * @constructor
   */
  public static ConvertDateTimeOffsetToFrontendDate(value: Date | string): Date {
    return DateUtils.ConvertToFrontendDate('YYYY-MM-DD HH:mm:ss', value);
  }

  /**
   * From a .NET DateTimeOffset, returns a date applying a offset
   * @param value
   * @param offset
   * @constructor
   */
  public static ConvertToFrontendDate(format: string, value: Date | string, offset: string = null): Date {
    return moment(value, format).toDate();
  }

  public static ParseFromUnixTimeStamp(value: number): Date {
    const date: Date = moment.unix(value).toDate();
    return date;
  }
}

/**
 * Dada una fecha de entrada, setea en 0's aquellos elementos que quedan fuera de la precisión
 * indicada.
 * PE: 12:00:36 con precisión de HORAS pasa a ser 12:00:00
 * @param date
 * @param precision
 * @constructor
 */
export function DateLimitGranularity(date: Date, precision: Precision): Date {
  const granularity: moment.unitOfTime.StartOf = PrecisionToGranularity(precision);
  const momentDate: moment.Moment = moment(date, 'X');
  return momentDate.clone().startOf(granularity).toDate();
}

/**
 * Dada una fecha UNIX devuelve un objeto date de javascript con la fecha y hora visible
 * adaptada a la zona horaria definida por el parámetro offset. Si timestamp es nulo o vacío
 * devuelve la fecha actual (DatTime.Now) también adecuada al offset.
 *
 * @param timestamp
 * @param timezoneInfo
 * @constructor
 */
export function UnixTimestampToUserDateFromOffset(timestamp: number, timezoneInfo: FormElementDatePickerTimeZoneInfo): Date {

  if (!timestamp) {
    return null;
  }

  if (!timezoneInfo) {
    // A esto hay que corregirle la hora del navegador...
    const momentDateNoTimezone: moment.Moment = moment(timestamp, 'X');
    // A esto hay que corregirle la hora del navegador... porque queremos la hora "visible" para el usuario
    momentDateNoTimezone.add(-momentDateNoTimezone.utcOffset(), 'minutes');
    return momentDateNoTimezone.toDate();
  }

  const zone: MomentZone = moment.tz.zone(timezoneInfo.IanaName);
  if (!zone) {
    throw new Error('Unknown IANA timezone name: ' + timezoneInfo.IanaName);
  }

  const momentDate: moment.Moment = moment(timestamp, 'X').tz(timezoneInfo.IanaName);
  return moment(momentDate.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').toDate();
}

/**
 *
 * @param date
 * @param component
 * @constructor
 * @protected
 */
export function UserDateToUnixTimestampFromOffset(date: Date, timezoneInfo: FormElementDatePickerTimeZoneInfo): number {

  if (!date) {
    return null;
  }

  // Si no hay zona horaria, no convertimos nada
  if (!timezoneInfo) {
    const momentDateNoTimezone: moment.Moment = moment(date);
    // A esto hay que corregirle la hora del navegador... porque queremos la hora "visible" para el usuario
    momentDateNoTimezone.add(momentDateNoTimezone.utcOffset(), 'minutes');
    return momentDateNoTimezone.unix();
  }

  // Validar que entendemos la info de la zona que viene de backend
  const zone: MomentZone = moment.tz.zone(timezoneInfo.IanaName);
  if (!zone) {
    throw new Error('Unknown IANA timezone name: ' + timezoneInfo.IanaName);
  }

  const momentData: moment.Moment = moment(date);
  const momentDateString: string = momentData.format('YYYY-MM-DD HH:mm:ss');
  const momentDate: moment.Moment = moment.tz(momentDateString, 'YYYY-MM-DD HH:mm:ss', timezoneInfo.IanaName);
  return momentDate.unix();
}

/**
 * Convertir la precisión de fechas de back, en el granularity de momentJs
 *
 * @param precision
 * @constructor
 */
export function PrecisionToGranularity(precision: Precision): moment.unitOfTime.StartOf {
  let granularity: moment.unitOfTime.StartOf = null;
  switch (precision) {
    case Precision.Years:
      granularity = 'year';
      break;
    case Precision.Months:
      granularity = 'month';
      break;
    case Precision.Days:
      granularity = 'day';
      break;
    case Precision.Hours:
      granularity = 'hours';
      break;
    case Precision.Minutes:
      granularity = 'minutes';
      break;
    case Precision.Seconds:
      granularity = 'seconds';
      break;
    default:
      throw new Error();
  }
  return granularity;
}

