<div [formGroup]="group">

<app-maps-inner-input [formControlName]="config.name"
                   [config]="config"
                   [group]="group">
  </app-maps-inner-input>

  <app-formelementinputdescription
    [config]="config"
    [group]="group">
  </app-formelementinputdescription>

</div>
