<ng-container
    *ngFor="let rowResult of data; index as i; trackBy: trackElement">
  <tr class="view-table-body-tr"
      [class.even-row]="i%2===0"
      [class.uneven-row]="i%2!==0"
      [ngClass]="resolveRowClasses(rowResult)">
    <td class="view-table-body-tr-td" *ngFor="let fieldInclude of headerFields"
        [ngClass]="resolveTableCellClasses(fieldInclude, rowResult)"
        [attr.data-column-title]="getViewModeTableColumn(fieldInclude)?.Header">

      <app-view-grid-cell
          *ngIf="checkFieldTypeInTypes(rowResult, fieldInclude, [ViewResultCellType.Simple, ViewResultCellType.FileLink, ViewResultCellType.NavigationNodeLink, ViewResultCellType.Html])"
          [viewModeUserColumn]="fieldInclude" [rowData]="rowResult">
      </app-view-grid-cell>

      <app-singleitemoperations2 [row]="rowResult" [viewModeUserColumn]="fieldInclude"
                                 *ngIf="checkFieldTypeInTypes(rowResult, fieldInclude,[ViewResultCellType.SingleItemOperation])">
      </app-singleitemoperations2>

      <app-view-grid-cell-bulk-operations
          [fieldInclude]="fieldInclude"
          [rowData]="rowResult"
          *ngIf="checkFieldTypeInTypes(rowResult, fieldInclude, [ViewResultCellType.BulkOperation])">
      </app-view-grid-cell-bulk-operations>
    </td>
  </tr>
</ng-container>
