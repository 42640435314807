import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { DecoupledModalBridgeService } from '../../shared/decoupled-modal/decoupled-modal-bridge.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { DtoFrontendModal } from '../models/ETG_SABENTISpro_Application_Core_models';

/**
 * Use to load file from local: e.g configuration file
 */
@Injectable()
export class CommunicationLocalService {
  constructor(
      private http: HttpClient,
      private spinnerService: SpinnerService,
      private dmbs: DecoupledModalBridgeService
  ) {
  }

  /**
   * @param file: local path with the file to load.
   * @param ignoreError: criteria that - if matched - ignores the error and emits null
   */
  public get(file: string, ignoreError: (e: any) => boolean = null): Observable<Object> {
    let spinnerId: Guid;
    spinnerId = this.spinnerService.showSpinner();

    console.log(file);

    return this.http
        .get(file)
        .pipe(
            catchError(e => {
              if (ignoreError && ignoreError(e)) {
                return of(null);
              }

              console.log(e.error);
              let message: string = '';

              if (e && e.error && e.error.message) {
                message = e.error.message;
              }
              if (e && e.error && e.error.error && e.error.error.message) {
                message = e.error.error.message;
              }

              this.dmbs.showError(`Error: ${message}`, {} as DtoFrontendModal);

              return e.error;
            }),
            finalize(() => {
              this.spinnerService.removeSpinner(spinnerId);
            }));
  }
}
