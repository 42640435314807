import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';

import { NavigationService } from '../../../core/navigation/navigation.service';
import { MenuService } from './menu.service';
import { ChangedetectorService } from '../../../core/changedetector/changedetector.service';
import { ChangedetectorReference } from '../../../core/changedetector/changedetectoreference';
import { MenuItemCompiledFrontend } from '../../../core/navigation/models/MenuItemCompiledFrontend.class';
import { NavigationRequest } from '../../../core/navigation/models/NavigationRequest.class';
import { filter, takeUntil } from 'rxjs/operators';
import { isNullOrUndefined, makeSafeForCSS, UtilsTypescript } from '../../utils/typescript.utils';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { MenuType } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  providers: [
    ChangedetectorReference
  ]
})
export class SideMenuComponent extends DestroyableObjectTrait implements OnInit {
  // inputs
  closed = false;

  // public properties
  tree: MenuItemCompiledFrontend;

  openedChildren: number;

  // template children
  @ViewChild('nav', {static: true}) nav;

  /**
   * Referencia de todos los tooltip que se muestran.
   */
  @ViewChildren(TooltipDirective) tooltips: QueryList<TooltipDirective>;

  /**
   * creates a new instance of SideMenuComponent
   * @param {NavigationService} navigationService. service used to get navigation changes
   * @param menuService
   * @param _eref
   * @param r
   */
  constructor(private navigationService: NavigationService,
              private menuService: MenuService,
              private _eref: ElementRef,
              private r: Router,
              private cdRef: ChangeDetectorRef,
              private cdReference: ChangedetectorReference,
              private cdService: ChangedetectorService) {
    super();
  }

  /**
   * subscribe to navigations changes on init
   */
  ngOnInit(): void {

    // Carga inicial
    this.navigationService
      .resolveTreeFromNavigationRequest(new NavigationRequest())
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((request: NavigationRequest) => {
        this.tree = request.responseProcessedTree.find((i) => i.menuName === 'left-menu');
        this.cdReference.changeDetector.detectChanges();
      });

    // Cambios
    this.navigationService
      .navigationRequestResolved
      .pipe(
        filter((i) => {
          return UtilsTypescript.isNullOrWhitespace(i.requestController)
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((request: NavigationRequest) => {
        this.tree = request.responseProcessedTree.find((i) => i.menuName === 'left-menu');
        this.cdReference.changeDetector.detectChanges();
      });

    this.menuService.toggleChanges()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (next) => {
          if (this.closed !== next.closedState) {
            this.closed = next.closedState;
            this.cdService.runApplicationChangeDetection();
          }
        }
      );
  }

  /**
   * Get the menu items to render
   */
  get menuItems(): MenuItemCompiledFrontend[] {
    if (!this.tree) {
      return [];
    }
    return this.tree.children.filter((i) => i.Hidden !== true);
  }

  /**
   * change the opened children iterator
   * @param {number} i
   */
  changeOpenedChildren(i: number, item: MenuItemCompiledFrontend): void {

    if (item.accessBlocked()) {
      return;
    }

    if (item.frontendPath && item.ForceOnClickNavigation) {
      this.navigationService.navigateToMenuItem(item)
        .pipe(
          takeUntil(this.componentDestroyed$),
          filter((j) => j === true)
        )
        .subscribe(() => {
          this.openedChildren = (this.openedChildren === i) ? null : i;
          this.cdReference.detectChangesIfOutsideAngular();
        });
      return;
    } else {
      this.openedChildren = (this.openedChildren === i) ? null : i;
      this.cdReference.detectChangesIfOutsideAngular();
    }
  }

  /**
   * get the fontawesome icon
   * @param {string} icon
   * @returns {string[]}
   */
  getIcon(icon: string[]): string[] {
    return ['i-' + icon[0]];
  }

  /**
   * returns if an array of children is valid. If has no children or has no Normal type children, it's an
   * invalid array.
   * @param {FrontendMenuItem[]} children: children array to check
   * @returns {boolean} if children array contains valid children, returns true
   */
  hasValidChildren(item: MenuItemCompiledFrontend): boolean {
    if (isNullOrUndefined(item.children)) {
      return false;
    }
    return item.children.filter((x) => {
      return x.menuType === MenuType.Normal || x.menuType === MenuType.WizardContainer;
    }).length !== 0;
  }

  /**
   * ACHSPRIME-2168 Revisado funciona OK
   *
   * No podemos usar la directiva appClieckOutside porque aquíe se llama a isElementRegistered.
   *
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickedOutside(event: any): void {
    if (!this._eref.nativeElement.contains(event.target)) {
      // Usamos esto para evitar que las modales de mensaje de acceso denegado nos cierren el menú
      if (UtilsTypescript.hasClass(event.target, 'modal')) {
        return;
      }
      if (!this.menuService.isElementRegistered(event.target, 'menu-left')) {
        this.menuService.toggleMenu({menuName: 'menu-left', closedState: true});
      }
    }
  }

  /**
   * This method open the menu if it's not opened when an item is clicked.
   */
  openItem(): void {
    // Escondemos tooltips que se están mostrando.
    this.hideTooltips();

    this.menuService.toggleMenu({menuName: 'menu-left', closedState: false});
  }

  /**
   *
   * @param name
   */
  makeSafeForCssWithPrefix(name: string): string {
    return makeSafeForCSS('nav_' + name);
  }

  /**
   * Muestra un tooltip.
   *
   * Disparado en mouseenter a un icono.
   */
  showTooltip(closed: boolean, t: TooltipDirective): void {
    if (closed) {
      t.show();
    }
  }

  /**
   * Oculta todos los tooltips.
   */
  hideTooltips(): void {
    this.tooltips.map(t => t.hide());
  }

  /**
   * Sets the open class when item is selected or route is the same that
   * @param i
   * @returns {string}
   */
  getClass(i: any): string {
    if (this.isActive(i)) {
      return 'open'
    }
  }

  /**
   *
   * @param i
   * @returns {boolean}
   */
  isActive(i: number): boolean {
    return ((i === this.openedChildren && !this.closed) ||
      (this.activeTreeHasChildren() &&
        this.r.url.includes(this.tree.children[i].path) &&
        !this.isPathHome(this.tree.children[i].path)) ||
      this.removeTrailingSlashes(this.tree.children[i].path) === this.removeTrailingSlashes(this.r.url));
  }

  /**
   * Returns a boolean indicating if current tree has children.
   */
  activeTreeHasChildren(): boolean {
    return !isNullOrUndefined(this.tree) && !isNullOrUndefined(this.tree.children);
  }

  /**
   * Returns a boolean indicating if a path is the home path.
   * @param {string} path
   */
  isPathHome(path: string): boolean {
    return this.removeTrailingSlashes(path) === 'home';
  }

  /**
   * Returns a string with it's trailing slashes removed or false if value is invalid.
   * @param {string} url
   */
  removeTrailingSlashes(url: string): string {
    if (isNullOrUndefined(url)) {
      return url;
    }

    return url.split('/').filter(x => x).join('/');
  }


}
