import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NavigationService } from '../../../core/navigation/navigation.service';
import { FormManagerComponent } from '../../../shared/form/form-manager/form-manager.component';
import { DestroyableObjectTrait } from '../../../shared/utils/destroyableobject.trait';

/**
 * Component Configuration Details
 */
@Component({
  selector: 'app-configuration-details',
  templateUrl: './configuration-details.component.html'
})
export class ConfigurationDetailsComponent extends DestroyableObjectTrait implements OnInit {

  /**
   * Variable params.
   */
  params: { machineName: string, configurationType: string, isNew: boolean };

  /**
   * Variable type form
   */
  @ViewChild('form', {static: true}) form: FormManagerComponent;

  /**
   * Constructor, inicialize the varible params.
   * @param navigationService NavigationService
   * @param router Router
   * @param activatedRoute ActivatedRoute
   */
  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
    this.params = {
      machineName: '',
      configurationType: '',
      isNew: false
    };
  }

  /**
   * Method ngOnInit get data of the url
   * for send to form.
   */
  ngOnInit(): void {
    this.params['machineName'] = this.navigationService.findParmsForRouteByName(this.activatedRoute.snapshot, 'machinename');
    this.params['configurationType'] = this.navigationService.findParmsForRouteByName(this.activatedRoute.snapshot, 'configurationtype');
    this.params['isNew'] = this.navigationService.lastResolvedNavigationRequestSync.value.requestController === 'parametrics.configuration.add';
  }

  /**
   * Method cancel redirect to list.
   * of configurations.
   * @param e Event
   */
  cancel(e: any): void {
    this.navigationService.backNew(0);
  }

  /**
   * Method saveSucceed reload the page.
   * of configurations before save data´s form.
   * @param event Event
   */
  saveSucceed(event: { id: string, message: Array<string>, responseData: any }): void {
    if (this.params['isNew']) {
      const urlBase: string = this.router.url.replace('add', '');
      this.router.navigateByUrl(urlBase + event.responseData.Result.machine_name + '/' + event.responseData.Result.configuration_type + '/details');
    } else {
      this.form.resetForm();
    }
  }
}
