<div class="modal-header c-modal__header" *ngIf="!config?.HideHeader">
  <h4 class="modal-title c-modal__title" >{{ config?.Title ?? '' }}</h4>
  <button
      *ngIf="!config?.HideClose"
      type="button"
      class="close"
      (click)="closeModal(false)">
    &times;
  </button>
</div>
<div class="modal-body c-modal__body">
  <ng-container #bodyContainer></ng-container>
</div>
<div class="modal-footer">
  <ng-container #footerContainer></ng-container>
</div>
