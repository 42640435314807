import { Component } from '@angular/core';

import {
  IViewResutRowGroupHeaderAction,
  ViewGroupBy,
  ViewResultRow,
  ViewResultRowGroup
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../list.service';
import { IGridRowMode } from '../shared/grid-row-mode-interface.component';
import { GridRowDefaultComponent } from './grid-row-default.component';
import { ChangedetectorReference } from '../../../../../core/changedetector/changedetectoreference';
import { isArray } from 'app/shared/utils/typescript.utils';
import { CommandService } from '../../../../../core/commands/command.service';
import { asIterableObject } from '../../../../utils/typescript.utils';
import { ClientCache } from '../../../../../core/clientcache/ClientCacheClass';
import { Md5 } from 'ts-md5';

/**
 * Components that extends `IGridRowMode` works as row iterators to
 * display the table data in a customized way for a grid.
 *
 * Usually `IGridRowMode` components selector use a tbody[app-attribute] to
 * contain the rows, making the component friendlier to CSS and simpler
 * to handle.
 */
@Component({
  // Include this component as and attribute selector component to simplofy the
  // css treatment.
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tbody[app-grid-row-group-expand]',
  templateUrl: './grid-row-group-expand.component.html',
  providers: [ChangedetectorReference]
})
export class GridRowGroupExpandComponent
  extends GridRowDefaultComponent
  implements IGridRowMode {

  private collapsedState: { [id: string]: boolean } = {};

  asIterableObject3 = asIterableObject;

  /**
   * GridRowDefaultComponent class constructor.
   * @param {ListComponent2Service} listComponentService Component's list service.
   */
  constructor(protected listComponentService: ListComponent2Service,
              protected cdReference: ChangedetectorReference,
              protected commandService: CommandService,
              protected clientCacheService: ClientCache) {
    super(listComponentService);
  }

  private getGroupCollapsedStateKeyStore(id: string): string {
    const requestHash: string = Md5.hashStr(JSON.stringify(this.listComponentService.currentPluginRequest)).toString();
    return 'views-collapsed-group-item::' + requestHash + '::' + id;
  }

  /**
   *
   * @param id
   */
  groupCollapsed(id: string): boolean {

    if (this.collapsedState.hasOwnProperty(id)) {
      return this.collapsedState[id];
    }

    const collapsedStateStore: boolean = this.clientCacheService.getItem(this.getGroupCollapsedStateKeyStore(id), null);

    if (collapsedStateStore !== null) {
      this.collapsedState[id] = collapsedStateStore;
    } else {
      this.collapsedState[id] = this.groupBy.GroupByHeaderCollapsedDefault;
    }

    return this.collapsedState[id];
  }

  toogleCollapseGroup(group: ViewResultRowGroup): void {
    this.collapsedState[group.Id] = !this.collapsedState[group.Id];
    this.clientCacheService.setItem(this.getGroupCollapsedStateKeyStore(group.Id), this.collapsedState[group.Id], 'browser');
    this.cdReference.changeDetector.detectChanges();
  }

  /**
   *
   * @param operation
   */
  operationOnClick(operation: IViewResutRowGroupHeaderAction): void {
    if (operation.Disabled) {
      return;
    }

    if (operation.OnClickCommands) {
      this.commandService.executeCommandChain(Object.values(operation.OnClickCommands));
    }
  }

  getOperationClasses(operation: IViewResutRowGroupHeaderAction, index: number): string[] {
    let classes: string[] = ['o-btn__achsrow--table o-btn__primary'];

    if (isArray(operation.CssClasses)) {
      classes = [...classes, ...operation.CssClasses];
    }

    if (operation.Disabled) {
      classes.push('--disabled');
    }

    classes.push('index-' + index);
    classes.push('id-' + operation.Id)
    return classes;
  }

  // Se supone que una abstr
  get groupBy(): ViewGroupBy {
    return this.listComponentService.getConfiguration().GroupBy as ViewGroupBy;
  }

  /**
   * Group data getter.
   */
  get groupData(): ViewResultRowGroup[] {
    const groups: { [identifier: string]: ViewResultRowGroup }
      = (this.listComponentService.data.Groups as any) || {};

    return Object.keys(groups)
      .filter((k) => k !== '$type')
      .map((k: string) => groups[k]);
  }

  /**
   * Track element unique keys for better performance
   *
   * @param {number} index
   * @param {ViewResultRow} element
   * @returns {number}
   */
  trackGroup(index: number, element: ViewResultRowGroup): string {
    return element ? element.Id : null;
  }

  /**
   * Get results for group.
   *
   * @param {ViewResultRowGroup} group Group to display.
   */
  getGroupResults(group: ViewResultRowGroup): ViewResultRow[] {
    return this.data.filter(d => d.GroupId === group.Id);
  }

  /**
   * Returns the length for full colspan.
   */
  fullColspanLength(): number {
    return this.isNullOrUndefined(this.headerFields) ? 0 : this.headerFields.length;
  }
}
