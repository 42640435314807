<div class="chip-layout t-vbo-review" *ngIf="selectedItems() > 0 && !isVboAllSelected()  && showVboReview()">
  <div class="chip-title t-summary">{{ "Seleccionadas @count filas." | t:{'@count': selectedItems()} }}</div>
  <div class="chip-list-item"  *ngFor="let item of getSelectedItems()">
    {{item.DisplayText}}
    <i class="chip-close t-remove" (click)="removeSelectedItem(item)"><i class="fa fa-times"></i></i>
  </div>
</div>
<div class="chip-layout t-vbo-review" *ngIf="isVboAllSelected()">
  <ng-template #loading>
    <div
        class="chip-title t-summary">{{ "Seleccionadas todas las filas de todas las páginas del listado." | t }}
      <i class="chip-close t-close" (click)="vboToggleAll()"><i class="fa fa-times"></i></i>
    </div>
  </ng-template>
  <div
      *ngIf="!isNaN(totalItems); else loading"
      class="chip-title t-summary">{{ "Seleccionadas @count filas en todas las páginas del listado." | t:{'@count': totalItems} }}
    <i class="chip-close t-close" (click)="vboToggleAll()"><i class="fa fa-times"></i></i>
  </div>
</div>

