import { removeElement } from './shared/utils/typescript.utils';
import { Injectable } from '@angular/core';
import { DomReferenceProviderService } from './shared/utils/providers/dom-reference-provider.service';

/**
 * El INDEX.HTML tiene un spinner temporal para mientras se carga angular, lo usamos
 * para:
 * 1. Esperar mientras se cargan los ficheros JS de angular
 * 2. Esperar y mostrar progreso mientras se carga el aplicativo
 */
@Injectable({providedIn: 'root'})
export class AppBootstrapSpinnerService {

  /**
   * Get an instance of AppBootstrapSpinnerService
   */
  constructor(private domReference: DomReferenceProviderService) {
  }

  /**
   * Si el spinner está habilitado
   */
  available(): boolean {
    const document: Document = this.domReference.documentRef;
    const spinnerElement: Element = document.querySelector('body .spinner-container');
    if (spinnerElement) {
      return true;
    }
    return false;
  }

  /**
   * Quitar el spinner
   */
  remove(): void {
    const document: Document = this.domReference.documentRef;
    const spinnerElement: Element = document.querySelector('body .spinner-container');
    if (spinnerElement) {
      removeElement(spinnerElement);
    }
  }

  /**
   * Subtexto de ayuda para el spinner de carga
   *
   * @param text
   */
  setText(text: string): void {
    const document: Document = this.domReference.documentRef;
    const spinnerElement: HTMLElement = document.querySelector('body .spinner-container-info');
    if (spinnerElement) {
      spinnerElement.innerText = text;
    }
  }
}
