<div class="view-search-form-search-and-filter"
     *ngIf="this.configuration.QuickSearch !== null && this.configuration.QuickSearch !== undefined">
  <div class="c-form-search-and-filter__side  view-search-form-search-and-filter__side--big">
    <div class="o-input">
      <input type="search"
             placeholder="Buscar"
             class="t-quicksearch t-quicksearch-input"
             (keyup)="searchChange($event);"
             (keypress)="preventEventPropagation($event);"
             [(ngModel)]="searchString">
      <button type="button" class="c-form-field__button" (click)="searchChange($event);">
        <i class="m-fi  m-fi--search af af-magnifier"></i>
      </button>
    </div>
  </div>

  <div class="c-form-search-and-filter__side" (appClickOutside)="clickOutsideHandler($event)">
    <div class="dropdown" [ngClass]="{'open': showFilters}">
      <span
        tooltip="Haz click sobre el desplegable, para activar o desactivar los criterios de búsqueda. El buscador sólo te permite filtrar por los criterios seleccionados. La cantidad máxima de criterios es de {{this.configuration.QuickSearch.MaxConcurrentFields}}."
        placement="top">
        <button type="button" class="c-btn-filtro"
                [ngClass]="{'is-selected': !showFilters}"
                (click)="toggleDropdown($event)">
        <i class="m-fi m-fi--arrow-point-down"></i>
      </button>
      </span>
      <ul class="dropdown-menu">
        <li>
          <div class="fix-dropdown-checkbox">
            <div *ngFor="let field of fields" class="c-radio-checkbox">
              <input
                type="checkbox"
                [id]="field.FieldId"
                class="o-checkradio"
                [checked]="isChecked(field.FieldId)"
                [class.disabled]="isDisabled(field.FieldId)"
                (change)="inputChanged($event, field.FieldId)">
              <label [for]="field.FieldId">{{ field.Name }}</label>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
