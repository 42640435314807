import { NgModule } from '@angular/core';
import { FuseChartsModule } from '../../../../layouts/prime-fuse/layout/components/fuse-charts/fuse-charts.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChartEmbeddedComponent } from './chart-embedded-wrapper/chart-embedded.component';
import { InnerChartEmbeddedComponent } from './inner-chart-embedded/inner-chart-embedded.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FuseChartsModule
    ],
    declarations: [
        ChartEmbeddedComponent,
        InnerChartEmbeddedComponent
    ],
    exports: [
        ChartEmbeddedComponent
    ]
})
export class ChartEmbeddedModule {}
