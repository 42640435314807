import { MenuItemCompiledFrontend } from './models/MenuItemCompiledFrontend.class';

/**
 * Parámetros del evento de navegación
 */
export class NavigationActionEventData {

  /**
   * Item de navegación que el usuario ha pinchado
   */
  public menuItem: MenuItemCompiledFrontend;

  /**
   * Poner a verdadero si quiero parar la navegación automática
   */
  public stopNavigation: boolean;
}
