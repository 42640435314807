import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileWrapperComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput', {static: true})
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  @HostBinding('class')
  get getClasses(): string {
    return super.getComponentClassesRendered();
  }
}
