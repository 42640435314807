import { Component, ElementRef, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../../core/changedetector/changedetectoreference';
import { NGXTrendSeriesChartComponent } from '../ngx-trend-series-chart.class';
import {
  AreaChart,
  AreaChartDisplayOptions
} from '../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class AreaChartComponent extends NGXTrendSeriesChartComponent<AreaChart, AreaChartDisplayOptions> implements OnInit {

  constructor(protected cdReference: ChangedetectorReference,
              protected elementRef: ElementRef) {
    super(cdReference);
  }

  GetViewValueOnInitializeChart(): [number, number] {
    return this.GetDefaultViewValueOnInitializeChart(this.elementRef);
  }

  GetViewValueOnResize(): [number, number] {
    return this.GetDefaultViewValueOnResize(this.elementRef);
  }
}
