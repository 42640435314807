import { Directive, HostListener, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../core/changedetector/changedetectoreference';
import { DestroyableObjectTrait } from '../shared/utils/destroyableobject.trait';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

/**
 * Esta directiva se usa para enlazar un scroll ejecutado sobre un elemento concreto del DOM
 * con el SCROLL de la ventana.
 *
 * Se utiliza por ejemplo para que en FUSE, donde el window:scroll nunca se llama (porque en FUSE el scroll
 * se hace dentro de un div ad-hoc) puedan haber componentes que escuchen el window:scroll.
 */
@Directive({
  selector: '[appScrollPropagateToWindowDirective]',
  providers: [
    ChangedetectorReference
  ]
})
export class ScrollPropagateToWindowDirective extends DestroyableObjectTrait implements OnInit {

  propagateChangeDebouncer: Subject<any> = new Subject<any>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.propagateChangeDebouncer
      .pipe(
        takeUntil(this.componentDestroyed$),
        throttleTime(300)
      )
      .subscribe(((value: any): void => {
        const e: any = document.createEvent('Event');
        e.initEvent('scroll', true, true);
        window.dispatchEvent(e);
      }).bind(this));
  }

  @HostListener('scroll', ['$event'])
  scrolled(event: Event): void {
    this.propagateChangeDebouncer.next(event);
  }
}
