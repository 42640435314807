<div [formGroup]="group">

  <app-formelementinputlabel [config]="config" [group]="group"></app-formelementinputlabel>

  <app-autocompletenew
      [formControlName]="config?.name"
      [config]="config"
      [group]="group"
      #formInput>
  </app-autocompletenew>

  <app-formelementinputdescription [config]="config" [group]="group"></app-formelementinputdescription>
  <app-formelementinput-validationmessages [config]="config" [group]="group"></app-formelementinput-validationmessages>
</div>
