import { NgModule } from '@angular/core';

import { LayoutManagerService } from './layout-manager.service';
import { ThemeManagerModule } from '../theme-manager/theme-manager.module';

@NgModule({
  imports: [
    ThemeManagerModule
  ],
  declarations: [],
  exports: [],
  providers: [  ]
})
export class LayoutManagerModule {
}
