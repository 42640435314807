<ng-container *ngIf="showComponent">
  <div class="l-grid-list">
    <div *ngFor="let option of availableViewModes" [id]="'viewmode-option-' + option.Id" [class]="option.Id">
      <label>
      <input
        type="radio"
        name="options"
        value="option"
        [checked]="option.Type === currentViewMode"
        (click)="onOptionChanged(option.Type)">
        <i></i>
      </label>
    </div>
  </div>
</ng-container>
