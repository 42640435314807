import { Type } from '@angular/core';

import { QueryGroupByMode } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { GridRowDefaultComponent } from '../components/grid-row-default.component';
import { GridRowGroupExpandComponent } from '../components/grid-row-group-expand.component';
import { IGridRowMode } from './grid-row-mode-interface.component';

const GridRowMode: { [type: string]: Type<IGridRowMode> } = {
  [QueryGroupByMode.Default]: GridRowDefaultComponent,
  [QueryGroupByMode.Expand]: GridRowGroupExpandComponent,
};

export { GridRowMode };

