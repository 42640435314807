import { IFuseChartTypeInterface } from './i-fuse-chart-type.interface';
import { getInSafe } from '../../../../../../../shared/utils/typescript.utils';
import { DestroyableObjectTrait } from '../../../../../../../shared/utils/destroyableobject.trait';
import { ChangedetectorReference } from '../../../../../../../core/changedetector/changedetectoreference';
import { Directive } from '@angular/core';
import {
  Chart,
  ChartDataSeries,
  ChartExecutionResponse,
  IChart,
  IChartDisplayOptions
} from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Directive()
export abstract class BaseFuseChartTypeInterface<TChart extends Chart, TDisplayOptions extends IChartDisplayOptions> extends DestroyableObjectTrait implements IFuseChartTypeInterface {
  /**
   * The last Chart Execution Response
   */
  public chartResponse: ChartExecutionResponse;

  /**
   * Current Chart Info
   */
  public currentChart: TChart;

  /**
   * Custom Display Options for any chart type
   */
  public displayOptions: TDisplayOptions;

  /**
   * The Series Lenghth
   */
  public numSeries: number;

  /**
   * Chart View Attribute. To Resize. Use Only in NGX Charts
   */
  public view: [number, number] = [0, 0];

  protected constructor(protected cdReference: ChangedetectorReference) {
    super();
  }

  /**
   * Load the current chart into the component setting the CurrentChart and DisplayOptions
   *
   * @param currentChartResponse
   * @param chart
   */
  public setCurrentChart(currentChartResponse: ChartExecutionResponse, chart: IChart): void {
    this.chartResponse = currentChartResponse;
    if (this.chartResponse !== null && this.chartResponse !== undefined) {
      this.currentChart = chart as TChart;
      this.displayOptions = getInSafe(this.currentChart, x => x.ChartDisplayOptions as TDisplayOptions, {} as TDisplayOptions);
      this.initializeChart();
    }
  }

  public abstract initializeChart(): void;

  /**
   * Escuchamos al window resize porque el componente de charts debe repintarse
   * al ajustar la ventana, no lo hace automáticamente al estar detacheado.
   */
  onWindowResize(): void {
    this.cdReference.changeDetectorParent.detectChanges();
  }

  getDataSeries(): ChartDataSeries {
    return this.chartResponse.CompiledChart.Data as ChartDataSeries;
  }
}
