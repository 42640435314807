import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FuseChartsService } from '../../../../fuse-charts.service';
import { UtilsTypescript } from '../../../../../../../../../shared/utils/typescript.utils';
import { PrimeUtils } from '../../../../../../../../../shared/utils/prime.utils';
import { Observable, of, timer } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { BaseFuseChartTypeInterface } from '../../base-fuse-chart-type.class';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import {
  ISerie,
  ProgressBarChart,
  ProgressBarChartDisplayOptions
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { TranslatorService } from '../../../../../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-progress-bar-chart',
  templateUrl: './progress-bar-chart.component.html',
  styleUrls: ['./progress-bar-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class ProgressBarChartComponent extends BaseFuseChartTypeInterface<ProgressBarChart, ProgressBarChartDisplayOptions> implements OnInit {

  /**
   * Public variable that holds the number to display.
   */
  public displayNumber: number = 0;

  /**
   * SingleNumberChartComponent class constructor.
   *
   * @param {FuseChartsService} fuseChartsService
   * @param {ChangeDetectorRef} cdRef
   */
  constructor(
      public fuseChartsService: FuseChartsService,
      protected cdRef: ChangeDetectorRef,
      protected cdReference: ChangedetectorReference,
      protected translatorService: TranslatorService) {
    super(cdReference);
  }

  /**
   * Component init lifecycle method.
   */
  ngOnInit(): void {
    this.rollNumbers()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((value: number) => {
          this.displayNumber = this.formatNumber(value);
          this.cdRef.detectChanges();
        });
  }

  public initializeChart(): void {
    this.rollNumbers()
                .pipe(
            takeUntil(this.componentDestroyed$))
        .subscribe((value: number) => {
          this.displayNumber = this.formatNumber(value);
          this.cdRef.detectChanges();
        });
    this.cdReference.changeDetector.detectChanges();
  }

  /**
   * Number formatter.
   * @param {string} num
   */
  formatNumber(num: number): number {
    return parseInt((num || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'), 0);
  }

  /**
   * EL número para pintar..
   */
  private rollNumbers(): Observable<Number> {
    const singleNumber: number = this.singleNumber as number;
    const delay: number = 50;
    const loops: number = 25;

    return timer(0, delay)
                .pipe(
            takeUntil(this.componentDestroyed$))
        .pipe(
            take(loops),
            mergeMap((i: number) => {
              const value: number = (singleNumber / loops) * i;
              return of(i !== (loops - 1) ? Math.ceil(value) : singleNumber);
            }),
        );
  }

  /**
   * Returns the number to display.
   */
  get singleNumber(): Number {
    const serie: ISerie = PrimeUtils.ParseKeyItemToArray<ISerie>(this.getDataSeries().Series)[0];
    return UtilsTypescript.ObjectValues(serie.Values)[0].Value;
  }

  public getIndicatorText(): string {
    if (!this.displayOptions) {
      return '';
    }
    return this.translatorService.get(this.displayOptions.IndicatorTextFormat, {'{value}': this.displayNumber.toFixed(0)})
  }
}
