import { AfterViewInit, Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormElementTextArea } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined, UtilsTypescript } from '../../../../utils/typescript.utils';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { MaxLengthValidationInterface } from '../../shared-interfaces';
import { getRemainingChars, handleMaxLengthInput, lengthValidationClasses } from '../../shared-functions';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';

@Component({
  selector: 'app-input-rich-text-editor',
  templateUrl: './input-rich-text-editor.component.html',
  styleUrls: ['./input-rich-text-editor.style.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputRichTextEditorComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputRichTextEditorComponent), multi: true}
  ]
})
export class InputRichTextEditorComponent
    extends FrontendFormElementInput
    implements MaxLengthValidationInterface, AfterViewInit {

  @ViewChild('input', {static: true}) input: QuillEditorComponent;

  protected valueValue: string;

  isNullOrUndefined: any = isNullOrUndefined;

  /**
   * Form element definition
   */
  get elementConfiguration(): FormElementTextArea {
    return this.formManagerService.getConfigFromSelector(this.config.ClientPath) as FormElementTextArea;
  }

  public ngAfterViewInit(): void {
    const lineHeight: number = 25;
    if (this.config.rows > 0) {
      const value: number = this.config.rows * lineHeight;
      const content: any = this.input.elementRef.nativeElement.querySelector('.ql-editor');
      if (content) {
        content.style.height = value + 'px'; // Add 'px' to set the height with the appropriate unit
      }
    }

    super.ngAfterViewInit();
  }

  /**
   * Configuration for the rich text editor
   */

  get richTextEditorConfiguration(): QuillModules {
    const config: QuillModules = {} as QuillModules;
    config.clipboard = { matchers: [], matchVisual: false };
    config.toolbar = [
      ['bold', 'italic', 'underline', 'strike'],
      // ['blockquote', 'code-block'],
      [{'color': []}, {'background': []}],
      [{'list': 'bullet'}],
      ['clean'],
    ];

    return config;
  }

  set value(value: string) {
    this.valueValue = this.normalizeValue(value);
    this.propagateChange(this.valueValue, true);
  }

  get value(): string {
    return this.valueValue;
  }

  writeValue(obj: any): void {
    this.valueValue = obj;
    this.cdRef.detectChanges();
  }

  /**
   * Ensure that whitespaces are trated as null values
   *
   * @param value
   */
  protected normalizeValue(value: string): string {
    if (UtilsTypescript.isNullOrWhitespace(value)) {
      return null;
    }
    return value;
  }

  /**
   * Returns a boolean indicating if the user input must be "denied"
   * once the input length count equals the max length.
   */
  handleMaxLengthInput(): boolean {
    if (handleMaxLengthInput(this.config, this.value)) {
      return true;
    }

    return false;
  }

  /**
   * @inheritdoc
   */
  lengthValidationClasses(): Record<string, boolean> {
    return lengthValidationClasses(this.valueValue, this);
  }


  /**
   * @inheritdoc
   */
  getRemainingChars(): string {
    return getRemainingChars(this.valueValue, this);
  }

  /**
   * @inheritDoc
   */
  focusInput(): boolean {
    const inputElement: HTMLElement = document.getElementById(this.uniqueComponentId('textarea-'));

    if (inputElement) {
      inputElement.focus();
      return true;
    }

    return false;
  }
}
