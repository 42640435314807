'use strict';

import { Component, Input } from '@angular/core';
import { ChangedetectorReference } from '../../core/changedetector/changedetectoreference';


@Component({
  selector: 'app-my-spinner',
  styleUrls: ['spinner.component.scss'],
  templateUrl: 'spinner.component.html',
  providers: [
    ChangedetectorReference
  ]
})
export class SpinnerComponent {

  @Input() message: string;

  @Input() cssClasses: string[];

  detectChanges(): void {
    this.cdRef.changeDetector.detectChanges();
  }

  constructor(private cdRef: ChangedetectorReference) {
  }
}
