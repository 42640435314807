/**
 * Event thrown when a local action occurs.
 */
import { IViewsSingleItemOperation, ViewFieldMetadata, ViewResultRow } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * Event data when a Single Item Operation is triggered.
 */
export class SingleItemOperationEventData2 {
  /**
   * The name of the local action.
   */
  public action: string;

  /**
   * The actual instance of the local action.
   */
  public operation: IViewsSingleItemOperation;

  /**
   * The specific row.
   */
  public row: ViewResultRow;

  public GetFieldMetadata(metadataName: string): ViewFieldMetadata {
    if (!this.row.Metadata) {
      throw new Error('The current row has any metadata')
    }
    if (!this.row.Metadata.hasOwnProperty(metadataName)) {
      throw new Error(`The metadata ${metadataName} doesn't exists in current metadata row`)
    }
    return this.row.Metadata[metadataName] as ViewFieldMetadata;
  }

  public GetFieldMetadataValue<T>(metadataName: string): T {
    return this.GetFieldMetadata(metadataName).RawValue as T;
  }
}
