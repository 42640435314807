<ng-container *ngIf="wizardSteps.length > 0">
  <div [ngClass]="getClasses()" class="c-wizard">
    <div *ngFor="let tab of wizardSteps" [ngClass]="getTabCssClasses(tab)" class="c-wizard__step">
      <div>
        <div [routerLink]="tab.getFrontendPath()" routerLinkActive="is-current" class="c-wizard__step-title">
          <span>{{tab.description}}</span>
        </div>
        <div class="c-wizard__step-desc">{{tab.title}}</div>
      </div>
    </div>
    <div [routerLink]="previous()" class="c-wizard__step-title">
      <span><</span>
    </div>
    <div [routerLink]="next()" class="c-wizard__step-title">
      <span>></span>
    </div>
  </div>
</ng-container>
