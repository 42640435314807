import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BatchItemComponent } from '../configuration/batch/batch-item/batch-item.component';
import { CoreModule } from '../core/core.module';
import { HelpModule } from '../help/help.module';
import { BackButtonComponent } from './back-button/back-button.component';
import { BatchModule } from './batch/batch.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ContextComponent } from './context/context.component';
import { ScrollDirective } from './context/selector/directives/scroll.directive';
import { SelectorComponent } from './context/selector/selector.component';
import { CustomComponentFactoryService } from './custom-component-factory/custom-component-factory.service';
import { DecoupledModalModule } from './decoupled-modal/decoupled-modal.module';
import { FileDownloaderService } from './files/file-downloader.service';
import { FormGlobalServicesClass } from './form/form.global.services.class';
import { SFormModule } from './form/form.module';
import { GenericEmptyPageComponent } from './generic-pages/generic-empty-page/generic-empty-page.component';
import {
  GenericFormPageSimpleComponent
} from './generic-pages/generic-form-page-simple/generic-form-page-simple.component';
import {
  GenericListPageSimpleComponent
} from './generic-pages/generic-list-page-simple/generic-list-page-simple.component';
import { GenericPermissionModule } from './generic-permission/generic-permission.module';
import { GeocodingModule } from './geocoding/geocoding.module';
import { List2Module } from './list_v2/list.module';
import { ListComponent2Service } from './list_v2/list.service';
import { LocalNavComponent } from './local-nav';
import { MappingGlobalServicesClass } from './mapping/mapping.global.services.class';
import { MappingModule } from './mapping/mapping.module';
import { MethodologyACHSComponent } from './methodology/methodology-ACHS.component';
import { MethodologyFINEComponent } from './methodology/methodology-FINE.component';
import { MethodologyINSHTComponent } from './methodology/methodology-INSHT.component';
import { BreadCrumb_Component } from './navigation-components/bread-crumb/bread-crumb.component';
import {
  DropdownMenuChildComponent
} from './navigation-components/dropdown-menu/dropdown-menu-child/dropdown-menu-child.component';
import { DropdownMenuComponent } from './navigation-components/dropdown-menu/dropdown-menu.component';
import { LocalActionsComponent } from './navigation-components/local-actions/local-actions.component';
import { MenuChildComponent } from './navigation-components/menu/menu-child/menu-child.component';
import { MenuHamburgerComponent } from './navigation-components/menu/menu-hamburger/menu-hamburger.component';
import { SideMenuComponent } from './navigation-components/menu/side-menu.component';
import { PrimaryTabsComponent } from './navigation-components/primary-tabs/primary-tabs.component';
import { WizardnavigationComponent } from './navigation-components/wizardnavigation/wizardnavigation.component';
import { NavigationTabsComponent } from './navigation-tabs/navigation-tabs.component';
import { NextButtonComponent } from './next-button/next-button.component';
import { NgxBootStrapModule } from './ngx-bootstrap/ngx-bootstrap.module';
import { AlertModule } from './notifications';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerService } from './spinner/spinner.service';
import { TabsModule } from './tabs/tabs.module';
import { UtilsModule } from './utils/utils.module';
import { BatchGlobalServicesClass } from './batch/batch.global.service.class';
import {
  GenericFormListFormPageSimpleComponent
} from './generic-pages/generic-form-list-form-page-simple/generic-form-list-form-page-simple.component';
import { ReportsGlobalServicesClass } from './reports/reports-global.service.class';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FrontendGeocodingService } from './geocoding/frontend-geocoding.service';
import { InstallPromptComponent } from './installprompt/install-prompt/install-prompt.component';
import { SystemInfoComponent } from './system-info/system-info.component';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    AlertModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    RouterModule,
    NgxBootStrapModule,
    SFormModule,
    TabsModule,
    MappingModule,
    BatchModule,
    List2Module,
    UtilsModule,
    HelpModule,
    GeocodingModule,
    GenericPermissionModule,
    DecoupledModalModule.forRoot(),
    TooltipModule.forRoot(),
    ExtendedModule,
    FlexModule,
    MatButtonModule,
  ],
  declarations: [
    BreadCrumb_Component,
    ConfirmationDialogComponent,
    ContextComponent,
    DropdownMenuChildComponent,
    DropdownMenuComponent,
    MenuChildComponent,
    MenuHamburgerComponent,
    NavigationTabsComponent,
    LocalActionsComponent,
    PrimaryTabsComponent,
    WizardnavigationComponent,
    ScrollDirective,
    SelectorComponent,
    SideMenuComponent,
    SpinnerComponent,
    LocalNavComponent,
    MethodologyFINEComponent,
    MethodologyINSHTComponent,
    MethodologyACHSComponent,
    BackButtonComponent,
    WizardnavigationComponent,
    NextButtonComponent,
    GenericEmptyPageComponent,
    GenericFormPageSimpleComponent,
    GenericListPageSimpleComponent,
    GenericFormListFormPageSimpleComponent,
    InstallPromptComponent,
    SystemInfoComponent,
  ],
  exports: [
    BreadCrumb_Component,
    CommonModule,
    ConfirmationDialogComponent,
    ContextComponent,
    DropdownMenuChildComponent,
    DropdownMenuComponent,
    FormsModule,
    MenuChildComponent,
    MenuHamburgerComponent,
    NavigationTabsComponent,
    PrimaryTabsComponent,
    WizardnavigationComponent,
    LocalActionsComponent,
    SFormModule,
    SpinnerComponent,
    TabsModule,
    SideMenuComponent,
    MappingModule,
    BatchItemComponent,
    List2Module,
    LocalNavComponent,
    UtilsModule,
    MethodologyFINEComponent,
    MethodologyINSHTComponent,
    MethodologyACHSComponent,
    GenericPermissionModule,
    BackButtonComponent,
    ScrollDirective,
    SelectorComponent,
    GenericEmptyPageComponent,
    GenericFormPageSimpleComponent,
    NextButtonComponent,
    HelpModule,
    InstallPromptComponent,
    SystemInfoComponent,
  ],
  providers: [
    ListComponent2Service,
    BsLocaleService
  ]
})

export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        FormGlobalServicesClass,
        MappingGlobalServicesClass,
        BatchGlobalServicesClass,
        SpinnerService,
        CustomComponentFactoryService,
        FileDownloaderService,
        ReportsGlobalServicesClass
      ]
    }
  }

  constructor(private bsLocaleService: BsLocaleService,
              private formGlobalServicesClass: FormGlobalServicesClass,
              private mappingGlobalServicesClass: MappingGlobalServicesClass,
              private batchGlobalServicesClass: BatchGlobalServicesClass,
              private spinnerService: SpinnerService,
              private reportsService: ReportsGlobalServicesClass,
              private frontendGeocodingService: FrontendGeocodingService) {
    this.bsLocaleService.use('es');
  }
}
