import { Component, Input } from '@angular/core';

import { AbstractDecoupledModalComponent } from '../models/abstract-decoupled-modal.component';
import { ConfirmDialogInterface } from '../models/modal-params.interface';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends AbstractDecoupledModalComponent {

  /**
   * @inheritdoc
   */
  @Input()
  data: ConfirmDialogInterface;

}
