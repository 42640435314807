<app-comment [value]="'TO TRANSLATE THIS FORM, USE THE FOLLOWING CONTEXT: be:form:' + formPlugin"></app-comment>
<div *ngIf="loadingAnimation" class="flex-col-lg-12">
  <app-form-loading-placeholer></app-form-loading-placeholer>
</div>

<div *ngIf="!loadingAnimation && loadErrorCode === 403 && !hideOn403">
  <span>{{'No tiene permisos para ver este recurso' | t }}</span>
</div>

<div *ngIf="!loadingAnimation && loadErrorCode === 429">
  <span>{{'No se ha podido obtener el recurso. Por favor reintentelo de nuevo más tarde.' | t }}</span>
</div>

<div *ngIf="!loadingAnimation && loadErrorCode === 0" style="margin-left: 15px">
  <span>{{'Parece que ha habido un error de conexión y no se ha podido completar la petición correctamente. Por favor, inténtelo de nuevo recargando la página o haciendo clic en el botón "Reintentar".' | t }}</span>
  <div>
    <button type="button" class="o-btn__primary" (click)="this.getConfig();">{{ 'Reintentar' | t }}</button>
  </div>
</div>
<div #container></div>

