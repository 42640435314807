import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  BatchTaskInfo,
  CoreFormSuccessEventCommand,
  CoreMappingRequest,
  FormPluginRequest,
  FormState,
  FormSubmitResult,
  WebServiceResponseTyped
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { AbstractDecoupledModalComponent } from '../../decoupled-modal/models/abstract-decoupled-modal.component';
import { IFormEvent } from '../../form/interfaces/formevent.interface';
import {
  asIterableObject,
  backendTypeMatch,
  getInSafe,
  isNullOrWhitespace,
  UtilsTypescript
} from '../../utils/typescript.utils';
import { MappingSteps } from './mapping-flow-modal.enum';
import { BatchService } from '../../../core/services/ETG_SABENTISpro_Application_Core_batch.service';


@Component({
  selector: 'app-mapping-flow-modal',
  templateUrl: './mapping-flow-modal.component.html',
  styleUrls: ['./mapping-flow-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MappingFlowModalComponent extends AbstractDecoupledModalComponent {

  /**
   * Hay algunos sitios donde no se define el formulario por defecto, así que
   * lo hardcoedamos aquí (tal y como estaba antes del refactor de este componente...)
   * El valor de defecto ya está puesto en backend en los objetos CoreMappingRequest
   * @protected
   */
  protected readonly defaultMappingExecutionFormId: string = 'core-mapping-spreadsheet-execution-form';

  /**
   * Disponibilizamos el enum en la plantilla
   */
  public mappingSteps = MappingSteps;

  /**
   * Paso actual del proceso de ejecución de mapping. Iniciamos con la selección de mapping (que es un drop-down
   * donde el usuario elige cual de los mappings disponibles quiere ejecutar)
   */
  currentStep: MappingSteps = this.mappingSteps.SELECT_MAPPING;

  /**
   * Mappings disponibles que el usuario puede elegir
   */
  @Input()
  availableMappings: CoreMappingRequest[] = [];

  /**
   * El id de mapping que se selecciona en el paso 1 (SELECT_MAPPING). Aquí tenemos el ID, no el nombre de plugin! :(
   */
  selectedMappingId: string;
  selectedMappingPlugin: string;

  /**
   * El plugin request para el formulario de generación de plantilla
   */
  templateGeneratePluginRequest: FormPluginRequest;

  /**
   * El objeto BatchTaskInfo que representa la ejecución del importador
   */
  executionTask: BatchTaskInfo;

  /**
   * La tarea batch de un proceso de exportación (descargar plantilla)
   */
  templateTask: BatchTaskInfo;

  /**
   * Parámetros para pasar al formulario de selección de mapping "core-bulk-load-select-form"
   */
  get bulkLoadSelectParams(): {} {
    // Este formulario recibe como parámetro un listado de identificadores de mapping
    // que va a mostrar al usuario para elegir. Realiza comprobaciones de seguridad
    // antes de mostrar nada
    return {'mappingsId': this.availableMappings.map((i) => i.MappingId)};
  }

  /**
   * MappingFlowModalComponent class constructor.
   */
  constructor(private batchService: BatchService) {
    super();
  }

  /**
   * Una vez que ya hemos pasado por el paso 0, devuelve el request
   * del mapping que debe ejecutar (seleccionado por el usuario)
   */
  get selectedMappingMappingRequest(): CoreMappingRequest {
    const request: CoreMappingRequest = UtilsTypescript.jsonClone(this.availableMappings.find((i) => i.MappingId === this.selectedMappingPlugin));
    if (isNullOrWhitespace(request.ExecutionFormId)) {
      request.ExecutionFormId = this.defaultMappingExecutionFormId;
    }
    return request;
  }

  /**
   * Argumentos para enviar al formulario de ejecución. Ver MappingSpreadSheetExecutionFormPlugin
   * ya que los argumentos van por "convenición" (POS) con el nombre "argument".
   */
  get executionFormArguments(): object {
    const args: object = {};
    args['argument'] = this.selectedMappingMappingRequest.MappingArguments;
    args['mappingdefinitionId'] = this.selectedMappingId;
    return args;
  }

  /**
   * Event que emite el formulario de ejecución cuando se seleccionan los botones
   * de exportar con datos o exportar sin datos.
   *
   * @param event
   */
  executionFormEventComplexHandler(event: IFormEvent): void {
    if (event.emiter && (event.emiter.endsWith('generateTemplateWithData') || event.emiter.endsWith('generateTemplateWithoutData'))) {
      this.currentStep = this.mappingSteps.TEMPLATE_BATCH;
      this.templateGeneratePluginRequest = new FormPluginRequest();
      const metadata: Object = event.metadata;
      if (metadata.hasOwnProperty('formArguments')) {
        this.templateGeneratePluginRequest.Arguments = metadata['formArguments'];
      }
      if (metadata.hasOwnProperty('formToCall')) {
        this.templateGeneratePluginRequest.FormId = metadata['formToCall'];
      }
    }
  }

  /**
   * Handler for Select Form succeed event.
   * @param $event
   */
  saveSucceedSelectForm($event: { id: string; message: any[]; responseData: FormSubmitResult }): void {
    this.selectedMappingId = $event.id;
    this.selectedMappingPlugin = $event.responseData.Result.plugin;
    this.currentStep = this.mappingSteps.EXECUTION_FORM;
  }

  /**
   * Handler for Execution Form succeed event.
   * @param $event
   */
  saveSucceedExecutionForm($event: { id: string; message: any[]; responseData: FormSubmitResult }): void {
    const taskId: string = getInSafe($event.responseData as FormSubmitResult, r => r.Result['id'], null);
    this.batchService.getTask(taskId).subscribe((response: WebServiceResponseTyped<BatchTaskInfo>) =>
      this.executionTask = response.result
    );
    this.currentStep = this.mappingSteps.RESULT_BATCH;
  }

  /**
   * Handler for Template Batch succeed event.
   * @param $event
   */
  saveSucceedTemplateBatch(e: any): void {
    let resultId: string = null;
    if ((e.formState as FormState).SubmitResult.Actions) {
      resultId = (asIterableObject((e.formState as FormState).SubmitResult.Actions).find(x => backendTypeMatch(CoreFormSuccessEventCommand.$type, x)) as CoreFormSuccessEventCommand).Result.id;
    } else if (e.formState.SubmitResult.Result) {
      resultId = e.formState.SubmitResult.Result.id
    }
    if (resultId) {
      this.batchService.getTask(resultId).subscribe((response: WebServiceResponseTyped<BatchTaskInfo>) => {
        this.templateTask = response.result;
      });
    }
  }

  cancelTemplateBatch(e: any): void {
    this.currentStep = this.mappingSteps.EXECUTION_FORM;
    this.templateTask = null;
  }

  loaded(e: any): void {
    this.saveSucceedTemplateBatch(e);
  }
}
