import { Component, OnInit } from '@angular/core';
import { DecoupledModalBridgeService } from '../../shared/decoupled-modal/decoupled-modal-bridge.service';
import { HelpService } from '../help.service';
import { FeedbackDialogComponent } from './feedback-dialog.component';

/**
 * This show the modal for feedback form.
 */
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  /**
   * FeedbackComponent class constructor.
   */
  constructor(
    private helpService: HelpService,
    private dmbs: DecoupledModalBridgeService
  ) { }

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.helpService.getObservable()
      .subscribe(() => this.openModal());
  }

  /**
   * This method open the modal for feedback.
   */
  openModal(): void {
    this.dmbs.showComponent(
      FeedbackDialogComponent,
      { },
    );
  }
}
