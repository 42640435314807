<!--<div class="chart-container" style="position: relative; height:280px; width:100%">
  <canvas #canvasItem height="280"></canvas>
</div>-->
<div class="chart-container"
     (appOnWindowResize)="onNgxChartResizedWithDirective()"
     (window:resize)="onNgxChartResized($event)"
     resizeWindowDebounceTime="500">
  <ngx-charts-bubble-chart
      [results]="data"
      [xAxis]="!displayOptions?.HideXAxis"
      [yAxis]="!displayOptions?.HideYAxis"
      [legend]="getLegendDisplayOptions()?.ShowLegend"
      [legendTitle]="getLegendDisplayOptions()?.LegendTitle"
      [legendPosition]="getLegendDisplayOptions()?.LegendPosition"
      [tooltipDisabled]="displayOptions?.TooltipDisabled"
      [showXAxisLabel]="displayOptions.ShowXAxisLabel"
      [showYAxisLabel]="displayOptions.ShowYAxisLabel"
      [xAxisLabel]="displayOptions.XAxisLabel"
      [yAxisLabel]="displayOptions.YAxisLabel"
      [xScaleMin]="displayOptions.XScaleMin"
      [xScaleMax]="displayOptions.XScaleMax"
      [yScaleMin]="displayOptions.YScaleMin"
      [yScaleMax]="displayOptions.YScaleMax"
      [scheme]="colorScheme"
      [view]="view"
      #chart>

    <ng-template #seriesTooltipTemplate let-model="model">
      <div [innerHTML]="getTooltipText(model)"></div>
    </ng-template>
  </ngx-charts-bubble-chart>
</div>
