import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogWrapperService } from '../dialog-wrapper.service';
import { AbstractDecoupledModalComponent } from '../models/abstract-decoupled-modal.component';


@Component({
  selector: 'app-decoupled-modal-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
  providers: [DialogWrapperService]
})
export class BaseDialogComponent
  extends AbstractDecoupledModalComponent implements OnInit {

  /**
   * Container Template Reference.
   */
  @ViewChild('bodyContainer', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  /**
   * Container Template Reference.
   */
  @ViewChild('footerContainer', { read: ViewContainerRef, static: true })
  footerContainer: ViewContainerRef;

  constructor(private wrapperService: DialogWrapperService) {
    super();
  }

  /**
   * @inheritdoc
   */
  ngOnInit(): void {
    this.wrapperService.initialize(this);
  }
}
