import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { AppMappingService } from '../../../shared/mapping/mapping.service';
import { FormManagerComponent } from '../../../shared/form/form-manager/form-manager.component';
import { BackNavigateOptions } from '../../../core/navigation/object-navigation';

/**
 * MappingDeleteComponent: component that controls the mapping delete
 *
 * @export
 * @class MappingDeleteComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-mapping-delete',
  templateUrl: './mapping-delete.component.html'
})

/**
 * This view controls the visualization of information for users and asociated groups
 */
export class MappingDeleteComponent implements OnInit {

  @ViewChild('form', { read: FormManagerComponent, static: true }) form;
  params = {};

  /**
   * Creates an instance of MappingDetailsComponent
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private navigationService: NavigationService, private appMappingService: AppMappingService) {
  }

  /**
   * Initialization to get the parameters from the navigation
   */
  ngOnInit(): void {
    const routeParams: object[] = this.navigationService.findParamsFromRoute(this.activatedRoute.snapshot);
    const params: object = routeParams.find(x => x.hasOwnProperty('mapping'));
    if (!isNullOrUndefined(params)) {
      if (this.router.url.includes('copy')) {
        this.params[ 'copyFromMappingId' ] = params[ 'mapping' ];
      }
      else {
        this.params[ 'mappingdefinitionId' ] = params[ 'mapping' ];
      }
    }
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    this.navigationService.back({skipMapping: true} as BackNavigateOptions);
  }

  /**
   * Handler for form succeed event.
   * @param e
   */
  saveSucceed(e: any): void {
    this.navigationService.back();
  }
}
