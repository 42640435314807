import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ListComponent2Service } from '../../list.service';
import { Subject } from 'rxjs';
import { IViewMode, ViewModeTypeEnum } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ViewsinitializedEventdata } from '../../events/viewsinitialized.eventdata';
import { PrimeUtils } from '../../../utils/prime.utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-mode-selector',
  templateUrl: './view-mode-selector.component.html',
  styleUrls: ['./view-mode-selector.component.scss']
})
export class ViewModeSelectorComponent implements OnInit, OnDestroy {

  public availableViewModes: IViewMode[];
  public currentViewMode: ViewModeTypeEnum;
  public showComponent: boolean = false;
  private destroyedComponent$: Subject<null> = new Subject<null>();

  constructor(protected viewService: ListComponent2Service,
              protected _elementRef: ElementRef,
              protected _renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.viewService.viewIntialized
        .pipe(takeUntil(this.destroyedComponent$))
        .subscribe(this.Initialize.bind(this));
  }

  /**
   * Internal propagation of a new selected item
   *
   * @param {ViewsinitializedEventdata} event
   * @constructor
   */
  Initialize(event: ViewsinitializedEventdata): void {
    this.currentViewMode = event.userConfiguration.CurrentViewModeType;
    this.availableViewModes = PrimeUtils.ParseKeyItemToArray<IViewMode>(event.configuration.AvailableViewModes);
    this.showComponent = this.availableViewModes.length > 1;
    this._renderer.addClass(this._elementRef.nativeElement, 'current-view-mode-' + this.availableViewModes.find(x => x.Type === this.currentViewMode).Id);
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this.destroyedComponent$.next(null);
    this.destroyedComponent$.unsubscribe();
  }

  /**
   * On Option changed
   * @param option
   */
  onOptionChanged(option: ViewModeTypeEnum): void {
    this._renderer.removeClass(this._elementRef.nativeElement, 'current-view-mode-' + this.availableViewModes.find(x => x.Type === this.currentViewMode).Id);
    this.currentViewMode = option;
    this.viewService.changeViewMode(option);
    this._renderer.addClass(this._elementRef.nativeElement, 'current-view-mode-' + this.availableViewModes.find(x => x.Type === this.currentViewMode).Id);
  }
}
