/**
 * Clase para pasar a través del router configuración estática del componente
 */
export class GenericFormPageSimpleArguments {

  /**
   * El ide del formulario
   */
  formId: string;

  /**
   * Map of route arguments to form parameteres
   */
  formArgumentMapping: { [id: string]: string };

  /**
   * Map of route arguments to form parameteres
   */
  formArgumentMappingFromResolver: { [id: string]: string };

  constructor(formId: string,
              formArgumentMapping: { [id: string]: string },
              formArgumentMappingFromResolver: { [id: string]: string } = null) {
    this.formId = formId;
    this.formArgumentMapping = formArgumentMapping;
    this.formArgumentMappingFromResolver = formArgumentMappingFromResolver;
  }
}
