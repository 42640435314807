import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FuseSharedModule } from '../../../../../../../../../@fuse/shared.module';
import { BasicLineChartComponent } from './components/basic-line-chart.component';
import { LineChartModule } from '@swimlane/ngx-charts';
import { UtilsModule } from '../../../../../../../../utils/utils.module';

@NgModule({
    declarations: [BasicLineChartComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        LineChartModule,
        UtilsModule
    ]
})
export class FuseLineChartModule {
}
