import { HostListener, Injectable, Optional, SkipSelf } from '@angular/core';
import { isNullOrUndefined } from '../utils/typescript.utils';
import { Observable, of } from 'rxjs';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';

@Injectable({
  providedIn: 'root'
})
export class InstallPromptService extends DestroyableObjectTrait {
  private promptEvent: any;
  private installPromptIsEnabled: boolean;

  constructor(@Optional() @SkipSelf() parentModule?: InstallPromptService) {
    super();

    if (parentModule) {
      throw new Error(
          'InstallPromptService is already loaded. Import it in the AppModule only');
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  public onBeforeInstallPrompt(event: any): void {
    event.preventDefault();
    this.promptEvent = event;
    this.installPromptIsEnabled = !isNullOrUndefined(this.promptEvent);
  }

  public isEnabled(): Observable<boolean> {
    return of(this.installPromptIsEnabled);
  }

  public showInstallPrompt(): void {
    console.log('sss');
    if (this.promptEvent) {
      console.log('enabled');
      this.promptEvent.prompt();
    }
  }
}
