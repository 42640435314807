import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LabelComponent extends FrontendFormElementWrapper {

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
