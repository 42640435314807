import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FrontendFormElementWrapper } from '../../../../../../../shared/form/form-components/formelementwrapper.class';
import { FrontendFormElementInput } from '../../../../../../../shared/form/form-components/formelementinput.class';
import { fuseAnimations } from '../../../../../../../../@fuse/animations';

@Component({
  selector: 'app-form-element-navigation-board',
  templateUrl: './form-element-navigation-board.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class FormElementNavigationBoardComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput', {static: true})
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

}
