<div class="chart-filters-container" *ngIf="filtersFormState !== null && filtersFormState !== undefined">
  <div class="chart-form-container">
    <ng-container #exposedFilterContainer appFormChartGenerator
                  *ngIf="filtersFormState !== null && filtersFormState !== undefined"
                  [formState]="filtersFormState"></ng-container>
  </div>
  <div class="bottom-actions-content w-100-p px-8 py-12" fxLayout="row" fxLayoutAlign="start center">
    <button
        id="btn_filters__apply"
        mat-button
        color="accent"
        class="o-btn__accent"
        (click)="applyFilters()">
      {{ 'Filtrar' | t }}
    </button>
    <button
        id="btn_filters__reset"
        style="margin-left: 6px"
        mat-button
        color="accent"
        class="o-btn__secondary"
        (click)="resetFilters()">
      {{ 'Restablecer filtros' | t }}
    </button>
  </div>
</div>

