import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FuseSharedModule } from '../../../../../../../../../@fuse/shared.module';
import { BasicMapComponent } from './components/basic-map.component';
import { GeocodingModule } from '../../../../../../../../shared/geocoding/geocoding.module';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    declarations: [
        BasicMapComponent
    ],
  imports: [
    CommonModule,
    GeocodingModule,
    FuseSharedModule,
  ],
    providers: []
})
export class MapChartModule {
}
