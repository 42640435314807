export class NotHandledError {
  message: string;
  name: string;

  constructor(_name: string, _message: string) {
    this.message = _message;
    this.name = _name;
  }

  toString(): string {
    return `${this.name}: ${this.message}`;
  }
}
