<div class="dropdown">
    <a class="btn-dropdown" id="dropdownMenu1" aria-haspopup="true" aria-expanded="true" (click)="toggle()">
      <div class="dropdown-user">
      <span class="initial">{{this.userInitial}}</span>
      </div>
    </a>


  <!-- Ponemos el ngIf open porque la implementación de este menú flotante hace un forced-reflow! -->
  <app-dropdown-menu-child *ngIf="open"
                           [children]="tree?.children"
                           [open]="open"
                           [direction]="direction"
                           [isRoot]="true"
                           (childEvents)="propagateEvent($event)"></app-dropdown-menu-child>

</div>
