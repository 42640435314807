import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';

import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent implements AfterContentInit {

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  // contentChildren are set
  ngAfterContentInit(): void {
    // get all active tabs
    const activeTabs: TabComponent[] = this.tabs.filter((tab) => tab.active && !!tab.hidden);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: TabComponent): void {
    // deactivate all tabs
    this.tabs.toArray().forEach(currentTab => currentTab.active = false);

    // activate the tab the user has clicked on.
    tab.active = true;
  }

}
