import { FileWrapper, UploadStatus } from '../../../file-uploader/models/file-wrapper';
import { take, takeUntil } from 'rxjs/operators';
import { FrontendFormElementInput } from '../formelementinput.class';
import { FileUploaderService } from '../../../file-uploader/file-uploader.service';
import { CommunicationService } from '../../../../core/communication/communication.service';
import { MessageToastService } from '../../../../core/message-toast/share/message-toast.service';
import { ChangeDetectorRef, Directive } from '@angular/core';
import { DecoupledModalBridgeService } from '../../../decoupled-modal/decoupled-modal-bridge.service';
import { FormManagerService } from '../../form-manager/form-manager.service';
import {
  DtoFrontendModalSize,
  DtoFrontendModalType,
  FileComponentMode,
  UploadedFile
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FormatFileSize, isNullOrUndefined, UtilsTypescript } from '../../../utils/typescript.utils';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FileUtils } from '../../../utils/file.utils';
import { TranslatorService } from '../../../../core/translator/services/rest-translator.service';

@Directive()
export class FileComponentClass extends FrontendFormElementInput {

  /**
   * Array of selected files
   */
  files: Array<FileWrapper> = new Array<FileWrapper>();

  constructor(
      protected uploaderService: FileUploaderService,
      protected communicationService: CommunicationService,
      protected messageService: MessageToastService,
      protected cdRef: ChangeDetectorRef,
      protected dmbs: DecoupledModalBridgeService,
      protected formManagerService: FormManagerService,
      protected localeService: TranslatorService) {
    super(formManagerService, cdRef, localeService);
  }

  get mode(): 'PRETTY' | 'LIST' {
    if (!this.config?.defaultComponentMode) {
      return undefined;
    }

    if (this.config.defaultComponentMode.toFixed() === FileComponentMode.Pretty.toFixed()) {
      return 'PRETTY';
    } else if (this.config.defaultComponentMode.toFixed() === FileComponentMode.List.toFixed()) {
      return 'LIST'
    } else {
      throw new Error('Unsupported file mode')
    }
  }

  /**
   * Get accessor
   */
  get value(): Array<UploadedFile> {
    return this.files
        .filter(i => !isNullOrUndefined(i.UploadedFile))
        .map(i => i.UploadedFile);
  }

  /**
   * Set accessor including call the onchange callback
   */
  set value(v: Array<UploadedFile>) {
    this.propagateChange(v);
  }

  /**
   * Do UploadFiles
   * @param files
   */
  uploadFiles(files: FileWrapper[]): void {
    files.map(((file: FileWrapper): void => {
      if (this.files.length < (this.config.maxFiles || 0)) {
        // This subscription works as a trigger for
        // change detection callbacks that must be
        // trigger when the form control "value" is changed.
        // This asignation (or push) must trigger validation and
        // value change propagation callbacks registered from
        // ControlValueAccessor and Validator interfaces.
        // Completed observable only emits a value when
        // the file is accepted by the server. That's when we need
        // to emit a form control value change.
        // @see "set value()" method on this file.
        file.completed
            .pipe(
                takeUntil(this.componentDestroyed$),
                take(1)
            )
            .subscribe(v => {
              this.value = [...this.value];
              this.propagateChange(this.value);
            });

        this.files.push(file);

        /**
         * Make sure we repaint the control when de file status is updated
         */
        file.statusChangedObservable
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe(() => {
              this.cdRef.detectChanges();
            });

        /**
         * Start the upload now
         */
        if (this.config.autoUpload) {
          file.upload();
        }

        this.propagateChange(this.value);
      }
    }).bind(this));
  }

  /**
   * Gets the link that will be painted to display the image
   *
   * @param {UploadedFile} file
   * @returns {string}
   */
  getLink(file: UploadedFile): string {
    if (isNullOrUndefined(file?.url)) {
      return null;
    }
    return this.communicationService.generateFileUrl(file.url);
  }

  getSrc(file: UploadedFile): string {
    console.debug(file);
    console.debug(file?.type);

    if (isNullOrUndefined(file) || isNullOrUndefined(file?.url)) {
      return 'assets/icons/icons8-dots-loading.gif';
    }

    return this.communicationService.generateThumbFileUrl(file.url, file.type);
  }

  isImage(file: UploadedFile): boolean {
    if (isNullOrUndefined(file?.type)) {
      return false;
    }

    return file.type.startsWith('image/');
  }

  openImageViewer(file: UploadedFile): void {
    if (!isNullOrUndefined(file?.url)) {
      this.dmbs.showForm('core-image-viewer-form', {
            ModalType: DtoFrontendModalType.Modal,
            ModalSize: DtoFrontendModalSize.ExtraLage
          },
          {
            'fileId': file?.id,
          });
    }
  }

  getSize(file: UploadedFile): string {

    if (isNullOrUndefined(file?.size)) {
      return '?';
    }

    return this.formatFileSize(file.size);
  }

  /**
   * List of valid extensions
   *
   * @returns {Array<string>}
   */
  validExtensions(): string {
    return [...(this.config.validExtensions || [])]
        .map(e => `.${e}`)
        .join(', ');
  }

  /**
   * This method only slices temporary/not-previously-saved files from the form control.
   *
   * TODO: Hay que llamar a backend para eliminar. Solo se eliminan aquellos ficheros que ya han sido
   * cargados (tienen un UploadedFile) y en servidor además solo se borrarán si están como TEMPORALES.
   *
   * @param {FileWrapper} file
   */
  removeFile(file: FileWrapper): void {
    const index: number = this.files.findIndex(
        fitem => {
          return (fitem.getIdentifier() === file.getIdentifier());
        }
    );

    if (index !== -1) {
      this.files.splice(index, 1);
      this.propagateChange(this.value);
    }
  }

  /**
   * This method hides the form control if the files setted on the control reaches the max files limit.
   *
   * TODO: No queda claro en la GUI porque la opción de cargar ficheros adicionales desaparece.
   */
  canUploadFiles(): boolean {
    return isNullOrUndefined(this.config.maxFiles) || (this.files.length < this.config.maxFiles);
  }

  /**
   * Validate
   * @param c
   */
  doValidate(c: AbstractControl): ValidationErrors {
    const errors: ValidationErrors = super.doValidate(c);
    const fileErrors: ValidationErrors = FileUtils.ValidateFileFormComponent(this.files, this.config)

    Object.keys(fileErrors).forEach(x => {
      errors[x] = fileErrors[x];
    })

    return errors;
  }

  /**
   * Indica si el componente está "ocupado". Cuando un componente está ocupado, no se permiten
   * cambios de valores en los input del formulario, ni ejecuciones de submit/rebuild/rebuildvalues, etc.
   */
  componentIsBussy(): boolean {
    return this.files.filter((i) =>
        i.getStatus() === UploadStatus.PAUSED
        || i.getStatus() === UploadStatus.WAITING
        || i.getStatus() === UploadStatus.STARTED)
        .length > 0;
  }

  /**
   * Cancel a upload file
   * @param file
   */
  cancel(file: FileWrapper): void {
    file.statusChangedObservable
        .pipe(take(1))
        .subscribe((status) => {
          this.formManagerService.detectChangesAllForm();
        });

    file.cancel();
  }

  formatFileSize(size: number): string {
    return FormatFileSize(size);
  }

  /**
   * Write a new value to the element.
   */
  writeValue(value: Array<UploadedFile>): void {
    let values: any = UtilsTypescript.getNewtonSoftRealValue(value);
    if (isNullOrUndefined(values)) {
      values = [];
    }

    this.files = values.map(
        i => new FileWrapper(null, this.uploaderService, null, i)
    );
  }
}
