import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageBarComponent } from './componentes/message-bar/message-bar.component';
import { MessageBarService } from './services/message-bar.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [MessageBarComponent],
    providers: [MessageBarService]
})
export class MessageBarModule { }
