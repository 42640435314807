import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Directive to detect file dropping on a dom element.
 *
 * ACHSPRIME-2168 Revisado componente completo OK
 */
@Directive({
  selector: '[appFileDrop]'
})
export class FileDropDirective {
  @Output() fileOver: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileDrop: EventEmitter<FileList> = new EventEmitter<FileList>();

  /**
   * Constructor
   */
  constructor() {
  }

  /**
   * Detects drag over events on a dom element.
   *
   * ACHSPRIME-2168 Revisado componente completo OK
   *
   * @param {DragEvent} e
   */
  @HostListener('dragover', ['$event'])
  public onDragOver(e: DragEvent): void {
    e.preventDefault();

    const data: DataTransfer = e.dataTransfer;
    if (!data.types.includes('Files')) {
      return;
    }

    this.fileOver.emit(true);
  }

  /**
   * Detects drag leave events on a dom element.
   *
   * ACHSPRIME-2168 Revisado componente completo OK
   *
   * @param {DragEvent} e
   */
  @HostListener('dragleave', ['$event'])
  public onDragLeave(e: DragEvent): void {
    e.preventDefault();

    const data: DataTransfer = e.dataTransfer;
    if (!data.types.includes('Files')) {
      return;
    }

    this.fileOver.emit(false);
  }

  /**
   * Detects file drop events on a dom element.
   *
   * ACHSPRIME-2168 Revisado componente completo OK
   *
   * @param {DragEvent} e
   */
  @HostListener('drop', ['$event'])
  public onDrop(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();

    const data: DataTransfer = e.dataTransfer;
    if (!data.types.includes('Files')) {
      return;
    }

    this.fileOver.emit(false);
    this.fileDrop.emit(data.files);
  }
}
