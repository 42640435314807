/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { GetCompanyStatisticsResult } from '../models/ETG_SABENTISpro_Achs_models';

@Injectable({ providedIn: 'root' })
export class AchsIntegrationService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Get a list of active modules.
  *
  * @param {string} companyId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {GetCompanyStatisticsResult}
  */
  getGetcompanystatisticsreport(companyId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<GetCompanyStatisticsResult>>
  {
    const params: any = {'companyId' : companyId};
    return this.generalService.get(`achs-integration/get-company-statistics-report`, params, clientOpts) as Observable<WebServiceResponseTyped<GetCompanyStatisticsResult>>;
  }
}
