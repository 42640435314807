/**
 * Representa un nivel de nevagación de tabs
 */
import { MenuItemCompiledFrontend } from './models/MenuItemCompiledFrontend.class';

export class NavigationTabLevel {

  /**
   * Los enlaces del nivel de tabs
   */
  public Tabs: MenuItemCompiledFrontend[];

  /**
   * El título del nivel de tabs, si lo tiene
   */
  public Title: string;

  /**
   * The item used to parent-navigate
   */
  public ParentItem: MenuItemCompiledFrontend;
}
