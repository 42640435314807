// Not the best way to deal with analytics....
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { AppConfigurationService } from '../app.configuration.service';
import { AppContextService } from '../shared/context/context.service';
import { getInSafe } from '../shared/utils/typescript.utils';
import { Observable } from 'rxjs';
import { EventData } from '../core/events/interfaces/event.class';
import { DestroyableObjectTrait } from '../shared/utils/destroyableobject.trait';
import { take, takeUntil } from 'rxjs/operators';
import { GoogleAnalyticsClientSettings } from '../core/models/ETG_SABENTISpro_Application_Modules_models';
import { compactElementId } from './googleanalytics.utils';
import { NavigationService } from '../core/navigation/navigation.service';

/**
 * The global Google Analytics function
 */
declare var gtag: (Function);

/**
 * The main service for Google Analytics management
 */
@Injectable()
export class GoogleanalyticsService extends DestroyableObjectTrait {

  /**
   * GA Settings
   */
  settings: GoogleAnalyticsClientSettings;

  /**
   * Tracking codes de GA4
   */
  ga4codes: string[] = [];

  /**
   * Get an instance of GoogleanalyticsService
   */
  constructor(
    private appConfigurationService: AppConfigurationService,
    private appContextService: AppContextService,
    private router: Router,
    private navigationService: NavigationService
  ) {
    super();
  }

  /**
   * Envía un evento compatible con GA4 y UA
   */
  pushEvent(event: EventData): void {
    console.debug('Analytics event: ' + event.action);
    gtag('event', event.action, event.params);
  }

  /**
   * Envía un evento compatible con GA4
   */
  pushEventAction(action: string): void {
    const data: EventData = new EventData();
    data.action = action;
    this.addContextDataToEvent(data);
    this.pushEvent(data);
  }

  /**
   * Envía un evento compatible con GA4
   */
  pushEventWhithContext(event: EventData): void {
    this.addContextDataToEvent(event);
    this.pushEvent(event);
  }

  /**
   * Añade info de contexto a un evento
   *
   * @param event
   */
  addContextDataToEvent(event: EventData): void {
    // A todos los eventos les añadimos el ID de aplicación y la empresa en contexto, porque son dimensiones
    // imporantes a la hora de explotar datos en la aplicación
    event.params = event.params ?? {};
    event.params['applicationId'] = event.params['applicationId'] ?? this.appConfigurationService.getBootstrapData('applicationId');
    event.params['empresa'] = event.params['empresa'] ?? getInSafe(this.appContextService.getContextCompany(), x => x.cif, 'none');
  }

  /**
   * Establece la página actual, compatible con GA4 y UA
   */
  setCurrentPage(page: string): void {

    // Establecer ubicación en GA4
    // https://getfishtank.ca/blog/track-events-google-analytics-4-and-google-tag-manager
    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages?hl=es
    this.ga4codes.map(id => gtag('config', id,
      {
        'page_path': page,
        'send_page_view': false
      }));

    // Esto lo enviamos aquí explícitamente para que se incorpore la info de empresa e id de aplicación al evento
    this.pushEventWhithContext({action: 'page_view', params: {page_location: page}});
  }

  /**
   * Initializes service.
   */
  initialize(): void {
    this.appConfigurationService
      .isAppBootstrapped$()
      .pipe(
        take(1)
      )
      .subscribe(v => {
        this.settings = this.appConfigurationService.getAnalyticsConfig();
        this.onBootData();
      })
  }

  /**
   * Track events from external observables.
   */
  trackEvents(eventObs: Observable<EventData>): void {
    eventObs
      .pipe(
        takeUntil(this.componentDestroyed$.asObservable())
      )
      .subscribe((event) => {
        this.pushEventWhithContext(event);
      });
  }

  /**
   * To handle the OnBootData "event".
   */
  private onBootData(): void {

    if (isNullOrUndefined(this.settings)) {
      return;
    }
    this.initGoogleTagManager(this.settings);
    this.clickTrack();
    this.trackContext();
    this.trackNavigation();
  }

  /**
   * Initializes GTM API
   */
  private initGoogleTagManager(settings: GoogleAnalyticsClientSettings): void {
    this.ga4codes = settings.TrackingGTMCodes || [];
    this.ga4codes.map(id => this.googleTagManagerJSWrapper()(window, document, 'script', 'dataLayer', id));
    this.ga4codes.map(id => gtag('config', id));
  }

  /**
   * Loads GTM script.
   */
  private googleTagManagerJSWrapper(): (w: any, d: any, s: any, l: any, i: any) => void {
    /* eslint-disable */
    return (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })
    /* eslint-enable */
  }

  /**
   *
   * @private
   */
  private trackNavigation(): void {
    // Usamos solo la navegación de backend
    this.navigationService.lastResolvedNavigationRequest
      .subscribe((i) => {
        this.setCurrentPage(i.requestBackendPath);
      });
  }

  /**
   * Subscribes to AppContextService and emit events to GTM.
   */
  private trackContext(): void {
    this.appContextService
      .contextChanged
      .subscribe((data: { [id: string]: string }) => {
        this.pushEvent({
            action: 'cambio_contexto',
            params: {
              'empresa': getInSafe(data, x => x['selectedCompanyCIF'], null),
              'centro': getInSafe(data, x => x['selectedCenterLabel'], null)
            }
          }
        );
        // Esto permite establecer las user properties, que nos ayudan a agrupar a los usuarios
        gtag('set', 'user_properties', {
          'empresa': getInSafe(data, x => x['selectedCompanyCIF'], 'NONE')
        });
      });
  }

  /**
   * Tracks user clicks on UI.
   */
  clickTrack(): void {
    document.addEventListener('click', (e: MouseEvent) => {
      const target: HTMLElement = e.target as HTMLElement;
      const classList: string[] = !isNullOrUndefined(target.classList) ? Array.from(target.classList) : [];
      const dataset: DOMStringMap = target.dataset;

      // Enviamos automáticamente cualquier cosa en la que hago click que está decorada con gtmlabel
      if (isNullOrUndefined(dataset) || !dataset.hasOwnProperty('gtmlabel')) {
        return;
      }
      // Be careful creating too many new events since, at the moment, there is a maximum of 500 unique event names per GA4 property.
      // Once the limit is reached, Google says you won't be able to track any new events or delete any of the old ones. Por eso solo enviaremos
      // cosas decoradas con gtmLabel
      this.pushEventWhithContext(
        {
          // Longitud máxima de nombre de evento de 40 carácteres
          action: 'click:' + compactElementId(dataset['gtmlabel'], 34),
          params: {}
        });
    });
  }
}
