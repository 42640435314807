import {
  UserConfigurationOrigin,
  ViewUserConfiguration
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';

export class ActiveUserConfigurationClass {
  userConfiguration: ViewUserConfiguration;
  origin?: UserConfigurationOrigin;
  viewStorageIsEnabled: boolean

  constructor(userConfig: ViewUserConfiguration, viewStorageIsEnabled: boolean, origin?: UserConfigurationOrigin) {
    this.userConfiguration = userConfig;
    this.origin = origin;
    this.viewStorageIsEnabled = viewStorageIsEnabled;
  }

  currentUserConfigurationIsCutomized(): boolean {
    return this.viewStorageIsEnabled === true && this.origin !== null && this.origin !== UserConfigurationOrigin.DEFAULT
  }
}
