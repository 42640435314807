import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  declarations: [],
  imports: [
    GoogleMapsModule,
    CommonModule
  ],
  exports: [
    GoogleMapsModule
  ]
})
export class GeocodingModule {
}
