import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { MenuItemCompiledFrontend } from '../../../core/navigation/models/MenuItemCompiledFrontend.class';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationRequest } from '../../../core/navigation/models/NavigationRequest.class';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { getInSafe } from '../../utils/typescript.utils';

@Component({
  selector: 'app-generic-empty-page',
  templateUrl: './generic-empty-page.component.html',
  styleUrls: ['./generic-empty-page.component.scss']
})
export class GenericEmptyPageComponent extends DestroyableObjectTrait {

  content: string;
  links: [] = [];
  currentMenuItemCompiled: MenuItemCompiledFrontend;
  navigationRequest: NavigationRequest;
  routeDataJson: string;
  currentMenuItemCompiledJson: string;

  constructor(public route: ActivatedRoute,
              private router: Router,
              protected sanitizer: DomSanitizer,
              private navigationManager: NavigationService) {
    super();
    this.router.events
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(x => x instanceof NavigationEnd)
      ).subscribe(x => {
      this.router.navigated = false;
      this.CreatePageContent();
    });
  }

  private CreatePageContent(): void {
    this.routeDataJson = JSON.stringify(getInSafe(this.route.snapshot, x => x.data, {}), null, 4);
    this.content = getInSafe(this.route.snapshot.data, x => x['content'], 'NO CONTENT');
    this.links = getInSafe(this.route.snapshot.data, x => x['links'], []);
    this.navigationManager.getMenuItemCompiled(this.route.snapshot)
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((x: MenuItemCompiledFrontend) => {
        this.currentMenuItemCompiled = x;
        this.currentMenuItemCompiledJson = JSON.stringify(x, null, 4)
      });

    this.navigationManager.lastResolvedNavigationRequest
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((x: NavigationRequest) => {
        this.navigationRequest = x;
      });
  }

  /**
   * Get a safe-to-render html
   */
  public getSafeContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  /**
   * Stringify content.
   */
  stringify(obj: any): string {
    return JSON.stringify(obj);
  }
}
