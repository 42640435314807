import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RestTranslatorModule } from '../../../../core/translator/rest-translator.module';
import { ExposedFiltersModule } from '../../exposed-filters/exposed-filters.module';
import { ViewHeadersSharedModule } from '../view-headers-shared/view-headers-shared.module';
import { ViewMaterialBarComponent } from './components/view-material-bar.component';
import { ViewSearcherMaterialComponent } from './components/view-searcher-material/view-searcher-material.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { UtilsModule } from '../../../utils/utils.module';
import { FuseSharedModule } from '../../../../../@fuse/shared.module';
import { ViewFilterMaterialComponent } from './components/view-filter-material/view-filter-material.component';
import { ViewOperationsModule } from '../../operations/view-operations.module';
import { ViewLocalActionsMaterialComponent } from './components/view-local-actions-material/view-local-actions-material.component';
import { ViewColumnConfigurationMaterialComponent } from './components/view-column-configuration-material/view-column-configuration-material.component';
import { ViewUserConfigurationMaterialComponent } from './components/view-user-configuration-material/view-user-configuration-material.component';

@NgModule({
  imports: [
    CommonModule,
    ExposedFiltersModule,
    ViewHeadersSharedModule,
    RestTranslatorModule,
    TooltipModule.forRoot(),
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    UtilsModule,
    FuseSharedModule,
    ViewOperationsModule,
  ],
  declarations: [
    ViewMaterialBarComponent,
    ViewSearcherMaterialComponent,
    ViewFilterMaterialComponent,
    ViewColumnConfigurationMaterialComponent,
    ViewUserConfigurationMaterialComponent,
    ViewLocalActionsMaterialComponent
  ],
    exports: [
        ViewMaterialBarComponent,
        ViewUserConfigurationMaterialComponent,
    ]
})
export class ViewMaterialBarModule {
}
