import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../core/changedetector/changedetectoreference';
import { ViewSearcherComponent } from '../../../view-headers-shared/components/view-searcher.component';
import { ViewQuickSearch, ViewsQuickSearchUserConfiguration } from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../../list.service';
import { TranslatorService } from '../../../../../../core/translator/services/rest-translator.service';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'app-view-searcher-material',
  templateUrl: './view-searcher-material.component.html',
  styleUrls: ['./view-searcher-material.component.scss'],
  providers: [ChangedetectorReference]
})
export class ViewSearcherMaterialComponent extends ViewSearcherComponent {

  @Output() onFocus: EventEmitter<boolean> = new EventEmitter<boolean>()

  public isFocus: boolean = false;

  constructor(protected listComponentConfiguration: ListComponent2Service,
              protected changeDetector: ChangeDetectorRef,
              protected translatorService: TranslatorService) {
    super(listComponentConfiguration, changeDetector);
  }

  /**
   * Obtener la configuración de la búsqueda rápida
   */
  get quickSearchConfiguration(): ViewQuickSearch {
    return this.configuration.QuickSearch;
  }

  /**
   * The current views quick search user configuration
   */
  get quickSearchUserConfiguration(): ViewsQuickSearchUserConfiguration {
    return this.userConfiguration.Search;
  }

  detectChanges(): void {
    this.changeDetector.detectChanges();
  }

  public changeFocus(value: boolean): void {
    this.isFocus = value;
    this.onFocus.emit(value);
    this.detectChanges();
  }

  public changeSettings(value: boolean): void {
    this.isFocus = value;
    this.onFocus.emit(value);
    this.detectChanges();
  }

  getPlaceHolder(): string {
    const fields: string = this.quickSearchConfiguration.Fields
      .filter((i) => this.quickSearchUserConfiguration.Fields.includes(i.FieldId))
      .map((i) => i.Name)
      .join(' , ');

    return this.translatorService.get('Buscar por @fields', {'@fields': fields});
  }

  /**
   * En every check or uncheck of a field on the drop-down, search fields array
   * changes
   * @param event
   */
  inputChanged(event: any, fieldId: string): void {
    super.inputChanged(event, fieldId);
    this.detectChanges();
  }

  getFloatLabelValue(): FloatLabelType {
    return this.userConfiguration.Search?.SearchString !== undefined ? 'always' : 'auto';
  }
}
