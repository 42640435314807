import { ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { TimepickerComponent, TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { BsDatepickerConfig, BsDatepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ChangedetectorService } from '../../../../../core/changedetector/changedetector.service';
import { ChangedetectorReference } from '../../../../../core/changedetector/changedetectoreference';
import { FormElementDateTimePicker } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import {
  PrecisionToGranularity,
  UnixTimestampToUserDateFromOffset,
  UserDateToUnixTimestampFromOffset
} from '../../../../utils/date.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import * as moment from 'moment';
import { Moment } from 'moment-timezone/moment-timezone';
import { UtilsTypescript } from '../../../../utils/typescript.utils';
import {
  NgxDatepickerChangedetectionFixerDirective
} from '../../../../../sabentisutils/ngx-datepicker-changedetection-fixer.directive';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { caLocale } from 'ngx-bootstrap/locale';
import { takeUntil } from 'rxjs/operators';
// Sin esto sale error en idioma catalan
defineLocale('ca', caLocale);

@Component({
  selector: 'app-form-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatetimepickerComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => DatetimepickerComponent), multi: true},
    ChangedetectorReference
  ]
})
export class DatetimepickerComponent extends FrontendFormElementInput implements OnInit, OnDestroy {

  @ViewChild('timepicker', {static: true})
  timePickerComponentInstance: TimepickerComponent;

  @ViewChild(BsDatepickerDirective, {static: true})
  protected inputElementBsDatepicker: BsDatepickerDirective;

  @ViewChild(NgxDatepickerChangedetectionFixerDirective, {static: true})
  protected ngxDatepickerChangedetectionFixerDirective: NgxDatepickerChangedetectionFixerDirective;

  /**
   * El input value compuesto original
   * @protected
   */
  protected inputValueComposed: Date;

  /**
   * Entrada para el campo de fecha, esto está siempre en la TIMEZONE del navegador! Así que no hay que hacerle
   * caso al timezone, la fecha "vista" corresponde a la alimentada al control a la que se aplica el offset
   * del timezone del usuario que viene de backend
   */
  protected inputValueDate: Date;

  /**
   * Entrada para el campo de hora, mantenemos separado para gestionar los empty/valid/invalid
   *
   * @protected
   */
  protected inputValueTime: Date;

  /**
   * Configuración de del componente
   */
  timePickerConfig: TimepickerConfig;

  /**
   * Si es o no un valor válido
   */
  timePickerValueIsValid: boolean = true;

  /**
   * Si está o no inicializado
   */
  initialized: boolean;

  /**
   * La configuración no puede ser inyectada, ya que afecta a todos el aplicativo...
   */
  bsConfig: BsDatepickerConfig = new BsDatepickerConfig();

  get formElementDateTimePicker(): FormElementDateTimePicker {
    return this.config.FormElement as FormElementDateTimePicker;
  }

  /**
   * Get an instance of DatetimeComponent
   * @param formManagerService
   */
  constructor(protected formManager: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected cdReference: ChangedetectorReference,
              protected cdService: ChangedetectorService,
              protected translatorService: TranslatorService,
              protected bsLocaleService: BsLocaleService) {

    super(formManager, cdRef, translatorService);

    this.bsLocaleService.use(this.translatorService.getAngularLang());
  }

  /**
   * Overriden to consider datepicker initialization
   */
  propagateTouch(): void {
    if (!this.initialized) {
      return;
    }
    super.propagateTouch();
  }

  handleInitialized(): void {
    this.initialized = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.generateBsConfig();
    this.ngxDatepickerChangedetectionFixerDirective.bsDatepicker = this.inputElementBsDatepicker;

    // Este listener es para detectar cuando el input de entrada al campo de hora no es válido (i.e. 80:80)
    // podamos detectarlo
    this.timePickerComponentInstance
        .isValid
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(((isValid: boolean): void => {

          // Si está vacío, ya no se trata de válido o no válido, sino de empty-full
          if (UtilsTypescript.isNullOrWhitespace(this.timePickerComponentInstance.hours) && UtilsTypescript.isNullOrWhitespace(this.timePickerComponentInstance.minutes)) {
            this.timePickerComponentInstance.resetValidation();
            this.timePickerValueIsValid = true;
            return;
          }

          this.timePickerValueIsValid = isValid;

          const component: AbstractControl = this.formManagerService
              .getFormComponent(this.config.ClientPath);

          component.updateValueAndValidity({emitEvent: false, onlySelf: false});
        }).bind(this));
  }

  /**
   * Generate BS config
   */
  protected generateBsConfig(): void {
    this.bsConfig.containerClass = 'theme-green';
    this.bsConfig.showWeekNumbers = false;
    this.bsConfig.dateInputFormat = 'DD/MM/YYYY';
    this.bsConfig.maxDate = UnixTimestampToUserDateFromOffset(this.formElementDateTimePicker.MaxDateEffective, this.formElementDateTimePicker.TimeZoneInfo);
    this.bsConfig.minDate = UnixTimestampToUserDateFromOffset(this.formElementDateTimePicker.MinDateEffective, this.formElementDateTimePicker.TimeZoneInfo);
    this.timePickerConfig = new TimepickerConfig();
    this.timePickerConfig.max = UnixTimestampToUserDateFromOffset(this.formElementDateTimePicker.MaxDateEffective, this.formElementDateTimePicker.TimeZoneInfo);
    this.timePickerConfig.min = UnixTimestampToUserDateFromOffset(this.formElementDateTimePicker.MinDateEffective, this.formElementDateTimePicker.TimeZoneInfo);
  }

  /**
   * Al cambiar el input de fecha
   * @param value
   */
  set dateInput(value: Date) {
    if (!value) {
      this.inputValueDate = null;
    } else {
      if (!this.inputValueDate) {
        this.inputValueDate = new Date();
      }
      this.inputValueDate.setFullYear(value.getFullYear());

      // Primero se tiene que establecer el dia si en inputValueDate ya hay una fecha ya que
      // si esta fecha tiene el dia 31 y lo pasamos a un mes que no tiene 31 dias(antes de cambiar el dia) se produce un desbordamiento
      // y el setMonth "arregla" la fecha.
      this.inputValueDate.setDate(value.getDate());
      this.inputValueDate.setMonth(value.getMonth());
    }
    this.propagateChangeFromDateValue(this.inputValueDate, this.inputValueTime);
  }

  /**
   * Obtener el valor del input de fecha
   */
  get dateInput(): Date {
    return this.inputValueDate;
  }

  /**
   * Al cambiar el input de fecha
   * @param value
   */
  set timeInput(value: Date) {
    if (!value) {
      this.inputValueTime = null;
    } else {
      if (!this.inputValueTime) {
        this.inputValueTime = new Date();
      }
      this.inputValueTime.setHours(value.getHours());
      this.inputValueTime.setMinutes(value.getMinutes());
    }

    this.propagateChangeFromDateValue(this.inputValueDate, this.inputValueTime);
    this.detectChanges();
  }

  /**
   *
   * @param value
   * @protected
   */
  protected propagateChangeFromDateValue(valueDate: Date, valueTime: Date): void {
    if (!this.timePickerValueIsValid) {
      // Hacemos esto para indicar que el valor NO es válido! Ya que requiere emitir un valor
      // para poder lanzar la validación cuando el componente de formulario no es requerido
      // ver Validate() en formelementinput.class.ts
      // No pasa nada por propagar un valor inválido, porque nosotros mismo obligamos a no
      // pasar las validaciones
      this.propagateChange(Number.POSITIVE_INFINITY, false, false);
      this.inputValueComposed = new Date(Number.POSITIVE_INFINITY);
      return;
    }
    if (!valueDate || !valueTime) {
      // Si falta alguno de los dos... propagamos vacío si es que no estaba ya vacío de antes
      if (this.inputValueComposed != null) {
        this.propagateChange(null);
      }
      return;
    }
    // Componemos ambos
    const composedDate: Date = moment(valueDate).clone().toDate();
    composedDate.setMinutes(valueTime.getMinutes());
    composedDate.setHours(valueTime.getHours());
    if (moment(composedDate).format('YYYYMMDDHHmm') !== moment(this.inputValueComposed).format('YYYYMMDDHHmm')) {
      this.propagateChange(UserDateToUnixTimestampFromOffset(composedDate, this.formElementDateTimePicker.TimeZoneInfo), false, false);
    }
  }

  /**
   * Obtener el valor del input de fecha
   */
  get timeInput(): Date {
    return this.inputValueTime;
  }

  /**
   * This function is described on the ControlValueAccessor interfacte.
   * Is used by the Angular Form to set the value that comes from the server
   * @param value: value set by Angular Forms Manager
   */
  writeValue(value: number): void {
    if (!value) {
      this.inputValueDate = null;
      this.inputValueTime = null;
      this.inputValueComposed = null;
      this.detectChanges();
      return;
    }

    this.inputValueComposed = UnixTimestampToUserDateFromOffset(value, this.formElementDateTimePicker.TimeZoneInfo);
    this.inputValueDate = UnixTimestampToUserDateFromOffset(value, this.formElementDateTimePicker.TimeZoneInfo);
    this.inputValueTime = UnixTimestampToUserDateFromOffset(value, this.formElementDateTimePicker.TimeZoneInfo);
    this.detectChanges();
  }

  /**
   * Validation method for the control.
   * @param {AbstractControl} c
   */
  doValidate(c: AbstractControl): ValidationErrors {

    const errors: ValidationErrors = super.doValidate(c);

    // Internamente siempre funcionamos con esto...
    const unixTimestampDate: number = c.value;

    // Solo podemos tratar esto como un valor único, no podemos distinguir
    // hora/fecha puesto que debemos basar esta validacion únicamente
    // en el valor propagado
    if (unixTimestampDate && !isFinite(unixTimestampDate)) {
      errors['invalid-date'] = 'La fecha y hora introducida no es válida.';
    }

    return errors;
  }

  detectChanges(): void {
    this.cdRef.detectChanges();
  }

  getId(): string {
    return this.config.ClientId;
  }

  /**
   *
   * @param valueA
   * @param valueB
   */
  equalValues(valueA: any, valueB: any): boolean {
    const granularity: moment.unitOfTime.StartOf = PrecisionToGranularity(this.formElementDateTimePicker.Precision);
    return moment(valueA, 'X').isSame(moment(valueB, 'X'), granularity);
  }

  /**
   * @param valueA
   * @param valueB
   */
  compareValues(valueA: any, valueB: any): number {
    const granularity: moment.unitOfTime.StartOf = PrecisionToGranularity(this.formElementDateTimePicker.Precision);
    const momentA: Moment = moment(valueA, 'X');
    const momentB: Moment = moment(valueB, 'X');
    if (momentA.isSame(momentB, granularity)) {
      return 0;
    }
    if (momentA.isAfter(momentB, granularity)) {
      return 1;
    }
    return -1;
  }

  is24HourClock(): boolean {
    return this.formElementDateTimePicker.TwentyFourHourClock;
  }
}
