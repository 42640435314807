import { Directive, ElementRef, OnInit } from '@angular/core';

/**
 * Esta directiva permite que un elemento al que le pongamos el innerHtml
 * de manera dinámica (i.e. desde backend) pueda evaluar scripts dentro
 * de ese innerHtml.
 */
@Directive({selector: '[appRunScripts]'})
export class RunScriptsDirective implements OnInit {
  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    setTimeout(() => { // wait for DOM rendering
      this.reinsertScripts();
    });
  }

  reinsertScripts(): void {

    const scripts: HTMLScriptElement[] = <HTMLScriptElement[]>this.elementRef.nativeElement.getElementsByTagName('script');
    const scriptsInitialLength: number = scripts.length;

    let activeScript: Function = () => {
    };

    for (let i: number = scriptsInitialLength - 1; i >= 0; i--) {
      const nextScriptLoadCallback: Function = activeScript;
      activeScript = () => {
        const script: HTMLScriptElement = scripts[i];
        if (script.type === 'text/x-template') {
          return;
        }
        const scriptCopy: HTMLScriptElement = <HTMLScriptElement>document.createElement('script');
        scriptCopy.type = script.type ? script.type : 'text/javascript';
        if (script.innerHTML) {
          scriptCopy.innerHTML = script.innerHTML;
        } else if (script.src) {
          scriptCopy.onload = function (event: Event): void {
            nextScriptLoadCallback();
            console.log('Loaded remote script: ' + scriptCopy.src);
          };
          scriptCopy.src = script.src;
        }
        scriptCopy.async = false;
        script.parentNode.replaceChild(scriptCopy, script);
        // Load event will not fire with inline scripts
        if (script.innerHTML) {
          nextScriptLoadCallback();
        }
      }
    }

    activeScript();
  }
}
