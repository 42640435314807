import { Injectable, ViewContainerRef } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../core/authentication/auth.service';
import { NavigationService } from '../core/navigation/navigation.service';
import { SessionService } from '../core/services/ETG_SABENTISpro_Application_Core_session.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationStatusEnums } from '../core/models/ETG_SABENTISpro_Application_Core_models';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

/**
 * Este guard se pone en aquellas pantallas en las que de manera explícita
 * el usuario NO debe estar autenticado
 */
@Injectable()
export class IsLoggedOutGuard implements CanActivate {
  /**
   *  This attribute is used to specify the container where error notifications will be shown. This variable is set on app bootstrap.
   */
  public container: ViewContainerRef;

  constructor(private sessionService: SessionService,
              private router: Router,
              private auth: AuthService,
              private navigationService: NavigationService
  ) {
  }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Observable<boolean> {
    return this
        .auth
        .$isAuthenticated
        .pipe(
            switchMap((i) => {
              switch (i) {
                case AuthenticationStatusEnums.NoAuthenticated:
                  return of(true);
                  break;
                default:
                  return fromPromise(this.navigationService.goToHome().then((j) => false));
              }
            })
        );
  }
}
