import { Component, Input, ViewChild } from '@angular/core';

import { AbstractDecoupledModalComponent } from '../models/abstract-decoupled-modal.component';
import { FormDialogInterface } from '../models/modal-params.interface';

@Component({
  selector: 'app-decoupled-modal-form-dialog',
  templateUrl: './form-dialog.component.html',
})
export class FormDialogComponent extends AbstractDecoupledModalComponent {

  /**
   * Reference to the app-form-manager component.
   */
  @ViewChild('form', { static: true }) form: any;

  /**
   * @inheritdoc
   */
  @Input()
  data: FormDialogInterface;


  /*
   * Set the title to the modal
   */
  public setTitle(): void {
    return this.form.title;
  }
}
