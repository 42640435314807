<div id="myModal" [ngClass]="cssClasses" class="modal">
  <!-- Modal content -->
  <div class="spinner modal-dialog">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
    <b>{{this.message}}</b>
  </div>
</div>
