import { ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { TimepickerComponent, TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { FormElementTimePickerTimeSpan } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DateUtils } from '../../../../utils/date.utils';
import { isNullOrUndefined } from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-form-timepicker-timespan',
  templateUrl: './time-picker-timespan.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimePickerTimespanComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => TimePickerTimespanComponent), multi: true}
  ]
})
export class TimePickerTimespanComponent extends FrontendFormElementInput implements OnDestroy, OnInit {

  @ViewChild('timepicker', {static: true})
  formElementInstanceValue: TimepickerComponent;

  protected inputValueTime: Date;
  protected inputValueTimeString: string;

  timePickerConfig: TimepickerConfig;

  isValid: boolean;

  set timeInput(value: Date) {
    if (isNullOrUndefined(value)) {
      this.inputValueTime = null;
      this.inputValueTimeString = null;
    } else if (this.inputValueTime !== value) {
      this.inputValueTime = moment(value).toDate();
      this.inputValueTimeString = this.datePipe.transform(value, 'HH:mm:ss');
    }
    this.propagateChange(this.inputValueTimeString);
  }

  get timeInput(): Date {
    return this.inputValueTime;
  }

  getId(): string {
    return this.config.ClientId;
  }

  detectChanges(): void {
    this.cdRef.detectChanges();
  }

  /**
   * Get an instance of DatetimeComponent
   * @param formManagerService
   */
  constructor(protected formManager: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected localeService: TranslatorService,
              protected datePipe: DatePipe) {

    super(formManager, cdRef, localeService);

  }

  customPropagateTouch(): any {
    this.propagateTouch();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.generateBsConfig();
    this.formElementInstanceValue
        .isValid
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(((isValid: boolean): void => {
          this.isValid = isValid;

          const component: AbstractControl = this.formManagerService
              .getFormComponent(this.config.ClientPath);

          component.updateValueAndValidity({emitEvent: true, onlySelf: false});
        }).bind(this));
  }

  /**
   * This function is described on the ControlValueAccessor interfacte.
   * Is used by the Angular Form to set the value that comes from the server
   * @param value: value set by Angular Forms Manager
   */
  writeValue(value: string): void {

    // Aceptamos los tipos DateTimeOffset y TimeSpan en .NET
    if (!value) {
      this.inputValueTime = null;
      return;
    }
    this.inputValueTimeString = value;
    this.inputValueTime = DateUtils.ConvertTimeSpanToDate(value);
  }


  doValidate(c: AbstractControl): ValidationErrors {

    const errors: ValidationErrors = super.doValidate(c);

    if (this.isValid === false) {
      errors['invalid-time'] = 'La hora indicada no es válida.';
    }

    return errors;
  }

  /**
   * Generate BS config
   */
  protected generateBsConfig(): void {
    this.timePickerConfig = new TimepickerConfig();
    this.timePickerConfig.max = (this.config.FormElement as FormElementTimePickerTimeSpan).MaxTime as Date;
    this.timePickerConfig.min = (this.config.FormElement as FormElementTimePickerTimeSpan).MinTime as Date;
  }
}
