<input [id]="uniqueId" type="file" appFileSelect
       style="display:none;"
       (fileOutput)="handleFileDrop($event)"
       [multiple]="multiple"
       [accept]="getValidExtensions()"
       [disabled]="disabled">
<div class="well well-drop"
     *ngIf="enableDragAndDrop"
     [ngClass]="{'over-drop-zone': overControl, 'over-drop-error': overError }"
     appFileDrop
     (fileOver)="handleFileOver($event)"
     (fileDrop)="handleFileDrop($event)">
  <i class="fa fa-cloud" style="margin-left: -100px; font-size: 100px"></i>
  <p>{{ "Selecciona o arrastra aquí tus archivos." | t }}</p>
</div>

<ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>

<ng-template #buttonTemplate>

  <label [for]="uniqueId" class="o-btn__accent" [class.--disabled]="disabled">
    {{ buttonLabel || 'Cargar fichero' }}
  </label>
</ng-template>
