import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { gzip } from 'pako';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { AppConfigurationService } from '../../app.configuration.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { IClientOptions } from './interfaces/client-options.intefrace';
import { Guid } from 'guid-typescript';
import { filter, flatMap, map } from 'rxjs/operators';
import { backendTypeMatch } from '../../shared/utils/typescript.utils';
import { CoreFileDownload } from '../models/ETG_SABENTISpro_Application_Core_models';
import { IResultCollector } from '../commands/resultcollector.interface';
import { CommandService } from '../commands/command.service';
import { finalizeWithReason } from '../../utils/rxJsFinalizeWithReason';


/**
 * Handles communication with the backend
 */
@Injectable()
export class CommunicationService {

  /**
   *  This attribute is used to specify the container where error notifications will be shown. This variable is set on app bootstrap.
   */
  constructor(
      private http: HttpClient,
      private spinnerService: SpinnerService,
      private appConfigurationService: AppConfigurationService,
      private commandService: CommandService
  ) {
    // CoreFileDownload command
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreFileDownload.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreFileDownload, () => Promise<boolean>>)
        )
        .subscribe((next) => {
          const path: string = next.Argument.Url;
          next.AddResult(() => new Promise(() => window.open(this.generateFileUrl(path), '_blank')));
        });
  }

  delete(endpoint: string, query: any, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {
    return this.appConfigurationService
        .isAppConfigLoaded$()
        .pipe(
            flatMap(() => this.doDelete(endpoint, query, clientOpts)));
  }

  get(endpoint: string, query: any, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {
    return this.appConfigurationService
        .isAppConfigLoaded$()
        .pipe(flatMap(() => this.doGet(endpoint, query, clientOpts)));
  }

  put(endpoint: string, query: any, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {
    return this.appConfigurationService
        .isAppConfigLoaded$()
        .pipe(flatMap(() => this.doPut(endpoint, query, clientOpts)));
  }

  post(endpoint: string, query: any, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {
    return this.appConfigurationService
        .isAppConfigLoaded$()
        .pipe(
            flatMap(() => this.doPost(endpoint, query, clientOpts)));
  }

  patch(endpoint: string, query: any, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {
    return this.appConfigurationService
        .isAppConfigLoaded$()
        .pipe(flatMap(() => this.doPatch(endpoint, query, clientOpts)));
  }

  /**
   * This method is used to communicate with the WebApi specified endpoint with a DELETE verb to delete a resource.
   *
   * @param {string} endpoint is used to specify the endpoint of the communication
   * @param query
   * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
   * @returns {Observable<Object>} As any Angular HttpClient use of a verb, it returns an Observable of an Object, that is the body of the response
   */
  protected doDelete(endpoint: string, query: any, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {

    let params: HttpParams = new HttpParams();
    if (query != null) {
      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          params = params.append(key.toString(), query[key]);
        }
      }
    }

    let spinnerId: Guid;

    if (clientOpts.showSpinner) {
      spinnerId = this.spinnerService.showSpinner('Cargando...', 240000, 0, clientOpts.showSpinnerInmediately);
    }

    return this.http.delete(this.formatUriForBackend(endpoint), {params: params})
        .pipe(finalizeWithReason(() => {
          this.spinnerService.removeSpinner(spinnerId);
        }));
  }

  /**
   *
   * This method is used to communicate with the WebApi specified endpoint with a PATCH verb to modify parcially a resource.
   *
   * @param {string} endpoint is used to specify the endpoint of the communication
   * @param {Object} params
   * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
   * @returns {Observable<Object>} As any Angular HttpClient use of a verb, it returns an Observable of an Object, that is the body of the response
   */
  protected doPatch(endpoint: string, params: Object, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {

    let spinnerId: Guid;

    if (clientOpts.showSpinner) {
      spinnerId = this.spinnerService.showSpinner('Cargando...', 240000, 0, clientOpts.showSpinnerInmediately);
    }

    return this.http.patch(this.formatUriForBackend(endpoint), params)
        .pipe(finalizeWithReason(() => {
          this.spinnerService.removeSpinner(spinnerId);
        }));
  }

  /**
   *
   * This method is used to communicate with the WebApi specified endpoint with a PUT verb to edit or modify a resource.
   *
   * @param {string} endpoint is used to specify the endpoint of the communication
   * @param {Object} params
   * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
   * @returns {Observable<Object>} As any Angular HttpClient use of a verb, it returns an Observable of an Object, that is the body of the response
   */
  protected doPut(endpoint: string, params: Object, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {

    let spinnerId: Guid;

    if (clientOpts.showSpinner) {
      spinnerId = this.spinnerService.showSpinner('Cargando...', 240000, 0, clientOpts.showSpinnerInmediately);
    }

    return this.http.put(this.formatUriForBackend(endpoint), params)
        .pipe(finalizeWithReason(() => {
          this.spinnerService.removeSpinner(spinnerId);
        }));
  }

  /**
   *
   * This method is used to communicate with the WebApi specified endpoint with a GET verb to get and read a resource
   *
   * @param {string} endpoint is used to specify the endpoint of the communication
   * @param query
   * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
   * @returns {Observable<Object>} As any Angular HttpClient use of a verb, it returns an Observable of an Object, that is the body of the response
   */
  protected doGet(endpoint: string, query: any, clientOpts: IClientOptions = {showSpinner: true}): Observable<Object> {

    let params: HttpParams = new HttpParams();
    if (query != null) {
      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          // IMPORTANTE: No hace falta codificar para URI los argumentos porque... el HttpClient
          // de angular YA lo hace por nosotros. Antes había un bug por el que se hacía una
          // doble codificación en nuestro cliente de front, y luego otra doble decodificación en backend
          // lo que hacía que parecía que funcionara.
          // Ver: https://stackoverflow.com/questions/49438737/how-to-escape-angular-httpparams
          // By default, QueryEncoder encodes keys and values of parameters
          // using encodeURIComponent, and then un-encodes certain
          // characters that are allowed to be part of the query according
          // to IETF RFC 3986: https://www.rfc-editor.org/rfc/rfc3986.
          // If the set of allowed query characters is not acceptable for a particular
          // backend, QueryEncoder can be subclassed and provided as the 2nd argument
          // to URLSearchParams.
          if (isNullOrUndefined(query[key])) {
            // Los valores NULL en el query string son pasados en la URL
            // por angular como el literal "null" lo que es confuso, un valor
            // null equivale a la falta de arugmento, por eso no lo ponemos en
            // el query string.
            continue;
          }
          params = params.append(key.toString(), query[key]);
        }
      }
    }

    let spinnerId: Guid;

    if (clientOpts.showSpinner) {
      spinnerId = this.spinnerService.showSpinner('Cargando...', 240000, 0, clientOpts.showSpinnerInmediately);
    }

    return this.http.get(this.formatUriForBackend(endpoint), {params: params})
        .pipe(finalizeWithReason(() => {
          this.spinnerService.removeSpinner(spinnerId);
        }));
  }

  /**
   *
   * This method is used to communicate with the WebApi specified endpoint with a POST verb to create a resource
   *
   * @param {string} endpoint is used to specify the endpoint of the communication
   * @param {Object} body
   * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
   * @returns {Observable<Object>} As any Angular HttpClient use of a verb, it returns an Observable of an Object, that is the body of the response
   */
  protected doPost(endpoint: string, body: Object, clientOpts?: IClientOptions): Observable<Object> {

    let spinnerId: Guid;

    if (clientOpts.showSpinner) {
      spinnerId = this.spinnerService.showSpinner('Cargando...', 240000, 0, clientOpts.showSpinnerInmediately);
    }

    // Merge or replace default headers with request headers.
    const originalHeaders: any = isNullOrUndefined(clientOpts['headers']) ? new HttpHeaders() : clientOpts.headers;

    // Default options consider showspinner and deflate as default.
    clientOpts = this.mergeOptions(clientOpts);
    if (!clientOpts.mergeHeaders) {
      clientOpts.headers = originalHeaders;
    }

    if (spinnerId) {
      clientOpts.headers = clientOpts.headers ?? new HttpHeaders();
      clientOpts.headers = clientOpts.headers.set('meta-spinnerId', spinnerId.toString());
    }

    // Deflate request if defined on options.
    if (clientOpts.deflate) {
      const stringifiedBody: string = JSON.stringify(body);
      const deflatedData: any = gzip(stringifiedBody);
      body = new Blob([deflatedData]);
    }

    const options: object = clientOpts as object;
    delete (options['showSpinner']);
    delete (options['mergeHeaders']);
    delete (options['deflate']);

    return this.http.post(this.formatUriForBackend(endpoint), body, options)
        .pipe(finalizeWithReason(() => {
          this.spinnerService.removeSpinner(spinnerId);
        }));
  }

  /**
   * Merges default and request options.
   * @param {IClientOptions} options Options to merge with.
   */
  mergeOptions(options: IClientOptions): IClientOptions {
    const defaultOptions: IClientOptions = {
      showSpinner: true,
      deflate: true,
      headers: new HttpHeaders({
        'Content-Encoding': 'gzip',
        'Content-Type': 'application/json; charset=x-user-defined-binary'
      }),
      mergeHeaders: true,
    };
    if (options.headers) {
      const kopKeyOptions: string[] = options.headers.keys();
      let httpHeaderNew: HttpHeaders = defaultOptions.headers;
      kopKeyOptions.forEach(kop => {
        if (httpHeaderNew.has(kop)) {
          httpHeaderNew = httpHeaderNew.set(kop, options.headers.get(kop));
        } else {
          httpHeaderNew = httpHeaderNew.append(kop, options.headers.get(kop));
        }
      })
      options.headers = httpHeaderNew;
    }
    return merge(defaultOptions, options);
  }

  /**
   * @param {string} uri
   * @returns {string}
   */
  formatUriForBackend(uri: string): string {
    const apiMethodUrl: string = this.appConfigurationService.get('domain') + uri;
    return apiMethodUrl;
  }

  /**
   * Generate a valid frontend URI from a system resource locator
   * such as file://public/myfile.jpg
   *
   * @param {string} uri
   * @param {string} path
   * @returns {string}
   */
  generateFileUrl(path: string): string {
    if (isNullOrUndefined(path)) {
      return '';
    }
    if (path.indexOf('core-files/fileref?fileRef=') !== -1) {
      return path;
    }
    let url: string = 'core-files/fileref?fileRef=' + encodeURIComponent(path);
    url = this.formatUriForBackend(url);
    return url;
  }

  /**
   * Generate a valid frontend URI from a system resource locator
   * such as file://public/myfile.jpg
   *
   * @param {string} uri
   * @param {string} path
   * @returns {string}
   */
  generateThumbFileUrl(path: string, type: string): string {
    if (isNullOrUndefined(path)) {
      return '';
    }
    if (path.indexOf('core-files/thumbnail?fileRef=') !== -1) {
      return path;
    }
    let url: string = 'core-files/thumbnail?fileRef=' + encodeURIComponent(path);
    url = this.formatUriForBackend(url);
    return url;
  }
}
