import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';

/**
 * Utlizamos un Wrapper (como en todos los otros componentes de la API de formularios)
 * porque es necesario para poder poner la directiva formElementName a nuestro ControlValueAccesor.
 * Actualmente en angular 6 no existe la posibilidad de añadir directivas de manera dinámica
 * a un componente :(
 */
@Component({
  selector: 'app-form-date-wrapper',
  templateUrl: './datepicker-wrapper.component.html'
})
export class DatepickerWrapperComponent extends FrontendFormElementWrapper {
  @ViewChild('formInput', {static: true})
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
