<div *ngFor="let opt of config.options"
     class="c-radio-checkbox"
     [ngStyle]="opt.Styles"
     [ngClass]="opt.CssClasses">
  <input
      [id]="getOptionId(opt)"
      [attr.name]="getOptionId(opt)"
      [checked]="isChecked(opt)"
      type="checkbox"
      [value]="opt.Key"
      (change)="checkHandler($event)"
      (blur)="propagateTouch()"
      [disabled]="isDisabled">
  <label [for]="getOptionId(opt)" class="o-checkradio__label t-check">{{ opt.Name }}</label>
</div>
