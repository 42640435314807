/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class BaseSystemService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Obtiene datos diversos que necesita la aplicación en el arranque (Settings)
  *
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {{ [id: string]: any }}
  */
  getBootdata(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<{ [id: string]: any }>>
  {
    return this.generalService.get(`core-system-base/boot-data`, null, clientOpts) as Observable<WebServiceResponseTyped<{ [id: string]: any }>>;
  }
}
