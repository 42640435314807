import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { isArray } from 'app/shared/utils/typescript.utils';
import { SingleItemOperationEventData2 } from '../../events/singleitemoperation.eventdata';
import { ListComponent2Service } from '../../list.service';
import { asIterableObject, UtilsTypescript } from '../../../utils/typescript.utils';
import {
  IViewModeField,
  IViewModeUserConfigurationColumn, IViewResultCell,
  IViewsSingleItemOperation,
  ViewFieldSingleItemOperations,
  ViewFieldTypeEnum,
  ViewResultCellSingleItemOperation,
  ViewResultCellType,
  ViewResultRow
} from 'app/core/models/ETG_SABENTISpro_Application_Core_models';


/**
 * This component manages interaction for SingleItemOperations
 */
@Component({
  selector: 'app-singleitemoperations2',
  templateUrl: './singleitemoperations.component.html',
  styleUrls: ['./singleitemoperations.component.scss']
})
export class Singleitemoperations2Component implements OnInit {

  /**
   * The field configuration
   */
  @Input() row: ViewResultRow;

  /**
   * The field include info
   */
  @Input() viewModeUserColumn: IViewModeUserConfigurationColumn;

  /**
   * The untyped field value
   */
  fieldValue: IViewResultCell;

  /**
   * Emitter for single item operations
   */
  onSingleItemOperation: EventEmitter<SingleItemOperationEventData2>;

  /**
   * Single Item Operations ungrouped
   */
  operations: IViewsSingleItemOperation[] = [];

  /**
   * Get an instance of GridCellComponent
   *
   * @param listService
   */
  constructor(private listService: ListComponent2Service) {
  }

  /**
   * Obtiene las clases CSS para un single item operation de file
   * @param sio
   */
  getOperationClasses(sio: IViewsSingleItemOperation): string[] {
    let classes: string[] = ['o-btn__secondary--table'];

    if (isArray(sio.CssClasses)) {
      classes = [...classes, ...sio.CssClasses];
    }

    if (sio.Disabled === true) {
      classes.push('--disabled');
    }

    return classes;
  }

  /**
   * Initializes component identifying if it has sio and grouping it
   */
  ngOnInit(): void {
    const viewModeColumn: IViewModeField = this.listService.getViewModeColumnFromViewModeUserColumn(this.viewModeUserColumn);
    const sioCell: ViewResultCellSingleItemOperation = asIterableObject(this.row.Columns).find((i) => i.Type === ViewResultCellType.SingleItemOperation && i.Id === viewModeColumn.Id) as ViewResultCellSingleItemOperation;
    const sioField: ViewFieldSingleItemOperations = asIterableObject(this.listService.getConfiguration().Fields).find((i) => i.Type === ViewFieldTypeEnum.SingleItemOperations && i.Id === viewModeColumn.Id) as ViewFieldSingleItemOperations;

    const emptyCellOperations: boolean = UtilsTypescript.isNullOrUndefined(sioCell.SingleItemOperations);

    this.operations = emptyCellOperations ? Object.values(sioField.SingleItemOperations) : Object.values(sioCell.SingleItemOperations);

    // Ordenamos por weight
    this.operations = this.operations.sort((a, b) => a.Weight - b.Weight);
    this.onSingleItemOperation = this.listService.onSingleItemOperation;
  }

  /**
   * When one of this operations is triggered.
   *
   * @param {UIEvent} event
   * @param {ISingleItemOperation} operation
   */
  onClick(operation: IViewsSingleItemOperation): void {
    if (operation.Disabled === true) {
      return;
    }
    const eventData: SingleItemOperationEventData2 = new SingleItemOperationEventData2();
    eventData.row = this.row;
    eventData.action = operation.Id;
    eventData.operation = operation;
    this.onSingleItemOperation.next(eventData);
  }
}
