import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilsTypescript } from '../../../shared/utils/typescript.utils';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styles: [`
    .container-table {
      height: 100%;
      margin-top: 15%;
    }

    .container-table {
      display: table;
    }

    .vertical-center-row {
      display: table-cell;
      vertical-align: middle;
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .image-wrapper {
      margin-right: 32px;
      padding-bottom: 32px;
    }

    .flex-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mt16 {
      margin-top: 8px;
    }`]
})
export class ErrorPageComponent implements OnInit {

  ticketId: string;
  path: string;
  main: string;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const ticketId: string = sessionStorage.getItem('lastError.ticketId');
    const errorPath: string = sessionStorage.getItem('lastError.path');

    this.ticketId = ticketId;

    if (UtilsTypescript.isNullOrWhitespace(this.ticketId)) {
      this.ticketId = '0000000';
    }

    const hrefErrorPage: URL = new URL(window.location.href);
    hrefErrorPage.pathname = errorPath ?? '';
    hrefErrorPage.searchParams.forEach((i) => hrefErrorPage.searchParams.delete(i));
    this.path = hrefErrorPage.toString();

    const hrefMain: URL = new URL(window.location.href);
    hrefMain.pathname = '';
    hrefMain.searchParams.forEach((i) => hrefMain.searchParams.delete(i));
    this.main = hrefMain.toString();
  }

  /**
   * Go to a page and refresh
   *
   * @param disableErrorgeneric-form-page.component.ts:line 29
   */
  goToPageAndRefresh(disableError: boolean = false): void {
    const uri: URL = new URL(this.path);
    uri.searchParams.set('disableErrorPage', 'true');
    window.location.href = uri.toString();
  }
}
