import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { ICoreRenderTemplateFrontend } from './core-render-template.interface';
import { backendTypeMatch } from '../../shared/utils/typescript.utils';
import { CoreRenderTemplateSimpleMessageComponent } from './core-render-template-simple-message/core-render-template-simple-message.component';
import {
  CoreRenderTemplateSimpleMessageTemplate,
  ICoreRenderTemplate
} from '../models/ETG_SABENTISpro_Application_Core_models';

@Directive({
  selector: '[appCoreRenderTemplate]'
})
export class CoreRenderTemplateDirective extends DestroyableObjectTrait implements OnInit {

  /**
   *
   */
  @Input() data: ICoreRenderTemplate;

  /**
   *
   */
  component: ComponentRef<ICoreRenderTemplateFrontend>;

  /**
   * Get an instance of CoreRenderTemplateDirective
   */
  constructor(private resolver: ComponentFactoryResolver,
              private container: ViewContainerRef) {
    super();
  }

  /**
   *
   */
  ngOnInit(): void {
    // TODO: La idea de este módulo es poder tener pequeños DTO que se renderizan con plantillas de frontend, experimental
    if (backendTypeMatch(CoreRenderTemplateSimpleMessageTemplate.$type, this.data)) {
      this.component = this.container.createComponent(CoreRenderTemplateSimpleMessageComponent);
      this.component.instance.data = this.data;
    } else {
      throw new Error('Componente not supported.');
    }
  }
}
