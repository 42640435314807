import { Component, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FormElementTextArea, FormTextAreaResizePolicy } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-input-textarea-wrapper',
  templateUrl: './input-textarea-wrapper.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InputTextAreaWrapperComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput', { static: true })
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  /**
   * Returns text area class.
   * @returns {(string | number)[]}
   */
  getComponentClasses(): string[] {

    const result: string[] = super.getComponentClasses();

    switch ((this.config.FormElement as FormElementTextArea).ResizePolicy) {
      case FormTextAreaResizePolicy.All:
        result.push('c-textarea-all');
        break;
      case FormTextAreaResizePolicy.Horizontal:
        result.push('c-textarea-horizontal');
        break;
      case FormTextAreaResizePolicy.None:
        result.push('c-textarea-none');
        break;
      case FormTextAreaResizePolicy.Vertical:
        result.push('c-textarea-vertical');
        break;
    }

    return result;
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
