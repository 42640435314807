import { Injectable } from '@angular/core';
import { Locker } from '../models/Locker';

@Injectable()
export class LockerService {
  private static locks: {[identifier: string]: Locker} = {};

  static getLocker(bin: any, id: any): Locker {
    const identifier: string = bin + ':' + id;

    if (!this.locks[identifier]) {
      this.locks[identifier] = new Locker(bin, id);
    }
    return this.locks[identifier];
  }
}
