import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { SystemInfoDTO, WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class SystemService
{
  constructor(public generalService: CommunicationService) {}

  /**
   * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
   * @returns {string}
   */
  getSystemInfo(force: boolean, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<SystemInfoDTO>>
  {
    const params: any = {'force' : force};
    return this.generalService.get('core-system/systeminfo', params, clientOpts) as Observable<WebServiceResponseTyped<SystemInfoDTO>>;
  }
}
