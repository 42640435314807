import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatorService } from '../../../../core/translator/services/rest-translator.service';

/**
 * https://medium.com/front-dev/translate-your-matpaginator-with-ngx-translate-and-stay-reactive-4c7b145cae9
 */
export class PaginatorI18n extends MatPaginatorIntl {

  private rangePlaceholderWithTotal: string = '@index_start a @index_end de @total';
  private rangePlaceholderWithoutTotal: string = '@index_start a @index_end';

  constructor(private readonly translate: TranslatorService) {
    super();

    this.itemsPerPageLabel = 'Resultados por página:';
    this.nextPageLabel = 'Siguiente';
    this.previousPageLabel = 'Anterior';

    // No podemos garantizar que estará trducido para el primer renderizado... pero algo es algo.
    this.itemsPerPageLabel = this.translate.get(this.itemsPerPageLabel);
    this.nextPageLabel = this.translate.get(this.nextPageLabel);
    this.previousPageLabel = this.translate.get(this.previousPageLabel);
    this.rangePlaceholderWithTotal = this.translate.get(this.rangePlaceholderWithTotal);
    this.rangePlaceholderWithoutTotal = this.translate.get(this.rangePlaceholderWithoutTotal);

    this.getRangeLabel = this.doGetRangeLabel;
  }

  doGetRangeLabel(page: number, pageSize: number, length: number): string {
    const startIndex: number = page * pageSize;

    const endIndex: number = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    const reg: string = (startIndex + 1).toString();
    const reg2: string = (endIndex).toString();

    let to: string = null;
    let placeholder: string = null;

    length = Math.max(length, 0);
    if (length === LoadingLengthNumber) {
      to = '?';
      placeholder = this.rangePlaceholderWithTotal;
    } else if (length === NoLengthLengthNumber) {
      // Do nothing.
      placeholder = this.rangePlaceholderWithoutTotal;
    } else {
      to = length.toString();
      placeholder = this.rangePlaceholderWithTotal;
    }

    return this.translate.applyReplacements(placeholder, {'@index_start': reg, '@index_end': reg2, '@total': to});
  };
}

/**
 * Indica que el length se está "cargando"
 */
export const LoadingLengthNumber: number = 99999;

/**
 * Indica que no hay length, ni se está cargando.
 */
export const NoLengthLengthNumber: number = 999998;
