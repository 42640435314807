import { Component, ElementRef, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import { NGXGroupedSeriesChartComponent } from '../../ngx-grouped-series-chart.class';
import {
  BarChartDisplayOptions,
  GroupedHorizontalBarChart
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-grouped-horizontal-bar-chart',
  templateUrl: './grouped-horizontal-bar-chart.component.html',
  styleUrls: ['./grouped-horizontal-bar-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class GroupedHorizontalBarChartComponent extends NGXGroupedSeriesChartComponent<GroupedHorizontalBarChart, BarChartDisplayOptions> implements OnInit {

  constructor(protected cdReference: ChangedetectorReference,
              protected elementRef: ElementRef) {
    super(cdReference);
  }

  public initializeChart(): void {
    super.initializeChart();
  }

  GetViewValueOnInitializeChart(): [number, number] {
    const calculatedView: [number, number] = this.GetDefaultViewValueOnResize(this.elementRef);
    return [calculatedView[0], this.numSeries * 70];
  }

  GetViewValueOnResize(): [number, number] {
    const calculatedView: [number, number] = this.GetDefaultViewValueOnResize(this.elementRef);
    return [calculatedView[0], this.numSeries * 70];
  }
}
