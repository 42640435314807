import { ChangeDetectorRef, Component, HostBinding, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../formelementinput.class';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';

/**
 * Este componente no necesita el Control Value Accessor por que es únicamente de salida
 */
@Component({
  selector: 'app-formelement-simplemessage',
  templateUrl: './formelement-simple-message.component.html'
})
export class FormElementSimpleMessageComponent extends FrontendFormElementWrapper {

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }

  /**
   * FormElementSimpleMessage class constructor.
   */
  constructor(
    protected formManager: FormManagerService,
    protected sanitizer: DomSanitizer,
    protected cdRef: ChangeDetectorRef,
    @SkipSelf()
    protected cdRefParent: ChangeDetectorRef) {
    super(formManager, cdRef, cdRefParent);
  }

  /**
   * Get a safe-to-render html
   */
  public get data(): any {
    return this.config.Messages;
  }
}


