import { Component, Input } from '@angular/core';
import { ViewsSingleItemOperationImplementationTypes } from 'app/core/models/ETG_SABENTISpro_Application_Core_models';
import { NavigationService } from 'app/core/navigation/navigation.service';

import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { SingleItemOperationEventData2 } from 'app/shared/list_v2/events/singleitemoperation.eventdata';

@Component({
  selector: 'app-predefined-corrective-action',
  templateUrl: './predefined-corrective-action.component.html'
})
export class PredefinedCorrectiveActionComponent {
  @Input()
  listSettingsId = 'predefined';

  /**
   * Creates an instance of PredefinedCorrectiveActionComponent.
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   * @memberof AgentComponent
   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private navigationService: NavigationService) {
  }

  /**
   * Handler on SingleItemOperation
   *
   * @param {SingleItemOperationEventData2} event
   * @memberof PredefinedCorrectiveActionComponent
   */
  public onSingleItemOperation(event: SingleItemOperationEventData2): void {
    const option: string = (event.row.Metadata['ef_metadata_main']['PrimaryKeys']).id;
    if (event.action === ViewsSingleItemOperationImplementationTypes.Remove) {
      const url: string = this.router.url + '/' + option + '/details/delete';
      this.router.navigateByUrl(url);
    }

    if (event.action === ViewsSingleItemOperationImplementationTypes.Access
      || event.action === ViewsSingleItemOperationImplementationTypes.Edit) {
      const url: string = this.router.url + '/' + option + '/details';
      this.router.navigateByUrl(url);
    }
  }
}
