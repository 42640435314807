import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';

@Component({
  selector: 'app-chips-wrapper',
  templateUrl: './inputchips-wrapper.component.html',
})

export class InputchipsWrapperComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput', { static: true })
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
