import { Component, OnInit } from '@angular/core';
import { skip, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { AppContextService } from '../../context/context.service';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { GenericFormListFormPageSimpleArguments } from './GenericFormListFormPageSimpleArguments';

@Component({
  selector: 'app-generic-form-list-form-page-simple',
  templateUrl: './generic-form-list-form-page-simple.component.html',
  styleUrls: ['./generic-form-list-form-page-simple.component.scss']
})
export class GenericFormListFormPageSimpleComponent extends DestroyableObjectTrait implements OnInit {

  /**
   * Configuración del componente
   */
  configurationArguments: GenericFormListFormPageSimpleArguments;

  /**
   * Parametros que vienen de ruta o resolver, centralizados para todos los componentes internos
   */
  params: object;

  constructor(protected activatedRoute: ActivatedRoute,
              protected navigationService: NavigationService,
              protected contextService: AppContextService,
              protected route: ActivatedRoute) {
    super();

    this.activatedRoute
      .data
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data) => {
        if (!data && (data['componentConfig'] instanceof GenericFormListFormPageSimpleArguments)) {
          throw new Error('');
        }

        this.configurationArguments = data['componentConfig'] as GenericFormListFormPageSimpleArguments;
      });

    this.route.params
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data) => {
        this.updateParams();
      });

    this.contextService
      .contextChanged
      .pipe(
        takeUntil(this.componentDestroyed$),
        // Como es un replay subject, no nos interesa el primer emit.
        skip(1)
      )
      .subscribe(x => this.updateParams());
  }

  ngOnInit(): void {
    this.updateParams();
  }

  get paramsForm1(): object {
    return this.mapParamsToComponentParams(this.configurationArguments.form1ArgumentMapping);
  }

  get paramsList1(): object {
    return this.mapParamsToComponentParams(this.configurationArguments.list1ArgumentMapping);
  }

  get paramsForm2(): object {
    return this.mapParamsToComponentParams(this.configurationArguments.form2ArgumentMapping);
  }

  get paramsForm3(): object {
    return this.mapParamsToComponentParams(this.configurationArguments.form3ArgumentMapping);
  }

  /**
   *
   * @param mapping
   * @protected
   */
  protected mapParamsToComponentParams(mapping: object): object {
    if (!mapping) {
      return {};
    }
    const params: object = {};
    for (const p of Object.keys(mapping)) {
      params[p] = this.params[mapping[p]];
    }
    return params;
  }

  /**
   * Agregar todos los argumentos que vamos a necesitar
   *
   * @protected
   */
  protected captureRequiredArguments(): string[] {
    const result: { [name: string]: boolean } = {};
    if (this.configurationArguments.form1ArgumentMapping) {
      for (const p of Object.values(this.configurationArguments.form1ArgumentMapping)) {
        result[p] = true;
      }
    }
    if (this.configurationArguments.list1ArgumentMapping) {
      for (const p of Object.values(this.configurationArguments.list1ArgumentMapping)) {
        result[p] = true;
      }
    }
    if (this.configurationArguments.form2ArgumentMapping) {
      for (const p of Object.values(this.configurationArguments.form2ArgumentMapping)) {
        result[p] = true;
      }
    }
    if (this.configurationArguments.form3ArgumentMapping) {
      for (const p of Object.values(this.configurationArguments.form3ArgumentMapping)) {
        result[p] = true;
      }
    }
    return Object.keys(result);
  }

  /**
   * Actualiza la batería completa de parámetros que necesitan los componentes
   * @protected
   */
  protected updateParams(): void {

    this.params = {};

    const mapping: string[] = this.captureRequiredArguments();

    if (mapping) {
      for (const paramValueOrMap of mapping) {
        let paramName: string = paramValueOrMap;
        let isOptional: boolean = false;
        if (paramValueOrMap.startsWith('?')) {
          paramName = paramValueOrMap.substring(1, paramValueOrMap.length);
          isOptional = true;
        }
        const valueFromRoute: string = this.navigationService.findParmsForRouteByName(this.activatedRoute.snapshot, paramName);
        const valueFromResolver: string = this.route.snapshot.data[paramName];
        const valueFromQueryString: string = this.route.snapshot.queryParams[paramName];
        // Originalmente los parámetros on literales, o si cuadra con algún argumento, el valor del argumento. Para evitar
        // esta ambigüedad, terminar el parámetro con un ? indica que debe obtener el valor de algún sitio, pero no del
        // literal de defecto
        const defaultValue: string = isOptional ? null : paramName;
        this.params[paramValueOrMap] = valueFromRoute || valueFromResolver || valueFromQueryString || defaultValue;
      }
    }
  }
}
