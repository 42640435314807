<div [formGroup]="group">
  <fieldset [formGroupName]="config.name" [disabled]="this.fieldsetGroup.disabled">
    <legend class="o-legend" *ngIf="config.label">
      <button type="button" class="collapsible"
              *ngIf="fieldsetType === FormFieldsetType.Collapsible"
              [ngClass]="{collapsed: isCollapsed}"
              [title]="isCollapsed ? 'Pulse para expandir' : 'Pulse para contraer'"
              (click)="collapseToogle()">{{isCollapsed ? '+' : '-'}}</button>
      <ng-container *ngIf="fieldsetType === FormFieldsetType.Collapsible else defaultTitle">
        <span (click)="collapseToogle()" [ngClass]="{collapsed: isCollapsed}" style="cursor: pointer; padding: 5px"
              [title]="isCollapsed ? 'Pulse para expandir' : 'Pulse para contraer'">{{ config.label }}</span>
      </ng-container>
      <ng-template #defaultTitle>
        {{ config.label }}
      </ng-template>
      <i class="o-btn-tooltip-help" *ngIf="config.toolTip" [title]="config.toolTip" placement="auto">?</i>
    </legend>
    <app-formelementinputdescription [config]="config" [group]="group">
    </app-formelementinputdescription>
    <div class="c-fieldset__contents">
      <div>
        <div
            [ngClass]="{'flex-row': isBootstrapContainer(this.config.FormElement), 'flex-row-nocols': !isBootstrapContainer(this.config.FormElement)}"
            class="flex-row"
            *ngFor="let row of rows">
          <ng-container *ngFor="let field of row;"
                        appGenericInput
                        [config]="field"
                        [group]="fieldsetGroup"></ng-container>
        </div>
      </div>
    </div>
  </fieldset>
</div>
