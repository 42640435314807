<ng-container *ngIf="listComponentService.getConfiguration() && listComponentService.getUserConfiguration()">
  <div class="small-bar">
    <app-view-localactions></app-view-localactions>
    <div style="flex: 1; display: flex; justify-content: flex-end; align-items: flex-end;">
      <div style="flex: 1; display: flex; justify-content: flex-start;">
        <app-exposed-filters st></app-exposed-filters>
      </div>
      <!-- Configuration -->
      <app-view-operations (onVboOperationCompleted)="dispatchEventOnTaskCompleted($event)"> </app-view-operations>
      <button class="btn-config-table" (click)="showColumnConfiguration()">
        <span>Configurar tabla</span>
        <i class="m-fi m-fi--gear af af-gear"></i>
      </button>
    </div>
    <div>
      <app-view-filter-container></app-view-filter-container>
      <app-view-filter-tabs></app-view-filter-tabs>
    </div>
  </div>
</ng-container>

<!-- {{ "Configuración de columnas" | t }} -->
<ng-template #columnsModal>
  <app-view-configuration-selector (close)="modalRef.doClose()">
  </app-view-configuration-selector>
</ng-template>
