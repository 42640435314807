import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartFormComponent } from './chart-form/chart-form.component';
import { FormChartGeneratorDirective } from './form-chart-generator.directive';
import { FuseDirectivesModule } from '../../../../../../../../@fuse/directives/directives';
import { CoreModule } from '../../../../../../../core/core.module';


@NgModule({
  declarations: [ChartFormComponent, FormChartGeneratorDirective],
  exports: [ChartFormComponent],
    imports: [
        FuseDirectivesModule,
        CommonModule,
        CoreModule
    ]
})
export class ChartFiltersModule {
}
