import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { AppContextService } from './context.service';
import { ICenterContext } from './interfaces/center-context.interface';
import { ICompanyContext } from './interfaces/company-context.interface';
import { ContextAppSelectorComponent } from '../../layouts/prime-fuse/layout/components/navbar/context/context-app-selector.component';
import { takeUntil } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { SabentisService } from '../../core/services/ETG_SABENTISpro_Application_Modules_sabentis.service';
import { SabentisContext } from '../../core/models/ETG_SABENTISpro_Application_Modules_models';

@Component({
  selector: 'app-context',
  templateUrl: './context.component.html'
})
export class ContextComponent extends DestroyableObjectTrait implements OnInit, OnDestroy {

  @ViewChild('company', {static: true}) companyComponent: ContextAppSelectorComponent;
  @ViewChild('center', {static: true}) centerComponent: ContextAppSelectorComponent;

  private hasCompany = false;

  objCompany: ICompanyContext;

  objCenter: ICenterContext;

  canUnselectCompany = true;

  canUnselectCenter = true;

  /**
   * Creates a new instance of ContextComponent
   * @param {AppContextService} contextService: injected context service
   * @param {Router} router: injected router service to navigate to home when
   * context changes
   */
  constructor(
    private contextService: AppContextService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private sabentisService: SabentisService) {
    super();
  }

  /**
   * when component is initialized the context is set from
   * the contextService
   */
  ngOnInit(): void {

    this.contextService.contextChanged
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((next) => {
        const context: SabentisContext = Object.assign(new SabentisContext(), next);
        // TODO: Fix temporal hasta ver que pasa con la serialización de esto
        this.canUnselectCompany = context.editableCompany === true || (context.editableCompany as any) === 'True';
        this.canUnselectCenter = context.editableCenter === true || (context.editableCenter as any) === 'True';
        this.objCompany = this.contextService.getContextCompany();
        this.objCenter = this.contextService.getContextCenter();
        this.hasCompany = !!(this.objCompany);
        if (isNullOrUndefined(this.objCompany) && this.companyComponent) {
          this.companyComponent.enable();
        }
        if (isNullOrUndefined(this.objCenter) && this.centerComponent) {
          this.centerComponent.enable();
        }
        this.cdr.detectChanges();
      }, (error) => {
        console.error(error);
      });
  }

  /**
   * the selected company is set to the context service. Then, the app
   * navigates to home page
   * @param selCompany
   */
  selectedCompany(selCompany: ICompanyContext): void {
    let selectedCompanyId: string = null;
    if (selCompany && selCompany.id) {
      selectedCompanyId = selCompany.id;
    } else {
      // Nos adelantamos al feedback del servidor para mejorar la UX
      this.hasCompany = false;
    }
    this.sabentisService.getSetcompanyandcenterincontext(
      selectedCompanyId,
      null,
      {showSpinner: true, showSpinnerInmediately: true})
      .subscribe();
  }

  /**
   * the selected center is set to the context service. Then, the app
   * navigates to home page
   * @param selCenter
   */
  selectedCenter(selCenter: ICenterContext): void {
    let selectedCenterId: string = null;
    if (selCenter && selCenter.id) {
      selectedCenterId = selCenter.id;
    }
    let currentCompanyId: string = null;
    if (this.objCompany && this.objCompany.id) {
      currentCompanyId = this.objCompany.id;
    }
    // Es suficiente con pasar el centro
    this.sabentisService.getSetcompanyandcenterincontext(
      currentCompanyId,
      selectedCenterId,
      {showSpinner: true, showSpinnerInmediately: true})
      .subscribe();
  }

  /**
   * This method returns if center selector is visible.
   * @returns {boolean}
   */
  isCenterVisible(): boolean {
    return this.hasCompany && (this.contextService.GetContext<SabentisContext>()?.visibleCenter === true || (this.contextService.GetContext<SabentisContext>()?.visibleCenter as any) === 'True');
  }
}
