<div id="calendar" class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div class="header p-6 p-sm-12" [ngClass]="'month-' + (viewDate | date:'M')?.toLocaleLowerCase()">
    <div class="header-content" fxLayout="row" fxLayoutAlign="space-between">
      <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">

        <!--
          <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="logo-icon" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">today</mat-icon>
              <span class="logo-text" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">{{ configuration?.Title }}</span>
          </div>
        -->
        <!-- HEADER BOTTOM -->
        <div class="header-bottom" fxLayout="row" fxLayoutAlign="center center"
             [@animate]="{value:'*',params:{delay:'150ms'}}">

          <button mat-icon-button class="arrow"
                  mwlCalendarPreviousView
                  [view]="currentCalendarModeView"
                  [viewDate]="viewDate"
                  (viewDateChange)="changeDate($event)"
                  aria-label="Previous">
            <mat-icon>chevron_left</mat-icon>
          </button>

          <div class="mat-title m-0" *ngIf="currentCalendarModeView">
            {{ viewDate | calendarDate:(currentCalendarModeView + 'ViewTitle'): isoCode }}
          </div>

          <button mat-icon-button class="arrow"
                  mwlCalendarNextView
                  [view]="currentCalendarModeView"
                  [viewDate]="viewDate"
                  (viewDateChange)="changeDate($event)"
                  aria-label="Next">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <!-- / HEADER BOTTOM -->

        <!-- TOOLBAR -->
        <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
          <div title="Today">
            <button mat-icon-button
                    mwlCalendarToday
                    [viewDate]="viewDate"
                    (viewDateChange)="changeDate($event)">
              <mat-icon class="material-icons-outlined">today</mat-icon>
            </button>
          </div>
          <div *ngFor="let calendarMode of calendarViewModes" [title]="calendarMode.Name">
            <button
              mat-icon-button (click)="changeCalendarMode(calendarMode)"
              [attr.aria-label]="calendarMode.Name">
              <mat-icon class="material-icons-outlined">{{ calendarMode.Icon }}</mat-icon>
            </button>
          </div>
        </div>

      </div>
      <!-- / TOOLBAR -->


    </div>

    <!-- ADD EVENT BUTTON -->
    <!--<button mat-fab class="add-event-button mat-accent" aria-label="Add event"
            [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
        <mat-icon>add</mat-icon>
    </button>-->
    <!-- / ADD EVENT BUTTON -->
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}"
       *ngIf="events && viewDate">
    <div [ngSwitch]="currentCalendarModeView">
      <app-view-mode-calendar-month
        [configuration]="currentCalendarModeViewValue"
        *ngSwitchCase="'month'"
        [(viewDate)]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (dayClicked)="dayClicked($event)"
        (eventClicked)="eventClicked($event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        (beforeViewRender)="beforeMonthViewRender($event)">
      </app-view-mode-calendar-month>
      <app-view-mode-calendar-week
        [configuration]="currentCalendarModeViewValue"
        *ngSwitchCase="'week'"
        [(viewDate)]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="eventClicked($event)"
        (eventTimesChanged)="eventTimesChanged($event)">
      </app-view-mode-calendar-week>
      <app-view-mode-calendar-day
        [configuration]="currentCalendarModeViewValue"
        *ngSwitchCase="'day'"
        [(viewDate)]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="eventClicked($event)"
        (eventTimesChanged)="eventTimesChanged($event)">
      </app-view-mode-calendar-day>
    </div>
  </div>
  <!-- / CONTENT -->
</div>
