import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { UtilsTypescript } from '../../../../utils/typescript.utils';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangedetectorReference } from '../../../../../core/changedetector/changedetectoreference';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { ChangedetectorService } from '../../../../../core/changedetector/changedetector.service';
import {
  FormElementCheckboxesModal,
  IFormElementOption
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import {
  NgxSelectChangedetectionFixerDirective
} from '../../../../../sabentisutils/ngx-select-changedetection-fixer.directive';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-input-check-modal',
  templateUrl: './input-check-modal.component.html',
  styleUrls: ['./input-check-modal.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputCheckModalComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputCheckModalComponent), multi: true},
    ChangedetectorReference
  ]
})
export class InputCheckModalComponent extends FrontendFormElementInput implements OnInit, AfterViewInit {
  @ViewChild('dropdown', {static: true})
  dropdown: BsDropdownDirective;

  @ViewChild(NgxSelectChangedetectionFixerDirective, {static: true})
  protected ngxSelectChangedetectionFixerDirective: NgxSelectChangedetectionFixerDirective;

  public selectedValue: string[] = [];
  public selectAll: boolean = false;

  constructor(
      protected formManagerService: FormManagerService,
      protected cdRef: ChangeDetectorRef,
      protected cdReference: ChangedetectorReference,
      protected cdService: ChangedetectorService,
      protected translatorService: TranslatorService
  ) {
    super(formManagerService, cdRef, translatorService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.ngxSelectChangedetectionFixerDirective.bsDropDown = this.dropdown;
  }

  getFormElement(): FormElementCheckboxesModal {
    return this.config.FormElement as FormElementCheckboxesModal;
  }

  writeValue(value: string): void {
    if (isNullOrUndefined(value)) {
      this.selectedValue = new Array<string>();
      return;
    }
    this.selectedValue = UtilsTypescript.jsonClone(value);
  }

  /**
   * Indica si la opción está seleccionada
   * @param option
   */
  isChecked(option: IFormElementOption): boolean {
    if (isNullOrUndefined(this.selectedValue)) {
      return false;
    }
    return this.selectedValue.some(o => o === option.Key);
  }

  /**
   * Captura el evento de presionar el checkbox y añade o elimina la opción de la lista de seleccionados
   * @param e
   */
  checkHandler(e: any): void {
    if (e.target.checked) {
      this.selectedValue.push(e.target.value);
    } else {
      this.selectedValue.splice(this.selectedValue.findIndex(x => x === e.target.value), 1);
    }
    this.propagateChange(UtilsTypescript.jsonClone(this.selectedValue));
  }

  /**
   * Mostrar las opciones seleccionadas como texto separado por comas.
   */
  get selectedText(): string {
    if (isNullOrUndefined(this.config.options)) {
      return '';
    }
    const selectedValueObj: string[] = this.config.options.filter(o => this.selectedValue.includes(o.Key)).map(({Name}) => Name);
    if (!this.getFormElement().MaxItemsToShowInBar) {
      return selectedValueObj.join(', ');
    } else {
      let selectedText: string = selectedValueObj.slice(0, this.getFormElement().MaxItemsToShowInBar).join(', ');
      if (selectedValueObj.length > this.getFormElement().MaxItemsToShowInBar) {
        selectedText = selectedText.concat(',...');
      }
      return selectedText;
    }
  }

  onSelectAllChange(): void {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedValue = this.config.options.map(({Key}) => Key)
    }
    this.propagateChange(UtilsTypescript.jsonClone(this.selectedValue));
  }
}
