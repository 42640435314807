import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { ViewResult, ViewUserConfiguration } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ViewsinitializedEventdata } from '../events/viewsinitialized.eventdata';
import { ListComponent2Service } from '../list.service';
import { ViewsuserconfigchangedAction, ViewsuserconfigchangedEventdata } from '../viewsuserconfigchanged.eventdata';
import { IViewModeComponent } from './i-view-mode.component';
import { ViewModeComponentsConstants } from './view-mode-component.constants';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { filter, takeUntil } from 'rxjs/operators';

/**
 * The `ViewModeDirective` instantiates a custom result display component
 * to show the results for a view given a `ViewResult` object.
 */
@Directive({selector: '[appViewModeDirective]'})
export class ViewModeDirective extends DestroyableObjectTrait implements OnInit, OnDestroy {

  /**
   * ViewMode component to render.
   */
  component: ComponentRef<IViewModeComponent>;

  /**
   * `ViewResult` data to render.
   */
  protected _ViewResultData: ViewResult;

  /**
   * String with the name of the current view mode to display on the screen.
   */
  protected currentViewModeType: string = null;

  /**
   * `ViewModeDirective` class constructor.
   *
   * @param {ComponentFactoryResolver} resolver Component factory resolver.
   * @param {ViewContainerRef} container Container reference.
   * @param {ListComponent2Service} listComponentConfiguration  View component service.
   */
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private listComponentConfiguration: ListComponent2Service) {
    super();
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.listComponentConfiguration
      .viewIntialized
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        (next: ViewsinitializedEventdata) => {
          this.CreateComponent(next.userConfiguration)
        });

    this.listComponentConfiguration
      .userConfigurationChanged
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((next) => next.refreshAction === ViewsuserconfigchangedAction.ViewModeChange)
      )
      .subscribe(
        (next: ViewsuserconfigchangedEventdata) => {
          this.CreateComponent(next.userConfiguration)
        });
  }

  /**
   * Return a `ViewResult` object with the data to render.
   */
  get ViewResultData(): ViewResult {
    return this._ViewResultData;
  }

  /**
   * Setter to input the view result data and component result data.
   */
  @Input() set ViewResultData(value: ViewResult) {
    this._ViewResultData = value;
    if (this.component
      && this.component.instance
      && this._ViewResultData) {
      this.component.instance.data = this._ViewResultData.Results;
    }
  }

  /**
   * Given a `ViewUserConfiguration` the `CreateComponent` method instantiates
   * a custom view mode component and displays it on the container.
   *
   * @param {ViewUserConfiguration} userConfiguration User configuration.
   */
  private CreateComponent(userConfiguration: ViewUserConfiguration): void {

    if (isNullOrUndefined(userConfiguration)) {
      return;
    }

    if (isNullOrUndefined(userConfiguration.CurrentViewMode.$type)) {
      throw new Error('User Configuration must have a ViewMode Type')
    }

    const selectedViewModeType: string = userConfiguration.CurrentViewMode.$type;

    if (this.currentViewModeType !== selectedViewModeType) {
      this.currentViewModeType = selectedViewModeType;
      if (!isNullOrUndefined(this.component)) {
        this.container.clear();
      }

      this.component = this.container.createComponent(ViewModeComponentsConstants[this.currentViewModeType]);

      this.component.instance.initialize();

      if (!isNullOrUndefined(this._ViewResultData)) {
        this.component.instance.data = this._ViewResultData.Results;
      }
    }
  }
}
