/**
 * Authentication service.
 */
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { ChangedetectorService } from '../changedetector/changedetector.service';
import { CommandService } from '../commands/command.service';
import { Injectable, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { IPersonContext } from '../../shared/context/interfaces/person-context.interface';
import { Router, UrlTree } from '@angular/router';
import { AppConfigurationService } from '../../app.configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationStatusEnums, ISessionInfo } from '../models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined, nameof } from '../../shared/utils/typescript.utils';
import { SessionstateService } from '../sessionstate/sessionstate.service';

/**
 * EL funcionamiento de este servicio es muy sencillo, realmente más que AUTH, es un servicio
 * de gestión del estado de la aplicación (sesión).
 *
 * Si se modifica el estado de la aplicación en el servidor (contexto, sesión, autenticación, etc.)
 * éste devuelve
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService extends DestroyableObjectTrait implements OnDestroy {


  public redirectAfterLogin: UrlTree;

  private readonly selectedPerson: ReplaySubject<IPersonContext> = new ReplaySubject<IPersonContext>(1);
  private readonly isAuthenticated: ReplaySubject<AuthenticationStatusEnums> = new ReplaySubject<AuthenticationStatusEnums>(1);

  /**
   * Observable que se emite al cambiar el estado de autentiación
   * @private
   */
  public get $isAuthenticated(): Observable<AuthenticationStatusEnums> {
    return this.isAuthenticated;
  }

  /**
   * Observable que se emite al cambiar la persona seleccionada
   * @private
   */
  public get $selectedPerson(): Observable<IPersonContext> {
    return this.selectedPerson;
  }

  /**
   * Ctor of Auth Service
   * @param {Router} router
   * @param {HttpClient} http
   * @param {AppConfigurationService} appConfigurationService
   */
  constructor(
      private router: Router,
      private http: HttpClient,
      private appConfigurationService: AppConfigurationService,
      private cdService: ChangedetectorService,
      private commandService: CommandService,
      private sessionStateService: SessionstateService,
      @Optional() @SkipSelf() parentModule?: AuthService,
  ) {

    super();

    if (parentModule) {
      throw new Error(
          'AuthService is already loaded. Import it in the AppModule only.');
    }

    console.debug('Authentication service started.')

    this.sessionStateService
        .$sessionDataPropertyChanged<ISessionInfo>([nameof<ISessionInfo>('AuthenticationStatus')])
        .subscribe((sessionData) => {
          const isAuthenticated: AuthenticationStatusEnums = sessionData['AuthenticationStatus'] ?? AuthenticationStatusEnums.NoAuthenticated;
          this.isAuthenticated.next(isAuthenticated);
        });

    this.sessionStateService
        .$sessionDataPropertyChanged<ISessionInfo>([nameof<ISessionInfo>('PersonId')])
        .pipe(
            filter((i) => !isNullOrUndefined(i['PersonId']))
        )
        .subscribe((sessionData) => {
          const selectedPerson: IPersonContext = {
            id: sessionData.PersonId,
            name: sessionData.PersonFullName,
            mail: sessionData['PersonMail'],
            groups: sessionData.Groups,
            PersonFullName: sessionData.PersonFullName,
            Identification: sessionData.Identification
          };
          this.selectedPerson.next(selectedPerson);
        });
  }
}
