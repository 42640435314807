import { NgModule } from '@angular/core';
import { DOCUMENT_TOKEN, DomReferenceProviderService, WINDOW_TOKEN } from './providers/dom-reference-provider.service';

import { ClickOutsideDirective } from './click-outside.directive';
import { KeysPipe } from './keys.pipe';
import { KeyedDictionaryPipe } from './pipes/KeyedDictionaryPipe.pipe';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    KeysPipe,
    KeyedDictionaryPipe
  ],
  exports: [
    ClickOutsideDirective,
    KeysPipe,
    KeyedDictionaryPipe
  ],
  providers: [
    {provide: WINDOW_TOKEN, useValue: window},
    {provide: DOCUMENT_TOKEN, useValue: document},
    DomReferenceProviderService
  ]
})
export class UtilsModule {
}
