import { ChangeDetectorRef, Component, SkipSelf } from '@angular/core';

import { FrontendFormElementInput } from '../form/form-components/formelementinput.class';
import { FormManagerService } from '../form/form-manager/form-manager.service';
import { FrontendFormElementWrapper } from '../form/form-components/formelementwrapper.class';
import { DynamicComponent } from '../custom-component-factory/type-manager.decorator';

@DynamicComponent('methodology-insht')
@Component({
  selector: 'app-methodology-insht',
  templateUrl: 'methodology-INSHT.component.html',
  styleUrls: ['./methodology.component.scss']
})
export class MethodologyINSHTComponent extends FrontendFormElementWrapper {

  constructor(formManagerService: FormManagerService,
              cdRef: ChangeDetectorRef,
              @SkipSelf() cdRefParent: ChangeDetectorRef) {
    super(formManagerService, cdRef, cdRefParent);
  }


  /**
   * formElementInstance
   */
  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported for a fieldset component.');
  }
}
