import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RestTranslatorModule } from '../../core/translator/rest-translator.module';
import { SharedModule } from '../../shared/shared.module';
import {
  PredefinedCorrectiveActionDetailsComponent,
} from './predefined-corrective-action-details/predefined-corrective-action-details.component';
import { PredefinedCorrectiveActionComponent } from './predefined-corrective-action.component';
import { PredefinedRoutingModule } from './predefined-routing.module';
import { PredefinedCorrectiveActionDeleteComponent } from './predefined-corrective-action-delete/predefined-corrective-action-delete.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PredefinedRoutingModule,
    RestTranslatorModule
  ],
  declarations: [
    PredefinedCorrectiveActionComponent,
    PredefinedCorrectiveActionDetailsComponent,
    PredefinedCorrectiveActionDeleteComponent
  ],
  exports: [
  ]
})
export class PredefinedModule { }
