import { Component, OnInit } from '@angular/core';

import { IViewsLocalAction, ViewsLocalActionSimple } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../list.service';
import { LocalActionEventData2 } from '../../../events/localaction.eventdata';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { asIterableObject, backendTypeMatch } from '../../../../utils/typescript.utils';

/**
 * Los local actions son botones que NO DEPENDEN DE NADA DE LO QUE
 * HAYA EN EL LISTADO. Deberían ir en la navegació o algo así...
 */
@Component({
  selector: 'app-view-localactions',
  templateUrl: './view-localactions.component.html'
})
export class ViewLocalActionsComponent implements OnInit {

  localActions: { [key: string]: IViewsLocalAction } = {};

  /**
   * Get an instance of ListLocalActionsComponent
   *
   * @param {ListComponentService} listComponentService
   */
  constructor(protected listComponentService: ListComponent2Service) {
  }

  /**
   * Init handler
   */
  ngOnInit(): void {
    this.localActions = isNullOrUndefined(this.listComponentService.getConfiguration().LocalActions) ? {} : this.listComponentService.getConfiguration().LocalActions;
  }

  /**
   * Called when a local action is clicked
   *
   * @param event
   * @param {string} key
   * @param {ILocalAction} localAction
   */
  localActionClicked(event: UIEvent, key: string, localAction: IViewsLocalAction): void {
    const localActionEvent: LocalActionEventData2 = new LocalActionEventData2();
    localActionEvent.action = key;
    localActionEvent.data = localAction;
    this.listComponentService.onLocalAction.emit(localActionEvent);
  }

  /**
   * Get the classes for the component
   *
   * @param i
   */
  getClass(i: any): string[] {
    // TODO: Esto está aqúi hardcodeado hasta hacer la migración de estilos
    // TODO: Revisar y remover, los estilos que aqui estaban ya no se necesitan para este boton.
    let classes: string[] = [];
    if (this.localActions[i].CssClasses) {
      classes = [...classes, ...this.localActions[i].CssClasses];
    } else {
      classes = [...classes, ...['default']];
    }
    return classes;
  }

  getLocalActionSimple(key: string): ViewsLocalActionSimple {
    const action: IViewsLocalAction = this.localActions[key];
    if (backendTypeMatch(ViewsLocalActionSimple.$type, action)) {
      return action as ViewsLocalActionSimple;
    }
    return null;
  }

  getLocalActionsIterable(): IViewsLocalAction[] {
    return asIterableObject(this.localActions);
  }
}
