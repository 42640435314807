import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ViewConfigurationComponent } from './configuration-view/view-configuration.component';
import { ConfigurationDetailsComponent } from './configuration-details/configuration-details.component';

/**
 * Const routes where the routes´s module
 * are declared
 */
const routes: Routes = [
  {
    path: 'cac',
    data: { breadcrumb: 'parametrics.configuration' },
    children: [
      { path: '', component: ViewConfigurationComponent, pathMatch: 'full', data: { mapping: 'parametrics.configuration' } },
      { path: 'add', component: ConfigurationDetailsComponent, data: { mapping: 'parametrics.configuration.add' } },
      {
        path: ':machinename', children: [
          {
            path: ':configurationtype', children: [
              { path: 'details', component: ConfigurationDetailsComponent, data: { mapping: 'parametrics.configuration.details' } }
            ]
          }
        ]
      }
    ]
  }
]

/**
 * Module ConfigurationRouting where configure
 * the routing for the module of configurations.
 */
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
