import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import * as jquery from 'jquery';
import { UtilsUrl } from './utils-url';

/**
 * Esta directiva sirve para convertir en enlaces
 * globales todos los enlaces locales que se encuentren en el DOM interno.
 */
@Directive({selector: '[appGlobalizeUrls]'})
export class GlobalizeUrlsDirective implements OnInit {

  /**
   * Para activar o desactivar el comportamiento
   */
  @Input() appGlobalizeUrls: boolean;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (!this.appGlobalizeUrls) {
      return;
    }

    setTimeout(() => { // wait for DOM rendering
      this.globalizeUrls();
    });
  }

  globalizeUrls(): void {
    const html: any = jquery(this.elementRef.nativeElement);
    html.find('[href]').attr('href', function (i, origValue) {
      if (!UtilsUrl.IsGlobalUrl(origValue)) {
        if (!origValue.startsWith('/')) {
          origValue = '/' + origValue;
        }
        return window.location.origin + origValue;
      }
      return origValue;
    });
  }
}
