import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '../../types';
import { DecoupledModalBridgeService } from '../../../app/shared/decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../app/shared/decoupled-modal/models/decoupled-modal-bridge.interface';
import {
  NavigationShortcutsComponent
} from '../../../app/layouts/prime-fuse/components/navigation-shortcuts/navigation-shortcuts.component';
import {
  DtoFrontendModalSize,
  DtoFrontendModalType
} from '../../../app/core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'fuse-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
  @Input()
  layout = 'vertical';

  @Input()
  navigation: FuseNavigationItem[];

  public itemNavigation: FuseNavigationItem = {
    type: 'item',
    sabentisMenuItemCompiledFrontend: null,
    id: 'quicksearch',
    title: 'Búsqueda rápida (Ctrl + K)',
    translate: 'Búsqueda rápida (Ctrl + K)',
    icon: 'search',
    function: () => {
      this.openQuickFind()
    }
  };

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   * @param _dmb
   */
  constructor(
      private _changeDetectorRef: ChangeDetectorRef,
      private _fuseNavigationService: FuseNavigationService,
      private _dmb: DecoupledModalBridgeService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Load the navigation either from the input or from the service
    this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

    // Subscribe to the current navigation changes
    this._fuseNavigationService.onNavigationChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {

          // Load the navigation
          this.navigation = this._fuseNavigationService.getCurrentNavigation();

          // Mark for check
          this._changeDetectorRef.markForCheck();
        });

    // Subscribe to navigation item
    merge(
        this._fuseNavigationService.onNavigationItemAdded,
        this._fuseNavigationService.onNavigationItemUpdated,
        this._fuseNavigationService.onNavigationItemRemoved
    ).pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {

          // Mark for check
          this._changeDetectorRef.markForCheck();
        });
  }

  public getNavigation(): FuseNavigationItem[] {
    if (!this.navigation) {
      return [];
    }

    return this.navigation;
  }

  public openQuickFind(): void {
    const ref: ModalReference<unknown> = this._dmb.showComponent(
        NavigationShortcutsComponent,
        {ModalType: DtoFrontendModalType.Modal, ModalSize: DtoFrontendModalSize.Large, Title: 'Búsqueda rápida'},
    );

    ref.close
        .pipe(
            filter((i) => i === true)
        )
        .subscribe((x: boolean) => {
        });
  }
}
