import { Component } from '@angular/core';

/**
 * UserListComponent: component with the list of users
 */
@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
})
export class MappingComponent {
  /**
   * Creates an instance of MappingComponent.
   * @memberof MappingComponent
   */
  constructor() {}
}
