import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockerService } from './services/locker.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [LockerService]
})
export class LockerModule {
  static forRoot(): ModuleWithProviders<LockerModule> {
    return {
      ngModule: LockerModule,
      providers: [
        LockerService
      ]
    }
  }
}
