import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

/**
 * Returns an injection token to get the `window` object.
 *
 * Also used for object mocking on Unit Testing.
 */
export const WINDOW_TOKEN: InjectionToken<Window> = new InjectionToken<Window>('WindowToken');

/**
 * Returns an injection token to get the `document` object.
 *
 * Also used for object mocking on Unit Testing.
 */
export const DOCUMENT_TOKEN: InjectionToken<Document> = new InjectionToken<Document>('DocumentToken');

/**
 * Abstract DomReference class used for unit testing mocking.
 */
export abstract class DomReference {

  /**
   * Get the `window` reference.
   */
  get windowRef(): Window {
    throw new Error('Not implemented.')
  }

  /**
   * Get the `document` reference.
   */
  get documentRef(): Document {
    throw new Error('Not implemented.')
  }

}

@Injectable()
export class DomReferenceProviderService extends DomReference {

  /**
   * Subjects that emits the current injected `window` so others modules can
   * alter it.
   */
  private windowAlteration$: Subject<Window> = new Subject<Window>();

  /**
   * Subjects that emits the current injected `document` so others modules can
   * alter it.
   */
  private documentAlteration$: Subject<Document> = new Subject<Document>();

  /**
   * Local reference to global `window` object.
   */
  private window: Window;

  /**
   * Local reference to global `document` object.
   */
  private document: Document;

  constructor(
    @Inject(WINDOW_TOKEN) private _window: Window,
    @Inject(DOCUMENT_TOKEN) private _document: Document,
  ) {
    super();

    /**
     * When the global object `window` is not defined then use the injected one.
     */
    this.window = !isNullOrUndefined(_window) ? _window : {} as Window;
    /**
     * When the global object `document` is not defined then use the injected one.
     */
    this.document = !isNullOrUndefined(_document) ? _document : {} as Document;
  }

  /**
   * Get the `window` reference.
   */
  get windowRef(): Window {
    return this.getNativeWindow();
  }

  /**
   * Get the `document` reference.
   */
  get documentRef(): Document {
    return this.getNativeDocument();
  }

  /**
   * Returns an instance of `Window` that could be modified or injected with
   * custom code properties.
   */
  private getNativeWindow(): Window {
    this.windowAlteration$.next(this.window);
    return this.window;
  }

  /**
   * Returns an instance of `Document` that could be modified or injected with
   * custom code properties.
   */
  private getNativeDocument(): Document {
    this.documentAlteration$.next(this.document);
    return this.document;
  }
}

