import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Output() appClickOutside: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private eref: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  clickedOutside(target: any): void {
    if (!this.eref.nativeElement.contains(target)) {
      this.appClickOutside.emit(true);
    }
  }
}
