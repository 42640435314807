import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ListComponent2Service } from '../../../list.service';
import {
  ViewsPredefinedFilter,
  ViewsPredefinedFilterSet
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { getInSafe } from '../../../../utils/typescript.utils';
import { takeUntil } from 'rxjs/operators';
import { ActiveUserConfigurationClass } from '../../../activeUserConfigurationClass';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';

@Component({
  selector: 'app-view-filter-tabs',
  templateUrl: './view-filter-tabs.component.html',
})
export class ViewFilterTabsComponent extends DestroyableObjectTrait implements OnInit {

  /**
   * ViewFilterTabsComponent class constructor.
   * @param {ListComponent2Service} viewService
   */
  constructor(private viewService: ListComponent2Service, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.viewService
        .activeUserConfiguration
        .pipe(
            takeUntil(this.componentDestroyed$),
        )
        .subscribe((next: ActiveUserConfigurationClass) => {
          if (next.userConfiguration) {
            this.cdr.detectChanges();
          }
        });
  }

  /**
   * Returns a boolean indicating if there is a selected filter.
   */
  get anyFilters(): boolean {
    return this.getPredefinedFilters().length > 0;
  }

  /**
   * Returns an array with the predefined filters for the view.
   */
  getPredefinedFilters(): ViewsPredefinedFilter[] {
    const filterConf: ViewsPredefinedFilterSet = this.viewService.getPredefinedFiltersConfiguration();
    const filters: any = getInSafe(filterConf, (f) => f.Filters, {});
    return Object.keys(filters)
        .map(k => filters[k]);
  }

  /**
   * Returns a string array with the classes for a filter.
   */
  getFilterClass(f: ViewsPredefinedFilter): string[] {
    return [this.viewService.isPredefinedFilterActivated(f) ? 'o-filter-tab--active' : 'o-filter-tab'];
  }

  /**
   * De/Activates a filter.
   */
  setFilter(f: ViewsPredefinedFilter): void {
    // Reset the page number if the configuration changes
    this.viewService.setPaginatorFirstPage();

    this.viewService.togglePredefinedFilter(f);
  }
}
