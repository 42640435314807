import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LayoutConstants } from '../core/layout-manager/constants/layout-variables.class';
import { LayoutGuard } from './layout.guard';
import { GenericEmptyPageComponent } from '../shared/generic-pages/generic-empty-page/generic-empty-page.component';
import { SharedModule } from '../shared/shared.module';
import { DynamicRoutesGenerator } from '../core/navigation/dynamic-routes-generator.service';

/**
 * Los layouts no tienen lazy loading porque si hacemos lazy
 * loading no podemos inyectar rutas dinámicas que vengan de backend
 * en el arranque.
 */
const routes: Routes = [
  {
    path: '',
    data: {layouts: true},
    children: [
      {
        path: '', redirectTo: 'fuse', pathMatch: 'full'
      },
      {
        path: 'fuse',
        loadChildren: () => import('./prime-fuse/prime-fuse.module').then(m => m.PrimeFuseModule),
        data: {layouts: [LayoutConstants.FuseLayout]},
        canActivate: [LayoutGuard],
      },
      {
        path: 'main',
        loadChildren: () => import('./fall-apart-layout/fall-apart-layout.module').then(m => m.FallApartLayoutModule),
        data: {layouts: [LayoutConstants.MainLayout]},
        canActivate: [LayoutGuard],
      },
      // ----------------------------------------------------------------------------
      // Esto es un hack para que si nos saltamos el layout, haga la
      // redirección que toque. En realidad nunca se carga GenericEmptyPageComponent
      {path: '**', component: GenericEmptyPageComponent, canActivate: [LayoutGuard]},
      // ----------------------------------------------------------------------------
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
  ],
  declarations: [],
  providers: [
    LayoutGuard,
    DynamicRoutesGenerator
  ],
  exports: [],
})

export class LayoutsModule {
}
