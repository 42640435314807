import { NgModule } from '@angular/core';
import { OnWindowsResizeDirective } from './on-windows-resize.directive';
import { RunScriptsDirective } from './unsafe-html/run-scripts.directive';
import { SafeHtmlPipe } from './unsafe-html/unsafe-html-pipe.pipe';
import { UrlifyDirective } from './content-urlify';
import { GlobalizeUrlsDirective } from './content-globalizeUrls';

@NgModule({
  imports: [],
  declarations: [
    OnWindowsResizeDirective,
    RunScriptsDirective,
    SafeHtmlPipe,
    UrlifyDirective,
    GlobalizeUrlsDirective
  ],
  exports: [
    OnWindowsResizeDirective,
    RunScriptsDirective,
    SafeHtmlPipe,
    UrlifyDirective,
    GlobalizeUrlsDirective
  ]
})

export class UtilsModule {
}
