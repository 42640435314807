import { Component, OnInit } from '@angular/core';

import { PagerInterface } from '../interfaces/pager.interface';
import { ViewResult, ViewsPagerUserConfigurationSimple, ViewUserConfiguration } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../list.service';
import { ViewsuserconfigchangedAction } from '../../viewsuserconfigchanged.eventdata';

@Component({
  selector: 'app-load-more-pager',
  templateUrl: './load-more-pager.component.html',
  styleUrls: ['./load-more-pager.component.scss']
})
export class LoadMorePagerComponent implements OnInit, PagerInterface {

  viewResult: ViewResult;
  currentPage = 0;
  pageSize = 25;

  constructor(private listComponentService: ListComponent2Service) {
  }

  ngOnInit(): void {
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    this.currentPage = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage;
    this.pageSize = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).PageSize;
  }

  /**
   * set User configuration to get data
   */
  changeCurrentPage(): void {
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    this.currentPage++;
    (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage = this.currentPage;
    this.listComponentService.setUserConfiguration(userConfiguration, true, ViewsuserconfigchangedAction.Append);
  }

  /**
   * Returns when the current page is not the last one
   * @returns {boolean}
   */
  isNotLastPage(): boolean {
    return !this.viewResult.LastPage;
  }
}
