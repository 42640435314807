import { Component } from '@angular/core';

@Component({
  selector: 'app-list-loading-placeholder',
  templateUrl: './list-loading-placeholder.component.html',
  styleUrls: ['./list-loading-placeholder.component.scss']
})
export class ListLoadingPlaceholderComponent {

  constructor() {
  }

}
