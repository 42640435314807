<select [(ngModel)]="selectedItems"
        [disabled]="isDisabled"
        (blur)="propagateTouch()"
        [ngModelOptions]="{standalone: true}"
        [id]="this.uniqueComponentId('select-')">
  <option *ngFor="let option of options"
          [id]="this.uniqueComponentId(option.Key + '-')"
          [ngStyle]="option.Styles"
          value="{{option.Key}}">
    {{ option.Name }}
  </option>
</select>

<i>{{ currentItemDescription }}</i>
