<div class="pt-8"
     [title]="getTooltip()">
  <div
      [@animate]="{ value: '*', params: { opacity: '0' }}"
      class="display-number text-center mb-16 light-blue-fg font-size-72 line-height-72">
    {{ displayNumber }}
  </div>
  <div *ngIf="description !== null && description !== undefined"
      class="h3 secondary-text font-weight-500 text-center px-30 single-number-chart-description">
    {{ description }}
  </div>
</div>
