import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { GenericEmptyPageComponent } from '../../shared/generic-pages/generic-empty-page/generic-empty-page.component';
import { GenericFormPageSimpleArguments } from '../../shared/generic-pages/generic-form-page-simple/GenericFormPageSimpleArguments';
import { GenericListPageSimpleComponent } from '../../shared/generic-pages/generic-list-page-simple/generic-list-page-simple.component';
import { GenericFormPageSimpleComponent } from '../../shared/generic-pages/generic-form-page-simple/generic-form-page-simple.component';
import { GenericListPageSimpleArguments } from '../../shared/generic-pages/generic-list-page-simple/GenericListPageSimpleArguments';

const routes: Routes = [
  {
    path: 'quala',
    children: [
      {
        path: '',
        component: GenericFormPageSimpleComponent,
        data: {
          componentConfig:
            new GenericFormPageSimpleArguments(
              'quala-maintainer-home',
              {}),
        },
      },
      {
        path: ':quala',
        children: [
          {path: '', redirectTo: 'specific', pathMatch: 'full'},
          {
            path: 'generic',
            children:
              [
                {
                  path: '',
                  component: GenericListPageSimpleComponent,
                  data: {
                    componentConfig:
                      new GenericListPageSimpleArguments(
                        'quala-problems-maintainer-view',
                        {'quala': 'quala', 'type': 'generic'}),
                    navigationOrder: 0,
                    contentOrder: 1
                  },
                },
                {
                  path: 'add',
                  component: GenericFormPageSimpleComponent,
                  data: {
                    componentConfig: new GenericFormPageSimpleArguments('quala-problem-form', {
                      'type': 'generic',
                      'quala': 'quala'
                    })
                  },
                },
                {
                  path: ':problem',
                  children: [
                    {path: '', redirectTo: 'details', pathMatch: 'full'},
                    {
                      path: 'details',
                      component: GenericFormPageSimpleComponent,
                      data: {
                        componentConfig: new GenericFormPageSimpleArguments('quala-problem-form', {
                          'problemId': 'problem',
                          'type': 'generic',
                          'quala': 'quala'
                        })
                      },
                    },
                    {
                      path: 'questionnaires',
                      children:
                        [
                          {
                            path: '', component: GenericListPageSimpleComponent,
                            data: {
                              componentConfig: new GenericListPageSimpleArguments(
                                'quala-questionnaire-view', {
                                  'quala': 'quala',
                                  'problemId': 'problem',
                                  'type': 'generic'
                                }, true, 1)
                            },
                          },
                          {path: 'add', component: GenericEmptyPageComponent},
                          {
                            path: ':questionnaire', children: [
                              {
                                path: 'questions',
                                children: [
                                  {
                                    path: '',
                                    component: GenericListPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericListPageSimpleArguments(
                                        'quala-questions-view',
                                        {
                                          'quala': 'quala',
                                          'questionnaireId': 'questionnaire',
                                          'type': 'generic'
                                        }, true, 1)
                                    },
                                  },
                                  {
                                    path: 'add',
                                    component: GenericFormPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericFormPageSimpleArguments('quala-maintainer-question-form', {
                                        'quala': 'quala',
                                        'questionnaireId': 'questionnaire',
                                        'problemId': 'problem',
                                        'type': 'generic'
                                      })
                                    }
                                  },
                                  {
                                    path: ':question/details',
                                    component: GenericFormPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericFormPageSimpleArguments('quala-maintainer-question-form', {
                                        'quala': 'quala',
                                        'questionId': 'question',
                                        'questionnaireId': 'questionnaire',
                                        'type': 'generic'
                                      })
                                    }
                                  },
                                ]
                              }
                            ]
                          }
                        ]
                    }
                  ]
                }
              ]
          },
          {
            path: 'specific',
            children:
              [
                {
                  path: '',
                  component: GenericListPageSimpleComponent,
                  data: {
                    componentConfig:
                      new GenericListPageSimpleArguments(
                        'quala-problems-maintainer-view',
                        {'quala': 'quala', 'type': 'specific'}),
                    navigationOrder: 0,
                    contentOrder: 1
                  },
                },
                {
                  path: 'add',
                  component: GenericFormPageSimpleComponent,
                  data: {
                    componentConfig: new GenericFormPageSimpleArguments('quala-problem-form', {
                      'type': 'specific',
                      'quala': 'quala'
                    })
                  },
                },
                {
                  path: ':problem',
                  children: [
                    {path: '', redirectTo: 'details', pathMatch: 'full'},
                    {
                      path: 'details',
                      component: GenericFormPageSimpleComponent,
                      data: {
                        componentConfig: new GenericFormPageSimpleArguments('quala-problem-form', {
                          'problemId': 'problem',
                          'type': 'specific',
                          'quala': 'quala'
                        })
                      },
                    },
                    {
                      path: 'questionnaires',
                      children:
                        [
                          {
                            path: '', component: GenericListPageSimpleComponent,
                            data: {
                              componentConfig: new GenericListPageSimpleArguments(
                                'quala-questionnaire-view', {
                                  'quala': 'quala',
                                  'problemId': 'problem',
                                  'type': 'specific'
                                }, true, 1)
                            },
                          },
                          {path: 'add', component: GenericEmptyPageComponent},
                          {
                            path: ':questionnaire', children: [
                              {
                                path: 'questions',
                                children: [
                                  {
                                    path: '',
                                    component: GenericListPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericListPageSimpleArguments(
                                        'quala-questions-view',
                                        {
                                          'quala': 'quala',
                                          'questionnaireId': 'questionnaire',
                                          'type': 'specific'
                                        }, true, 1)
                                    },
                                  },
                                  {
                                    path: 'add',
                                    component: GenericFormPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericFormPageSimpleArguments('quala-maintainer-question-form', {
                                        'quala': 'quala',
                                        'questionnaireId': 'questionnaire',
                                        'problemId': 'problem',
                                        'type': 'specific'
                                      })
                                    }
                                  },
                                  {
                                    path: ':question/details',
                                    component: GenericFormPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericFormPageSimpleArguments('quala-maintainer-question-form', {
                                        'quala': 'quala',
                                        'questionId': 'question',
                                        'questionnaireId': 'questionnaire',
                                        'type': 'specific'
                                      })
                                    }
                                  },
                                ]
                              }
                            ]
                          }
                        ]
                    }
                  ]
                }
              ]
          },
          {
            path: 'normativa',
            children:
              [
                {
                  path: '',
                  component: GenericListPageSimpleComponent,
                  data: {
                    componentConfig:
                      new GenericListPageSimpleArguments(
                        'quala-problems-maintainer-view',
                        {'quala': 'quala', 'type': 'normativa'}),
                    navigationOrder: 0,
                    contentOrder: 1
                  },
                },
                {
                  path: 'add',
                  component: GenericFormPageSimpleComponent,
                  data: {
                    componentConfig: new GenericFormPageSimpleArguments('quala-problem-form', {
                      'type': 'normativa',
                      'quala': 'quala'
                    })
                  },
                },
                {
                  path: ':problem',
                  children: [
                    {path: '', redirectTo: 'details', pathMatch: 'full'},
                    {
                      path: 'details',
                      component: GenericFormPageSimpleComponent,
                      data: {
                        componentConfig: new GenericFormPageSimpleArguments('quala-problem-form', {
                          'problemId': 'problem',
                          'type': 'normativa',
                          'quala': 'quala'
                        })
                      },
                    },
                    {
                      path: 'questionnaires',
                      children:
                        [
                          {
                            path: '', component: GenericListPageSimpleComponent,
                            data: {
                              componentConfig: new GenericListPageSimpleArguments(
                                'quala-questionnaire-view', {
                                  'quala': 'quala',
                                  'problemId': 'problem',
                                  'type': 'normativa'
                                }, true, 1)
                            },
                          },
                          {path: 'add', component: GenericEmptyPageComponent},
                          {
                            path: ':questionnaire', children: [
                              {
                                path: 'questions',
                                children: [
                                  {
                                    path: '',
                                    component: GenericListPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericListPageSimpleArguments(
                                        'quala-questions-view',
                                        {
                                          'quala': 'quala',
                                          'questionnaireId': 'questionnaire',
                                          'type': 'normativa'
                                        }, true, 1)
                                    },
                                  },
                                  {
                                    path: 'add',
                                    component: GenericFormPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericFormPageSimpleArguments('quala-maintainer-question-form', {
                                        'quala': 'quala',
                                        'questionnaireId': 'questionnaire',
                                        'problemId': 'problem',
                                        'type': 'normativa'
                                      })
                                    }
                                  },
                                  {
                                    path: ':question/details',
                                    component: GenericFormPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericFormPageSimpleArguments('quala-maintainer-question-form', {
                                        'quala': 'quala',
                                        'questionId': 'question',
                                        'questionnaireId': 'questionnaire',
                                        'type': 'normativa'
                                      })
                                    }
                                  },
                                ]
                              }
                            ]
                          }
                        ]
                    }
                  ]
                }
              ]
          },
          {
            path: 'specificrevision',
            children:
              [
                {
                  path: '',
                  component: GenericListPageSimpleComponent,
                  data: {
                    componentConfig:
                      new GenericListPageSimpleArguments(
                        'quala-problems-maintainer-view',
                        {'quala': 'quala', 'type': 'specificrevision'}),
                    navigationOrder: 0,
                    contentOrder: 1
                  },
                },
                {
                  path: 'add',
                  component: GenericFormPageSimpleComponent,
                  data: {
                    componentConfig: new GenericFormPageSimpleArguments('quala-problem-form', {
                      'type': 'specificrevision',
                      'quala': 'quala'
                    })
                  },
                },
                {
                  path: ':problem',
                  children: [
                    {path: '', redirectTo: 'details', pathMatch: 'full'},
                    {
                      path: 'details',
                      component: GenericFormPageSimpleComponent,
                      data: {
                        componentConfig: new GenericFormPageSimpleArguments('quala-problem-form', {
                          'problemId': 'problem',
                          'type': 'specificrevision',
                          'quala': 'quala'
                        })
                      },
                    },
                    {
                      path: 'questionnaires',
                      children:
                        [
                          {
                            path: '', component: GenericListPageSimpleComponent,
                            data: {
                              componentConfig: new GenericListPageSimpleArguments(
                                'quala-questionnaire-view', {
                                  'quala': 'quala',
                                  'problemId': 'problem',
                                  'type': 'specificrevision'
                                }, true, 1)
                            },
                          },
                          {path: 'add', component: GenericEmptyPageComponent},
                          {
                            path: ':questionnaire', children: [
                              {
                                path: 'questions',
                                children: [
                                  {
                                    path: '',
                                    component: GenericListPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericListPageSimpleArguments(
                                        'quala-questions-view',
                                        {
                                          'quala': 'quala',
                                          'questionnaireId': 'questionnaire',
                                          'type': 'specificrevision'
                                        }, true, 1)
                                    },
                                  },
                                  {
                                    path: 'add',
                                    component: GenericFormPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericFormPageSimpleArguments('quala-maintainer-question-form', {
                                        'quala': 'quala',
                                        'questionnaireId': 'questionnaire',
                                        'problemId': 'problem',
                                        'type': 'specificrevision'
                                      })
                                    }
                                  },
                                  {
                                    path: ':question/details',
                                    component: GenericFormPageSimpleComponent,
                                    data: {
                                      componentConfig: new GenericFormPageSimpleArguments('quala-maintainer-question-form', {
                                        'quala': 'quala',
                                        'questionId': 'question',
                                        'questionnaireId': 'questionnaire',
                                        'type': 'specificrevision'
                                      })
                                    }
                                  },
                                ]
                              }
                            ]
                          }
                        ]
                    }
                  ]
                }
              ]
          },
        ]
      }
    ]
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class QualaMaintainerModule {
}
