import { EventEmitter } from '@angular/core';

const BLACKLISTED_ZONE_EVENTS: string[] = [
  'focus',
  'blur',
  'requestAnimationFrame$',
  '^setTimeout$',
  '^setInterval$',
  '^IDB',
  'swipeleft',
  'swiperight',
  ':keydown$',
  ':mouseenter$',
  ':mouseleave$',
  ':mouseover$',
  ':mousewheel$',
  ':mousemove$',
  ':mousedown$',
  ':mouseup$',
  ':mouseout$',
  ':dragover$',
  ':dragleave$',
  ':drop$',
  ':scroll$',
  '^HTMLDocument\.addEventListener:'
];

/**
 * Este whitelist se pone para no tener que lanzar la detección manualmente en toda la aplicación,
 * y hacer eso en una segunda fase....
 */
const WHITELISTED_ZONE_EVENTS: string[] = [
  // Para los tooltips de ngx, necesitamos los el mouseover por el mismo motivo que en ngxDatePicker
  ':mouseover$'
];

// Este sirve para que el whitelist, si está vacío, no de falsos positivos.
WHITELISTED_ZONE_EVENTS.push('$^');

// Convert all of them to regular expressions
const BLACKLISTED_ZONE_EVENTS_REGEX: RegExp = new RegExp(BLACKLISTED_ZONE_EVENTS.join('|'), 'i');
const WHITELISTED_ZONE_EVENTS_REGEX: RegExp = new RegExp(WHITELISTED_ZONE_EVENTS.join('|'), 'i');

// Keep track of all events and how they are or not blacklisted
const BLACKLIST_STATS: object = {};

// No usar, se iba a utilizar para workaround del problema con bsDatePicker que depende de nzZone.OnStable()
export const masterOnMicrotaskEmpty: EventEmitter<void> = new EventEmitter<void>(false);

// We have this intermediate zone to patch/intercept and have controll on everything that is blacklisted,
// because root zone cannot have a ZoneSpec.
export const masterZone: Zone = Zone.current.fork({
    name: 'master',
    onHasTask: (delegate: ZoneDelegate, current: Zone, target: Zone, hasTaskState: HasTaskState): void => {
      delegate.hasTask(target, hasTaskState);
      if (current === target) {
        masterOnMicrotaskEmpty.emit();
      }
    },
  })
;

export const blacklistZone: Zone = masterZone.fork({
  name: 'blacklist',
  onScheduleTask: (delegate: ZoneDelegate,
                   current: Zone,
                   target: Zone,
                   task: Task): Task => {

    // Blacklist scroll, mouse, and request animation frame events.
    const value: any = BLACKLIST_STATS[task.source];
    if (value !== undefined && value !== null) {
      BLACKLIST_STATS[task.source] = null;
    }

    const a: boolean = false;

    if (a && ['eventTask', 'macroTask'].includes(task.type) && BLACKLISTED_ZONE_EVENTS_REGEX.test(task.source) === true && WHITELISTED_ZONE_EVENTS_REGEX.test(task.source) === false) {
      task.cancelScheduleRequest();
      BLACKLIST_STATS[task.source] = 'blocked';

      // Schedule task in root zone, note Zone.root != target,
      // "target" Zone is Angular. Scheduling a task within Zone.root will
      // prevent the infinite digest cycle from appearing.
      return masterZone.scheduleTask(task);
    } else {
      return delegate.scheduleTask(target, task);
    }
  }
});
