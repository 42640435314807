import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { isArray, isNullOrUndefined, isString } from 'app/shared/utils/typescript.utils';

import { GenericPermissionLogicEnum, GenericPermissionService } from './generic-permission.service';
import { PermissionsWraperComponent } from './permissions-wraper.component';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { takeUntil } from 'rxjs/operators';

/**
 * Function type for the callback inputs for the directive.
 */
export interface PermissionDirectiveCallback {
  (elementRef: ElementRef, temlateRef: TemplateRef<any>, viewContainer: ViewContainerRef);
};

/**
 * This directive checks for the existence of permissions of an
 * array and triggers a callback depending of the case.
 *
 * How to use:
 * <span *permissions="arrayofpermissions; negate">You shall [NOT] pass</span>
 *
 * arrayofpermissions: Can be a variable that holds an array of string or a formatted array of strings.
 * negate: If added the validation logic will be negated.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[permissions]'
})
export class PermissionsDirective extends DestroyableObjectTrait implements OnDestroy {

  /**
   * Optional callable that executes when a permision evaluation returns false.
   */
  @Input('permissionsFalseCallback') applyFalseCallback: PermissionDirectiveCallback = null;
  /**
   * Optional callable that executes when a permision evaluation returns false.
   */
  @Input('permissionsTrueCallback') applyTrueCallback: PermissionDirectiveCallback = null;

  /**
   * Array of permissions to ask.
   */
  private itemPermissions: string[] = [];

  /**
   * If true, the permissions evaluation value is negated.
   */
  private negateLogic = false;

  /**
   * If true, then the directive applies a disable logic instead of a view/hide logic.
   */
  private disableLogic = false;

  /**
   * FLAG: Indicates if the container must be cleaned before being evaluated.
   */
  private shouldCleanContainer = false;

  /**
   * Implements an OR logic, instead of the default AND logic.
   */
  private orLogic = false;

  /**
   * Container for the wrapped element.
   */
  private wrapperContainer: ComponentRef<PermissionsWraperComponent>;

  /**
   * Class constructor.
   */
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private genericPermissionsService: GenericPermissionService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private render: Renderer2
  ) {
    super();

    this.genericPermissionsService
      .permissionsUpdated()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.updateView();
      });
  }


  /**
   * Sets the value of the directive and triggers an update.
   */
  @Input() set permissions(val: string | string[]) {
    if (isString(val)) {
      this.itemPermissions = (val as string).split(',');
    }

    if (isArray(val)) {
      this.itemPermissions = val as string[];
    }

    this.itemPermissions = this.itemPermissions.map(i => i.trim());

    this.updateView();
  }

  /**
   * If true then the evaluation for the permissions is negated.
   *
   * The input value is not used as this setter is only executed when the negate argument is present.
   */
  @Input('permissionsNegate') set negate(nouse: any) {
    this.negateLogic = true;
    this.updateView();
  }

  /**
   * If true, then after the evaluation the element is disabled if the logic fails.
   *
   * The input value is not used as this setter is only executed when the negate argument is present.
   */
  @Input('permissionsDisable') set disable(nouse: any) {
    this.disableLogic = true;
    this.updateView();
  }

  /**
   * If true, then after the evaluation the element is disabled if the logic fails.
   *
   * The input value is not used as this setter is only executed when the negate argument is present.
   */
  @Input('permissionsOR') set or(nouse: any) {
    this.orLogic = true;
    this.updateView();
  }

  /**
   * Triggers a permissions evaluation.
   */
  private updateView(): void {
    if (this.shouldCleanContainer) {
      this.viewContainer.clear();
      this.shouldCleanContainer = false;
    }

    if (this.negateLogic ? !this.checkPermission() : this.checkPermission()) {
      this.applyShow();
      return;
    }

    if (this.disableLogic) {
      this.applyDisable();
      return;
    }

    this.applyHide();
  }

  /**
   * Evaluates if the current permissions on the element are valid for the user.
   */
  private checkPermission(): boolean {
    const logic: GenericPermissionLogicEnum = this.orLogic ? GenericPermissionLogicEnum.OR : GenericPermissionLogicEnum.AND;
    return this.genericPermissionsService.validPermission(this.itemPermissions, logic);
  }

  /**
   * Applies when the evaluation is falsy.
   */
  private applyHide(): void {
    if (!isNullOrUndefined(this.applyFalseCallback)) {
      this.applyFalseCallback(this.element, this.templateRef, this.viewContainer);
      return;
    }

    this.viewContainer.clear();
  }

  /**
   * Applies when the evaluation is truthy.
   */
  private applyShow(): void {
    if (!isNullOrUndefined(this.applyTrueCallback)) {
      this.applyTrueCallback(this.element, this.templateRef, this.viewContainer);
      return;
    }

    if (this.viewContainer.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  /**
   * Applies when disableLogic is enabled and permissions failed.
   */
  private applyDisable(): void {
    if (!isNullOrUndefined(this.applyTrueCallback)) {
      this.applyTrueCallback(this.element, this.templateRef, this.viewContainer);
      return;
    }

    if (this.viewContainer.length === 0) {
      this.wrapperContainer = this.viewContainer.createComponent(PermissionsWraperComponent);
      const viewReference: EmbeddedViewRef<any> = this.wrapperContainer.instance.createEmbeddedView(this.templateRef);

      const rootElement: any = viewReference.rootNodes[0];
      this.render.setAttribute(rootElement, 'disabled', 'true');

      this.shouldCleanContainer = true;
    }
  }
}
